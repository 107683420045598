import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

interface LoginMutationParams {
  tempEmail: string;
  password: string;
}

interface UseLoginResponse {
  access_token: string;
  is_clinician_and_pin: boolean;
  type: string;
}

export const useConfirmLogin = (
  mutationOptions?: UseMutationOptions<UseLoginResponse, ApiError, LoginMutationParams>
) =>
  useMutation({
    mutationFn: async ({ tempEmail, password }: LoginMutationParams) => {
      const { data } = await hikeApi.post<UseLoginResponse>('/use-login', {
        tempEmail,
        password
      });
      return data;
    },
    ...mutationOptions
  });

interface SignUpWithInvitationParams {
  email: string;
  token: string;
}

interface SignupWithInvitationResponse {
  message: string;
  hasPassword: boolean;
}

export const useSignupWithInvitation = (
  { email, token }: SignUpWithInvitationParams,
  queryOptions?: Omit<UseQueryOptions<SignupWithInvitationResponse, ApiError>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['signup-with-invitation', email, token],
    queryFn: async () => {
      const { data } = await hikeApi.get<SignupWithInvitationResponse>('/forgot-pass', {
        params: {
          email,
          token
        }
      });
      return data;
    },
    ...queryOptions
  });

interface SetPasswordParams {
  email: string;
  password: string;
  phone_number?: string;
}

interface SetPasswordResponse {
  message: string;
}

export const useSetPassword = (
  mutationOptions?: UseMutationOptions<SetPasswordResponse, ApiError, SetPasswordParams>
) =>
  useMutation({
    mutationFn: async ({ email, password, phone_number }: SetPasswordParams) => {
      const { data } = await hikeApi.post<SetPasswordResponse>('/set-password', {
        email,
        password,
        phone_number
      });
      return data;
    },
    ...mutationOptions
  });

interface RemovePasswordParams {
  email: string;
  phone_number?: string;
}

interface RemovePasswordResponse {
  message: string;
}

export const useRemovePassword = (
  mutationOptions?: UseMutationOptions<RemovePasswordResponse, ApiError, RemovePasswordParams>
) =>
  useMutation({
    mutationFn: async ({ email, phone_number }: RemovePasswordParams) => {
      const { data } = await hikeApi.post<RemovePasswordResponse>('/remove_pass', {
        email,
        phone_number
      });
      return data;
    },
    ...mutationOptions
  });
