import { OrderActionType } from '@/pages/OrderActions/OrderActions';
import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { formatConstant } from '@hike/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

interface OrderFormMutationParams {
  leftStl: File | null;
  rightStl: File | null;
  orderSide: string;
  orderQuantity: number;
  reasonsForAction: string;
  instructionsForAction: string;
  poNumber?: string;
  actionType: OrderActionType;
  visitId: string;
}

export const useCreateOrderAction = (
  options?: Omit<UseMutationOptions<void, ApiError, OrderFormMutationParams>, 'mutationFn'>
) =>
  useMutation<void, ApiError, OrderFormMutationParams>({
    mutationFn: async ({
      leftStl,
      rightStl,
      orderSide,
      orderQuantity,
      reasonsForAction,
      instructionsForAction,
      poNumber,
      actionType,
      visitId
    }: OrderFormMutationParams) => {
      const formData = new FormData();
      if (leftStl) {
        formData.append('left_stl', leftStl);
      }
      if (rightStl) {
        formData.append('right_stl', rightStl);
      }
      if (poNumber) {
        formData.append('PONumber', poNumber);
      }
      formData.append('orderSide', orderSide);
      formData.append('orderQuantity', orderQuantity.toString());
      formData.append('reasonsForAction', reasonsForAction);
      formData.append('instructionsForAction', instructionsForAction);
      formData.append('actionType', formatConstant(actionType));
      formData.append('visit_id', visitId);

      const { data } = await hikeApi.post<void>('v2/order_action', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    },
    ...options
  });
