import { ROUTES } from '@/Router';
import {
  useGetCompletedEvaluationsForClinician,
  useGetEvaluationsBySearch,
  useGetIncompleteEvaluationsForClinician,
  useManuallyStartVisit
} from '@/api/evaluations';
import TicketList from '@/components/TicketList/TicketList';
import { ApiError } from '@/utils/ApiError';
import { queryClient } from '@/utils/queryClient';
import {
  Alert,
  Box,
  Button,
  Group,
  Image,
  Modal,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme
} from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import { useDisclosure, useSetState } from '@mantine/hooks';
import { IconExclamationCircle, IconHash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { z } from 'zod';

const labelStyles = {
  label: {
    fontWeight: 700,
    paddingBottom: 8
  }
};

type ManualAddErrorObject = {
  patientID?: string[];
  birthday?: string[];
  firstName?: string[];
  lastName?: string[];
};

type PatientInfoObject = {
  birthday: Date | null;
  patientID: string;
  fullName: string;
};

const addPatientManuallySchema = z.object({
  birthday: z.date({
    invalid_type_error: 'Please enter a valid date',
    required_error: 'This field is required'
  }),
  firstName: z.string().min(1, {
    message: "Please enter the patient's first name"
  }),
  lastName: z
    .string({
      required_error: "Please enter the patient's last name"
    })
    .min(1, {
      message: "Please enter the patient's last name"
    }),
  patientID: z.string().min(1, {
    message: 'Please enter the patient ID'
  })
});

export default function StartNewEvaluation() {
  const [isDOBSelectorOpen, { open: openDOBSelector, close: closeDOBSelector }] = useDisclosure(false);
  const theme = useMantineTheme();
  const [opened, handlers] = useDisclosure(false);
  const [patientInfo, setPatientInfo] = useSetState<PatientInfoObject>({
    birthday: null,
    patientID: '',
    fullName: ''
  });
  const [firstName, lastName] = patientInfo.fullName.split(';', 2); // split based on special delimiter assigned on add patient manually
  const [errors, setErrors] = useState<ManualAddErrorObject>({});

  const [searched, setSearched] = useState<boolean>(false);
  const [hasManualError, setHasManualError] = useState<boolean>(false);
  const [isAddingManually, setIsAddingManually] = useState<boolean>(false);
  const [isReviewingManually, setIsReviewingManually] = useState<boolean>(false);
  const closeButtonColor = isAddingManually ? 'black' : 'white';

  const [newClinicalRedirectModalOpened, { open: openNewClinicalRedirectModal, close: closeNewClinicalRedirectModal }] =
    useDisclosure(false);
  const { isError: isCompletedEvalsError } = useGetCompletedEvaluationsForClinician();

  const { isError: isIncompleteEvalsError } = useGetIncompleteEvaluationsForClinician();

  const evaluationsError = isCompletedEvalsError || isIncompleteEvalsError;
  const navigate = useNavigate();
  const {
    data: queried_evaluations,
    isLoading,
    isError
  } = useGetEvaluationsBySearch(patientInfo.fullName, patientInfo.patientID, {
    enabled: !!searched
  });

  const sanitizeInputAndSetName = (value: string, nameToChange: 'first' | 'last' | 'full') => {
    const sanitizedValue = value.replace(/[^A-Za-z' ,.-]/gi, ''); // restrict name input to alphabetical characters, space, hyphen, comma, and period
    if (nameToChange === 'first') {
      setPatientInfo({ fullName: `${sanitizedValue};${lastName || ''}` });
    } else if (nameToChange === 'last') {
      setPatientInfo({ fullName: `${firstName || ''};${sanitizedValue}` });
    } else {
      setPatientInfo({ fullName: sanitizedValue });
    }
  };

  const handleSwitchToManualAdd = () => {
    setSearched(false);
    setIsAddingManually(true);
    setPatientInfo({ fullName: patientInfo.fullName.replace(' ', ';') }); // add special delimiter to split first and last name
  };

  const handleSwitchToSearch = () => {
    setIsAddingManually(false);
    setIsReviewingManually(false);
    setErrors({});
    setPatientInfo({ fullName: patientInfo.fullName.replace(';', ' ') }); // remove special delimiter for full name
  };

  const handleEditManualAdd = () => {
    setIsReviewingManually(false);
    setHasManualError(false);
  };

  const validateManualFormAndSwitchToReview = () => {
    const result = addPatientManuallySchema.safeParse({
      birthday: patientInfo.birthday,
      firstName,
      lastName,
      patientID: patientInfo.patientID
    });
    if (result.success) {
      setErrors({});
      setIsReviewingManually(true);
    } else {
      setErrors(result.error.flatten().fieldErrors);
    }
  };

  const handleClose = () => {
    handlers.close();
    setSearched(false);
    setIsAddingManually(false);
    setIsReviewingManually(false);
    setHasManualError(false);
    setErrors({});
    setPatientInfo({ birthday: null, patientID: '', fullName: '' }); // reset input fields
  };

  const getModalHeaderButton = () => {
    if (searched && !isLoading) {
      return <>{/* No title button */}</>;
    }
    if (isReviewingManually) {
      return (
        <Button mt="md" size="md" fullWidth onClick={handleEditManualAdd} data-automation-id="edit-button">
          Edit
        </Button>
      );
    }
    if (isAddingManually) {
      return (
        <Button
          mt="md"
          variant="light"
          size="sm"
          fullWidth
          onClick={handleSwitchToSearch}
          data-automation-id="back-to-search-button"
        >
          Back to Search
        </Button>
      );
    }

    return (
      <Button
        data-automation-id="add-manually-header-button"
        style={{
          border: '1px solid white'
        }}
        mt="md"
        size="md"
        fullWidth
        onClick={handleSwitchToManualAdd}
      >
        Add Manually
      </Button>
    );
  };

  const getModalTitle = () => {
    if (isAddingManually) {
      return (
        <Group>
          <Title order={3} component="h1">
            {isReviewingManually ? 'Review Patient' : 'Add Patient Manually'}
          </Title>
        </Group>
      );
    }

    return (
      <Title order={3} component="h1" style={{ color: 'white' }}>
        {searched && !isLoading ? 'Search Results' : 'Search for Your Next Patient'}
      </Title>
    );
  };
  const {
    mutate: mutateAddManualPatient,
    error: addManualError,
    isPending: addManualLoading
  } = useManuallyStartVisit({
    onError: (data) => {
      if (data.data.sunset_order_creation) {
        openNewClinicalRedirectModal();
      }
      setHasManualError(true);
    },
    onSuccess: (data) => {
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      sessionStorage.setItem('visitId', data.visit_id.toString());
      navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
    }
  });

  const handleAddManually = () => {
    mutateAddManualPatient({
      patientName: `${firstName} ${lastName}`,
      patient_ID: patientInfo.patientID,
      date_of_birth: patientInfo.birthday as Date
    });
  };

  const getManualErrorAlert = (error: ApiError | null) => {
    if (error?.error === 'Patient ID already found') {
      return (
        <Alert variant="light" color="red" title="Patient ID Already In Use" icon={<IconExclamationCircle />}>
          Please search for the patient instead.
        </Alert>
      );
    }
    return (
      <Alert variant="light" color="red" title="System Error" icon={<IconExclamationCircle />}>
        {error?.error || 'An error occurred while adding this patient manually.'}
      </Alert>
    );
  };

  const sunsetModal = () => (
    <Modal
      title="Go to the new experience"
      opened={newClinicalRedirectModalOpened}
      size="md"
      onClose={closeNewClinicalRedirectModal}
    >
      <Image
        src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F9d%2F72%2F5f%2F9d725fe7ec601fb2744d8d704f402a10.gif"
        alt="New experience"
      />
      <Text pt="sm" size="md">
        Hey there! We've got some exciting news to share. We're moving on to bigger and better things, which means we'll
        be saying goodbye to this app soon. Please start using our new app for placing orders. Thanks for your support.
      </Text>
      <Group mt="lg" justify="flex-end">
        <Button
          onClick={() => {
            window.location.href = 'https://app.insoles.ai';
          }}
          color="blue"
        >
          Go to new experience
        </Button>
      </Group>
    </Modal>
  );
  const getFormOrResults = () => {
    if (isAddingManually) {
      if (isReviewingManually) {
        return (
          <Stack gap="sm" mt="sm">
            {hasManualError && getManualErrorAlert(addManualError)}
            <Stack gap="0">
              <Text fw="bold">Patient ID:</Text>
              <Text>{patientInfo.patientID}</Text>
            </Stack>
            <Stack gap="0">
              <Text fw="bold">First Name: </Text>
              <Text>{firstName}</Text>
            </Stack>
            <Stack gap="0">
              <Text fw="bold">Last Name:</Text>
              <Text>{lastName}</Text>
            </Stack>
            <Stack gap="0">
              <Text fw="bold">Date of Birth:</Text>
              <Text>{dayjs(patientInfo.birthday).format('MM/DD/YYYY')}</Text>
            </Stack>
            <Button
              mt="md"
              size="lg"
              fullWidth
              onClick={handleAddManually}
              loading={addManualLoading}
              data-automation-id="start-evaluation-button"
            >
              Start Evaluation!
            </Button>
          </Stack>
        );
      }
      return (
        <>
          <Modal title="Select Date of Birth" opened={isDOBSelectorOpen} onClose={closeDOBSelector}>
            <DatePicker
              data-automation-id="dob-picker"
              w="100%"
              value={patientInfo.birthday}
              maxDate={new Date()}
              onChange={(value) => {
                setPatientInfo({ birthday: value });
              }}
              styles={{
                levelsGroup: {
                  justifyContent: 'center'
                }
              }}
            />

            <Group gap="xs" mt="lg" justify="flex-end">
              <Button data-automation-id="cancel-dob-button" variant="subtle" color="red" onClick={closeDOBSelector}>
                Cancel
              </Button>
              <Button onClick={closeDOBSelector}>Save</Button>
            </Group>
          </Modal>
          <Stack gap="1">
            <NumberInput
              data-automation-id="manual-patient-id-input"
              key="Manual Patient ID"
              label="Patient ID"
              mt="sm"
              required
              value={patientInfo.patientID}
              allowDecimal={false}
              onChange={(value) => setPatientInfo({ patientID: String(value) })}
              placeholder="Enter the Patient ID"
              styles={labelStyles}
              leftSection={<IconHash size={18} />}
              hideControls
            />
            {errors.patientID?.map((error, idx) => (
              <Text c="red" size="sm" key={idx}>
                {error}
              </Text>
            ))}
            <TextInput
              data-automation-id="manual-first-name-input"
              key="Manual First Name"
              label="First Name"
              mt="sm"
              required
              value={firstName || ''}
              onChange={(event) => sanitizeInputAndSetName(event.target.value, 'first')}
              placeholder="Enter first name"
              styles={labelStyles}
            />
            {errors.firstName?.map((error, idx) => (
              <Text c="red" size="sm" key={idx}>
                {error}
              </Text>
            ))}
            <TextInput
              data-automation-id="manual-last-name-input"
              key="Manual Last Name"
              label="Last Name"
              mt="sm"
              required
              value={lastName || ''}
              onChange={(event) => sanitizeInputAndSetName(event.target.value, 'last')}
              placeholder="Enter last name"
              styles={labelStyles}
            />
            {errors.lastName?.map((error, idx) => (
              <Text c="red" size="sm" key={idx}>
                {error}
              </Text>
            ))}
            <DateInput
              required
              readOnly
              onClick={openDOBSelector}
              label="Date of Birth"
              placeholder="Tap to select DOB"
              value={patientInfo.birthday}
              styles={labelStyles}
              mt="sm"
            />
            {errors.birthday?.map((error, idx) => (
              <Text c="red" size="sm" key={idx}>
                {error}
              </Text>
            ))}
            <Button
              data-automation-id="review-patient-info-button"
              mt="md"
              size="lg"
              fullWidth
              onClick={validateManualFormAndSwitchToReview}
            >
              Review Patient Info
            </Button>
          </Stack>
        </>
      );
    }

    if (searched && !isLoading) {
      return (
        <Stack>
          {sunsetModal()}
          <TicketList
            isError={isError}
            notFoundColor="white"
            evaluations={queried_evaluations}
            openNewClinicalRedirectModal={openNewClinicalRedirectModal}
            allowEdit
          />
          <Text
            style={{
              color: 'white'
            }}
          >
            {queried_evaluations && queried_evaluations.length > 0
              ? "Not who you're looking for? Try another search or add your patient manually."
              : 'Try another search or add patient manually.'}
          </Text>
          <Button
            data-automation-id="search-again-button"
            fullWidth
            onClick={() => setSearched(false)}
            style={{
              backgroundColor: '#001D36'
            }}
          >
            Search Again
          </Button>
          <Button
            data-automation-id="add-manually-after-search-button"
            fullWidth
            onClick={handleSwitchToManualAdd}
            style={{
              backgroundColor: '#001D36'
            }}
          >
            Add Manually
          </Button>
        </Stack>
      );
    }
    return (
      <Stack>
        <TextInput
          style={{
            color: 'white'
          }}
          data-automation-id="patient-name-input"
          key="Patient Name"
          label="Patient Name"
          required
          value={patientInfo.fullName}
          onChange={(event) => sanitizeInputAndSetName(event.target.value, 'full')}
          placeholder="Enter Full Name"
          styles={labelStyles}
        />
        <Text
          pt="sm"
          ta="center"
          fw={700}
          size="lg"
          style={{
            color: 'white'
          }}
        >
          OR
        </Text>
        <NumberInput
          style={{
            color: 'white'
          }}
          data-automation-id="patient-id-input"
          key="Patient ID"
          label="Patient ID"
          required
          value={patientInfo.patientID}
          allowDecimal={false}
          onChange={(value) => setPatientInfo({ patientID: String(value) })}
          placeholder="Enter the Patient ID"
          styles={labelStyles}
          leftSection={<IconHash size={18} />}
          hideControls
        />
        <Button
          data-automation-id="patient-search-submit-button"
          loading={isLoading}
          style={{
            backgroundColor: patientInfo.fullName === '' && patientInfo.patientID === '' ? '#ddd' : '#001D36'
          }}
          disabled={patientInfo.fullName === '' && patientInfo.patientID === ''}
          fullWidth
          mt="xl"
          onClick={() => setSearched(true)}
        >
          Search
        </Button>
      </Stack>
    );
  };

  return (
    <Box p="md">
      <Button
        data-automation-id="start-new-evaluation-button"
        disabled={evaluationsError}
        fullWidth
        onClick={() => handlers.open()}
        size="lg"
      >
        Start New Evaluation
      </Button>
      <Modal
        styles={{
          header: {
            backgroundColor: isAddingManually ? 'white' : theme.colors.hike?.[9],
            borderRadius: 0
          },
          content: {
            backgroundColor: isAddingManually ? 'white' : theme.colors.hike?.[9],
            borderRadius: 0
          },
          inner: {
            borderRadius: 0
          },
          close: {
            color: closeButtonColor,
            fontSize: 100,
            border: `1px solid ${closeButtonColor}`,
            width: 32,
            height: 32
          }
        }}
        fullScreen
        opened={opened}
        onClose={handleClose}
        transitionProps={{ transition: 'slide-up', duration: 200 }}
        title={getModalHeaderButton()}
      >
        <Stack>
          {sunsetModal()}
          {getModalTitle()}
          {getFormOrResults()}
        </Stack>
      </Modal>
    </Box>
  );
}
