import { ROUTES } from '@/Router';
import { useGetCompletedEvaluationsForClinician, useGetIncompleteEvaluationsForClinician } from '@/api/evaluations';
import PortalMenu from '@/components/PortalMenu/PortalMenu';
import TicketList from '@/components/TicketList/TicketList';
import { ActionIcon, AppShell, Button, Group, Image, Modal, ScrollArea, Stack, Tabs, Text, Title } from '@mantine/core';
import { useDisclosure, useElementSize, useViewportSize } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import './ClinicianPortal.module.css';
import StartNewEvaluation from './StartNewEvaluation';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FEATURE_FLAG } from '@/utils/featureFlag';

export enum ClinicalPortalTabs {
  Completed = 'completed',
  Incomplete = 'incomplete'
}

export default function ClinicianPortal() {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref: footerRef, height: footerHeight } = useElementSize();
  const { height: viewportHeight } = useViewportSize();
  const newClinicalRedirect = useFeatureFlagEnabled(FEATURE_FLAG.REDIRECT_TO_3_0);
  const [newClinicalRedirectModalOpened, { open: openNewClinicalRedirectModal, close: closeNewClinicalRedirectModal }] =
    useDisclosure(false);

  useEffect(() => {
    if (newClinicalRedirect && typeof newClinicalRedirect === 'boolean') {
      openNewClinicalRedirectModal();
    }
  }, [newClinicalRedirect]);

  const {
    data: completed_evaluations,
    isLoading: isCompletedEvalsLoading,
    isError: isCompletedEvalsError
  } = useGetCompletedEvaluationsForClinician();

  const {
    data: incomplete_evaluations,
    isLoading: isIncompleteEvalsLoading,
    isError: isIncompleteEvalsError
  } = useGetIncompleteEvaluationsForClinician();

  const getTicketListPropsByTab = () => {
    if (tabValue === ClinicalPortalTabs.Incomplete) {
      return {
        isError: isIncompleteEvalsError,
        isLoading: isIncompleteEvalsLoading,
        evaluations: incomplete_evaluations?.clinician_evaluations,
        allowEdit: true
      };
    }
    if (tabValue === ClinicalPortalTabs.Completed) {
      return {
        isError: isCompletedEvalsError,
        isLoading: isCompletedEvalsLoading,
        evaluations: completed_evaluations?.clinician_evaluations,
        allowEdit: true
      };
    }
    return {
      isError: true,
      isLoading: false,
      allowEdit: false,
      evaluations: []
    };
  };
  useEffect(() => {
    sessionStorage.removeItem('visitId');

    if (!tabValue) {
      navigate(
        generatePath(ROUTES.PORTAL, {
          tabValue: ClinicalPortalTabs.Incomplete
        }),
        {
          replace: true
        }
      );
    }
  }, [tabValue]);

  return (
    <>
      <Modal
        title="Go to the new experience"
        opened={newClinicalRedirectModalOpened}
        size="md"
        onClose={closeNewClinicalRedirectModal}
      >
        <Image
          src="https://framerusercontent.com/images/UE2AuZLwNnJfc2xLF0sg3G9agMw.png?scale-down-to=1024"
          alt="New experience"
        />
        <Text pt="sm" size="md">
          We are excited to announce that we have a new and improved clinical portal experience. Click the button below
          to check it out.
        </Text>
        <Group mt="lg" justify="flex-end">
          <Button onClick={closeNewClinicalRedirectModal} color="gray">
            Stay Here
          </Button>
          <Button
            onClick={() => {
              window.location.href = 'https://app.insoles.ai';
            }}
            color="blue"
          >
            Go to new experience
          </Button>
        </Group>
      </Modal>
      <AppShell padding="sm">
        <AppShell.Header ref={headerRef} withBorder={false}>
          <Stack p="lg" pb={0}>
            <Group justify="space-between">
              <Group gap="xs">
                <PortalMenu />
                <Title order={3} component="h1">
                  Your Patient Evaluations
                </Title>
              </Group>
              <ActionIcon
                color="gray"
                variant="light"
                size="lg"
                data-automation-id="search-button"
                component={Link}
                to={generatePath(ROUTES.SEARCH)}
              >
                <IconSearch size={18} />
              </ActionIcon>
            </Group>
            <Tabs
              value={tabValue}
              onChange={(value) =>
                navigate(
                  generatePath(ROUTES.PORTAL, {
                    tabValue: value
                  })
                )
              }
            >
              <Tabs.List>
                <Tabs.Tab
                  data-automation-id="incomplete-tab"
                  flex={1}
                  value={ClinicalPortalTabs.Incomplete}
                  style={{
                    fontWeight: tabValue === ClinicalPortalTabs.Incomplete ? 700 : 400
                  }}
                >
                  In Progress ({incomplete_evaluations?.clinician_evaluations?.length || 0})
                </Tabs.Tab>
                <Tabs.Tab
                  data-automation-id="completed-tab"
                  flex={1}
                  value={ClinicalPortalTabs.Completed}
                  style={{
                    fontWeight: tabValue === ClinicalPortalTabs.Completed ? 700 : 400
                  }}
                >
                  Completed ({completed_evaluations?.clinician_evaluations?.length || 0})
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </Stack>
        </AppShell.Header>
        <AppShell.Main pt={headerHeight} pb={footerHeight}>
          <ScrollArea pt={3} h={viewportHeight - headerHeight - footerHeight}>
            {headerHeight > 0 && footerHeight > 0 && <TicketList {...getTicketListPropsByTab()} />}
          </ScrollArea>
        </AppShell.Main>
        <AppShell.Footer ref={footerRef}>
          <StartNewEvaluation />
        </AppShell.Footer>
      </AppShell>
    </>
  );
}
