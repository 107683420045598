import { FormBuilderType } from '@/components/FormPage/FormStep';
import { isDevEnv } from '@/utils/constants';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Code,
  NativeSelect,
  NumberInput,
  Paper,
  Portal,
  rem,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput
} from '@mantine/core';
import { DateInput, MonthPickerInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useLocalStorage } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconCode, IconExclamationCircle } from '@tabler/icons-react';
import { camelCase, includes, reject, startCase } from 'lodash';
import { zodResolver } from 'mantine-form-zod-resolver';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import css from './FormBuilder.module.css';

export enum FormTypes {
  singleOption = 'singleOption',
  multipleOption = 'multipleOption',
  text = 'text',
  textArea = 'textArea',
  number = 'number',
  date = 'date',
  month = 'month',
  dropdown = 'dropdown',
  numberDropdown = 'numberDropdown',
  checkbox = 'checkbox',
  multiCheckbox = 'multiCheckbox',
  multiSelectBubble = 'multiSelectBubble',
  tabs = 'tabs',
  autoComplete = 'autoComplete'
}

export interface Builder {
  field: string;
  type: FormTypes;
  label?: string;
  hidden?: boolean;
  maxDate?: Date;
  placeholder?: string;
  initialValue?: unknown;
  isOther?: IsOtherFormFootSelect;
  options?: { label: string; value: string | boolean }[];
  validate?: z.ZodTypeAny;
  children?: Builder[];
  description?: string;
  component?: (props: FormBuilderFnProps) => React.ReactNode;
}

export interface IsOtherFormFootSelect {
  bilateral: boolean;
  left: boolean;
  right: boolean;
}

export interface FormBuilderFnProps {
  form: UseFormReturnType<Record<string, unknown>>;
  initialValue: string | Record<string, unknown>;
  isOther: IsOtherFormFootSelect;
  toggleField: (fieldKey: string, value: string) => void;
  disabled?: boolean;
}

export interface FormBuilderUIProps {
  buttonProps?: ButtonProps;
}

interface FormBuilderProps extends FormBuilderUIProps {
  formName: string;
  builder: FormBuilderType;
  formSteps: {
    title: string;
    formResults: unknown;
  }[];
  activeStep: number;
  onChangeHandler?: {
    [formKey: string]: (formValues: any, form?: FormBuilderFnProps['form']) => Promise<void> | void;
  }[];
  as?: React.ElementType;
  form?: FormBuilderFnProps['form'];
  disabled?: boolean;
  setFormData: (formName: string, values: Record<string, unknown>) => void;
  callHandler: () => Promise<void>;
  goToNextStep: () => void;
  onSaveRouteTo?: string | null;
}

export const setDefaultFieldValues = (field: string, acc: Record<string, unknown>, curr?: Builder) => {
  if (!curr?.type) {
    return;
  }

  switch (curr.type) {
    case FormTypes.autoComplete:
    case FormTypes.dropdown:
    case FormTypes.checkbox:
    case FormTypes.singleOption:
    case FormTypes.number:
    case FormTypes.numberDropdown:
    case FormTypes.text:
    case FormTypes.textArea:
    case FormTypes.tabs:
      acc[field] = curr.initialValue ?? '';
      break;
    case FormTypes.month:
      acc[field] = curr.initialValue ?? null;
      break;
    case FormTypes.date:
      acc[field] = curr.initialValue ?? new Date();
      break;
    case FormTypes.multipleOption:
    case FormTypes.multiCheckbox:
    case FormTypes.multiSelectBubble:
      acc[field] = curr.initialValue ?? [];
      break;
    default:
      throw new Error(`Invalid form type: ${curr.type}`);
  }
};

export default function FormBuilder({
  formName,
  builder,
  formSteps,
  activeStep,
  buttonProps,
  disabled,
  onChangeHandler,
  as: Component = 'form',
  form: overrideForm,
  setFormData,
  callHandler,
  goToNextStep,
  onSaveRouteTo
}: FormBuilderProps) {
  const [isDevMode, setDevMode] = useLocalStorage({
    key: 'formBuilderDevMode',
    defaultValue: false,
    deserialize: (value) => value === 'true',
    serialize: (value) => value.toString()
  });
  const navigate = useNavigate();
  const [isOnSaveRouteToButtonLoading, setIsOnSaveRouteToButtonLoading] = useState<boolean>(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);

  const setInitialValues = () =>
    Array.isArray(builder)
      ? builder.reduce((acc, curr) => {
          const { field } = curr;
          setDefaultFieldValues(field, acc, curr);
          return acc;
        }, {} as any)
      : undefined;

  const form = useForm({
    name: camelCase(formName),
    initialValues: setInitialValues(),
    validate: zodResolver(
      z.object(
        Array.isArray(builder) &&
          builder.reduce((acc, curr) => {
            if (curr.hidden) {
              return acc;
            }
            acc[curr.field] = curr.validate || z.any();
            return acc;
          }, {} as any)
      )
    ),
    onValuesChange: (formValues) => {
      if (typeof overrideForm !== 'undefined') {
        overrideForm.setValues({
          ...overrideForm.values,
          ...formValues
        });
      }

      if (onChangeHandler) {
        onChangeHandler.forEach((handler) => {
          if (typeof handler[formName] === 'undefined') {
            return;
          }

          handler[formName]?.(formValues, form);
        });
      }
    }
  });

  useEffect(() => {
    if (overrideForm?.values) {
      Object.keys(overrideForm.values).forEach((field) => {
        if (field.endsWith('_other') && !overrideForm.values[field]) {
          const mainField = field.substring(0, field.indexOf('_other'));
          const result = overrideForm.values[mainField];
          if (Array.isArray(result) && result.includes('Other')) {
            overrideForm.setFieldError(field, 'Please fill out this field');
          }
        }
      });
    }
  }, [overrideForm?.values]);

  useEffect(() => {
    /**
     * In the event a previously visible field is now hidden and that field had errors,
     * we want to reset the form errors for that specific field only.
     */
    if (!form.isValid()) {
      if (Array.isArray(builder)) {
        builder.forEach((formBuild) => {
          if (formBuild.hidden && form.errors[formBuild.field]) {
            form.setFieldError(formBuild.field, undefined);

            if (typeof overrideForm !== 'undefined') {
              overrideForm.setFieldError(formBuild.field, undefined);
            }
          }
        });
      }
    }
  }, [form.errors, builder, overrideForm]);

  const handleFormSubmit = async (values: typeof form.values) => {
    if (Array.isArray(builder)) {
      builder.forEach((formBuild) => {
        if (formBuild.hidden) {
          return;
        }

        try {
          formBuild.validate?.parse(values[formBuild.field]);
        } catch (error) {
          if (error instanceof z.ZodError) {
            form.setErrors({
              ...form.errors,
              [formBuild.field]: error.issues.map((issue) => issue.message).join('\n')
            });

            if (typeof overrideForm !== 'undefined') {
              overrideForm.setErrors({
                ...form.errors,
                [formBuild.field]: error.issues.map((issue) => issue.message).join('\n')
              });
            }
          }
        }
      });
    } else {
      try {
        builder.validate?.parse(values);
      } catch (error) {
        if (error instanceof z.ZodError) {
          form.setErrors({
            ...form.errors,
            [formName]: error.issues.map((issue) => issue.message).join('\n')
          });

          if (typeof overrideForm !== 'undefined') {
            overrideForm.setErrors({
              ...form.errors,
              [formName]: error.issues.map((issue) => issue.message).join('\n')
            });
          }
        }
      }
    }
    setFormData(formName, values);
    const formIsValid = typeof overrideForm !== 'undefined' ? overrideForm.isValid() : form.isValid();
    if (formIsValid) {
      await callHandler();
      return true;
    }
    return false;
  };

  const toggleField = (field: string, value: string) => {
    if (typeof form.values[field] === 'undefined') {
      // TODO: might have to fix [value] if its a string-type of field
      form.setFieldValue(field, [value]);

      if (typeof overrideForm !== 'undefined') {
        overrideForm.setFieldValue(field, [value]);
      }

      return;
    }

    if (includes((form.values as Record<string, string[]>)[field], value)) {
      form.setFieldValue(
        field,
        reject((form.values as any)[field], (val: string) => val === value)
      );

      if (typeof overrideForm !== 'undefined') {
        overrideForm.setFieldValue(
          field,
          (form.values as Record<string, string[]>)[field]?.filter((val: string) => val !== value)
        );
      }
    } else {
      const previousValues = (form.values as Record<string, string[]>)[field];

      if (previousValues) {
        form.setFieldValue(field, [...previousValues, value]);

        if (typeof overrideForm !== 'undefined') {
          overrideForm.setFieldValue(field, [...previousValues, value]);
        }
      }
    }
  };
  const isLastStep = activeStep === formSteps.length - 1;
  const showForm = activeStep === formSteps.findIndex((formStep) => formStep.title === formName);
  useEffect(() => {
    if (Array.isArray(builder)) {
      const initialValues = builder.reduce((acc, curr) => {
        const { field } = curr;
        setDefaultFieldValues(field, acc, curr);
        return acc;
      }, {} as any);

      if (onChangeHandler) {
        onChangeHandler.forEach((handler) => {
          if (typeof handler[formName] !== 'undefined') {
            handler[formName]?.(initialValues);
          }
        });
      }
    }
  }, []);
  if (!showForm && activeStep !== -1) {
    return null;
  }

  const formHasErrors = Object.keys(form.errors).length > 0;

  const isBuilderAComponent = !Array.isArray(builder) && typeof builder.component !== 'undefined';

  const renderCTAButton = () => (
    <Stack gap="xs" mt="lg">
      {!!onSaveRouteTo && (
        <Button
          {...buttonProps}
          data-automation-id="form-submit-and-route-button"
          disabled={formHasErrors}
          loading={isOnSaveRouteToButtonLoading}
          fullWidth
          size="lg"
          color="black"
          onClick={async () => {
            setIsOnSaveRouteToButtonLoading(true);
            const result = await handleFormSubmit(form.values);
            setIsOnSaveRouteToButtonLoading(false);
            if (result) {
              notifications.show({
                title: 'Saved changes',
                message: `Successfully saved changes to ${startCase(formName)}`,
                color: 'green'
              });
              navigate(onSaveRouteTo);
            }
          }}
        >
          Save and Back to Review
        </Button>
      )}
      <Button
        {...buttonProps}
        data-automation-id="form-submit"
        disabled={formHasErrors}
        loading={isSubmitButtonLoading}
        fullWidth
        size="lg"
        color="hikeGreen"
        onClick={async () => {
          setIsSubmitButtonLoading(true);
          const result = await handleFormSubmit(form.values);
          setIsSubmitButtonLoading(false);
          if (result) {
            goToNextStep();
          }
        }}
      >
        {isLastStep ? 'Submit' : 'Next'}
      </Button>
    </Stack>
  );

  return (
    <>
      {isDevMode && isDevEnv && Component === 'form' && (
        <Code mb="sm" block color="var(--mantine-color-blue-light)">
          {JSON.stringify(form, null, 2)}
        </Code>
      )}
      {isDevEnv && (
        <Portal>
          <Paper shadow="lg" pos="fixed" right={30} bottom={30}>
            <Button
              styles={{
                label: {
                  fontFamily: 'monospace'
                }
              }}
              leftSection={<IconCode />}
              onClick={() => setDevMode(!isDevMode)}
              variant="filled"
              color="red"
              size="sm"
            >
              dev mode
            </Button>
          </Paper>
        </Portal>
      )}

      <Component name={formName} onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack gap="lg">
          {isBuilderAComponent &&
            !Array.isArray(builder) &&
            React.createElement(builder.component as 'input', {
              form,
              initialValue: builder.initialValue,
              isOther: builder.isOther,
              toggleField,
              disabled
            })}
          {Array.isArray(builder) &&
            builder.map((field) => {
              const fieldKey = field.field;
              const isFieldRequired = !(field.validate instanceof z.ZodOptional);

              const activeForm = overrideForm ?? (form as UseFormReturnType<Record<string, unknown>>);

              if (field.hidden) {
                return null;
              }

              switch (field.type) {
                case FormTypes.singleOption:
                  return (
                    <Stack gap={3} key={fieldKey}>
                      <Stack gap={0}>
                        <Text size="sm" fw="bold" className={isFieldRequired ? 'required' : ''}>
                          {field.label}
                        </Text>
                        {field.description && (
                          <Text pt="sm" pb="sm" fs="italic" size="sm">
                            {field.description}
                          </Text>
                        )}
                        {form.errors[fieldKey as keyof typeof form.errors] && (
                          <Text c="red" size="sm">
                            {form.errors[fieldKey as keyof typeof form.errors] as unknown as string}
                          </Text>
                        )}
                      </Stack>
                      <SimpleGrid cols={2}>
                        {field.options?.map((option) => (
                          <Button
                            data-automation-id={`${fieldKey}-${option.label}`}
                            aria-label={option.label}
                            key={String(option.value)}
                            disabled={disabled}
                            variant={form.values[fieldKey] === option.label ? 'filled' : 'outline'}
                            onClick={
                              form.values[field.field] === option.label
                                ? () => form.setFieldValue(field.field, '')
                                : () => form.setFieldValue(field.field, option.label)
                            }
                            rightSection={form.values[fieldKey] === option.label ? <IconCheck size={14} /> : undefined}
                          >
                            {option.label}
                          </Button>
                        ))}
                      </SimpleGrid>
                    </Stack>
                  );
                case FormTypes.multipleOption:
                  return (
                    <Stack gap={3} key={fieldKey}>
                      <Stack gap={0}>
                        <Text size="sm" fw="bold" className={isFieldRequired ? 'required' : ''}>
                          {field.label}
                        </Text>
                        <Text size="sm">Select all conditions that apply</Text>
                        {form.errors[fieldKey as keyof typeof form.errors] && (
                          <Text c="red" size="sm">
                            {form.errors[fieldKey as keyof typeof form.errors] as unknown as string}
                          </Text>
                        )}
                      </Stack>
                      <SimpleGrid cols={2}>
                        {field.options?.map((option) => (
                          <Button
                            data-automation-id={`${fieldKey}-${option.label}`}
                            py="md"
                            h="fit-content"
                            aria-label={option.label}
                            disabled={disabled}
                            key={String(option.value)}
                            variant={(form.values as any)[fieldKey].includes(option.label) ? 'filled' : 'outline'}
                            onClick={() => toggleField(fieldKey, option.label)}
                            rightSection={
                              (form.values as any)[fieldKey].includes(option.label) ? (
                                <IconCheck size={14} />
                              ) : undefined
                            }
                            styles={{
                              label: {
                                whiteSpace: 'normal'
                              }
                            }}
                          >
                            {option.label}
                          </Button>
                        ))}
                      </SimpleGrid>
                    </Stack>
                  );
                case FormTypes.textArea:
                  return (
                    <Stack key={fieldKey}>
                      <Textarea
                        data-automation-id={fieldKey}
                        label={field.label}
                        disabled={disabled}
                        required={isFieldRequired}
                        {...(field.placeholder && {
                          placeholder: field.placeholder
                        })}
                        {...form.getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                      />
                    </Stack>
                  );
                case FormTypes.text:
                  return (
                    <Stack key={fieldKey}>
                      <TextInput
                        data-automation-id={fieldKey}
                        label={field.label}
                        disabled={disabled}
                        required={isFieldRequired}
                        {...(field.placeholder && {
                          placeholder: field.placeholder
                        })}
                        {...(overrideForm || form).getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                      />
                    </Stack>
                  );
                case FormTypes.number:
                  return (
                    <Stack key={fieldKey}>
                      <NumberInput
                        data-automation-id={fieldKey}
                        inputMode="decimal"
                        label={field.label}
                        disabled={disabled}
                        required={isFieldRequired}
                        {...(field.placeholder && {
                          placeholder: field.placeholder
                        })}
                        {...form.getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                      />
                    </Stack>
                  );
                case FormTypes.numberDropdown:
                  return (
                    <Stack gap={0} key={fieldKey}>
                      <NativeSelect
                        data-automation-id={fieldKey}
                        key={fieldKey}
                        label={field.label}
                        disabled={disabled}
                        required={isFieldRequired}
                        {...form.getInputProps(field.field)}
                        onChange={(e) => {
                          form.getInputProps(field.field).onChange(Number(e.currentTarget.value));
                        }}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                        data={[
                          {
                            value: '',
                            label: 'Select an option'
                          },
                          ...(field.options?.map(
                            (option) =>
                              ({
                                value: Number(option.value),
                                label: option.label
                              }) as any
                          ) ?? [])
                        ]}
                      />
                    </Stack>
                  );
                case FormTypes.month:
                  return (
                    <Stack key={fieldKey}>
                      <MonthPickerInput
                        data-automation-id={fieldKey}
                        key={field.field}
                        disabled={disabled}
                        label={field.label}
                        maxDate={field.maxDate}
                        required={isFieldRequired}
                        {...form.getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                      />
                    </Stack>
                  );

                case FormTypes.date:
                  return (
                    <Stack key={fieldKey}>
                      <DateInput
                        data-automation-id={fieldKey}
                        label={field.label}
                        maxDate={field.maxDate}
                        disabled={disabled}
                        required={isFieldRequired}
                        {...form.getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                      />
                    </Stack>
                  );
                case FormTypes.dropdown:
                  return (
                    <Stack gap={0} key={fieldKey}>
                      <NativeSelect
                        data-automation-id={fieldKey}
                        key={fieldKey}
                        disabled={disabled}
                        label={field.label}
                        required={isFieldRequired}
                        {...form.getInputProps(field.field)}
                        styles={{
                          label: {
                            fontWeight: 700
                          }
                        }}
                        data={[
                          {
                            value: '',
                            label: 'Select an option'
                          },
                          ...(field.options?.map((option) => ({ label: option.label, value: String(option.value) })) ??
                            [])
                        ]}
                      />
                    </Stack>
                  );
                case FormTypes.multiCheckbox:
                  return (
                    <React.Fragment key={fieldKey}>
                      <Text size="sm" fw="bold" className={isFieldRequired ? 'required' : ''}>
                        {field.label}
                      </Text>
                      {form.errors[fieldKey as keyof typeof form.errors] && (
                        <Text c="red" size="sm">
                          {form.errors[fieldKey as keyof typeof form.errors] as unknown as string}
                        </Text>
                      )}
                      <Text size="sm" mb="sm" c="dark.4">
                        {field.description ?? 'Select all conditions that apply'}
                      </Text>

                      <Checkbox.Group
                        value={activeForm?.values[fieldKey] as string[]}
                        onChange={(value) => {
                          activeForm.setFieldValue(fieldKey, value);
                        }}
                      >
                        <Stack>
                          {field.options?.map((option) => (
                            <Checkbox
                              data-automation-id={`${fieldKey}-${option.label}`}
                              disabled={disabled}
                              value={option.label}
                              key={`${fieldKey}-${String(option.value)}`}
                              label={option.label}
                            />
                          ))}
                        </Stack>
                      </Checkbox.Group>
                    </React.Fragment>
                  );
                case FormTypes.checkbox:
                  return (
                    <React.Fragment key={fieldKey}>
                      <Text size="sm" fw="bold" className={isFieldRequired ? 'required' : ''}>
                        {field.label}
                      </Text>
                      {form.errors[fieldKey as keyof typeof form.errors] && (
                        <Text c="red" size="sm">
                          {form.errors[fieldKey as keyof typeof form.errors] as unknown as string}
                        </Text>
                      )}

                      {field.options?.map((option) => (
                        <Checkbox
                          data-automation-id={`${fieldKey}-${option.label}`}
                          key={`${fieldKey}-${String(option.value)}`}
                          value={option.label}
                          label={option.label}
                          disabled={disabled}
                          onChange={() => {
                            if (activeForm.values[fieldKey] === option.label) {
                              activeForm.setFieldValue(fieldKey, '');
                            } else {
                              activeForm.setFieldValue(fieldKey, option.label);
                            }
                          }}
                          checked={activeForm.values[fieldKey] === option.label}
                        />
                      ))}
                    </React.Fragment>
                  );

                case FormTypes.multiSelectBubble:
                  return (
                    <Stack gap={0} key={fieldKey}>
                      <Text size="sm" fw="bold" className={isFieldRequired ? 'required' : ''}>
                        {field.label}
                      </Text>
                      {form.errors[fieldKey as keyof typeof form.errors] && (
                        <Text c="red" size="sm">
                          {form.errors[fieldKey as keyof typeof form.errors] as unknown as string}
                        </Text>
                      )}
                      <Box
                        p="md"
                        mt="sm"
                        bg="#F4F3F7"
                        style={{
                          borderRadius: rem(10)
                        }}
                      >
                        <Text size="sm">{field.description ?? 'Tap to select all that apply'}</Text>
                        <SimpleGrid mt="lg" cols={2} className={css.optionsGrid}>
                          {field.options?.map((option) => (
                            <Button
                              data-automation-id={`${fieldKey}-${option.label}`}
                              h="100%"
                              aria-label={option.label}
                              key={option.label}
                              disabled={disabled}
                              color="dark.5"
                              styles={{
                                label: {
                                  whiteSpace: 'normal',
                                  paddingTop: rem(15),
                                  paddingBottom: rem(15)
                                }
                              }}
                              variant={(form.values as any)[fieldKey].includes(option.label) ? 'filled' : 'light'}
                              onClick={() => toggleField(fieldKey, option.label)}
                              rightSection={
                                (form.values as any)[fieldKey].includes(option.label) ? (
                                  <IconCheck size={14} />
                                ) : undefined
                              }
                            >
                              {option.label}
                            </Button>
                          ))}
                        </SimpleGrid>
                      </Box>
                    </Stack>
                  );
                case FormTypes.autoComplete:
                  return React.createElement(field.component as unknown as 'input', {
                    form,
                    toggleField,
                    key: fieldKey,
                    initialValue: field.initialValue,
                    disabled
                  });
                default:
                  throw new Error(`Invalid form type: ${field.type}`);
              }
            })}
        </Stack>
        {formHasErrors && (
          <Alert variant="light" color="red" title="Error" mt="xl" icon={<IconExclamationCircle />}>
            There are errors in the form. Please fix them before {isLastStep ? 'submitting' : 'continuing'}.
          </Alert>
        )}
        {Component === 'form' && renderCTAButton()}
      </Component>
    </>
  );
}
