import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function RearfootPosting({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_rearfoot_posting_type`]: [
          {
            field: `${footDirection}_rearfoot_posting_type`,
            label: `${startCase(footDirection)} Rearfoot Post Type`,
            type: FormTypes.checkbox,
            initialValue: initialValues.rearfoot_post?.[`${footDirection}_rearfoot_posting_type`],
            options: [
              {
                label: 'Extrinsic',
                value: 'extrinsic'
              },
              {
                label: 'Intrinsic',
                value: 'intrinsic'
              }
            ],
            validate: zodHelpers.optionalText
          },
          {
            field: `${footDirection}_rearfoot_posting_angling`,
            label: `${startCase(footDirection)} Rearfoot Post Angling`,
            type: FormTypes.checkbox,
            initialValue: initialValues.rearfoot_post?.[`${footDirection}_rearfoot_posting_angling`],
            options: [
              {
                label: 'Lateral',
                value: 'lateral'
              },
              {
                label: 'Medial',
                value: 'medial'
              },
              {
                label: 'Neutral',
                value: 'neutral'
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
