import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';

export default function Edema({ form, initialValue, isOther, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_edema`]: [
          {
            field: `${footDirection}_edema`,
            type: FormTypes.multiCheckbox,
            initialValue: initialValues.edema?.[`${footDirection}_edema`],
            options: [
              {
                label: 'Full Foot',
                value: 'fullFoot'
              },
              {
                label: 'Forefoot',
                value: 'forefoot'
              },
              {
                label: 'Ankle',
                value: 'ankle'
              },
              {
                label: 'Calf',
                value: 'calf'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            validate: zodHelpers.optionalMultiCheckbox
          },
          {
            field: `${footDirection}_edema_other`,
            label: `Please describe the ${footDirection !== 'bilateral' ? `${footDirection} foot` : ''} edema:`,
            type: FormTypes.text,
            hidden: !isOther[footDirection],
            initialValue: initialValues.edema?.[`${footDirection}_edema_other`],
            validate: zodHelpers.requiredText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
