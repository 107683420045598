import FootSelector from '@/pages/HikeScan/FootSelector/FootSelector';
import NotAllowedError from '@/pages/HikeScan/PermissionsAndErrors/NotAllowedError';
import PreCheckReport from '@/pages/HikeScan/PreCheck/PreCheckReport';
import Previewing from '@/pages/HikeScan/Previewing';
import Recording from '@/pages/HikeScan/Recording';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { useGetDeviceOrientation } from '@/utils/useGetDeviceOrientation';
import { Box, Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useOs, useSetState, useViewportSize } from '@mantine/hooks';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { createContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import css from './FootEval.module.css';

const mimeType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm';
const MAX_RECORDING_TIME = 40;
export enum PRECHECK_VIEWS {
  FOOT_SELECTOR = 'Foot Selector',
  LIGHTING = 'Lighting',
  VISIBLE_HEEL = 'Visible Heel',
  OVERALL_GOOD_SCAN = 'Overall Good Scan',
  REPORT = 'Report'
}

export type FeetDirections = 'left' | 'right';

interface Results {
  leftFootScanSuccess: boolean;
  leftFootScanUploading: boolean;
  leftFoodScanError: string;
  rightFootScanSuccess: boolean;
  rightFootScanUploading: boolean;
  rightFoodScanError: string;
  leftFootScanRecording: boolean;
  rightFootScanRecording: boolean;
  leftFootScanPreviewing: boolean;
  rightFootScanPreviewing: boolean;
  leftFootScanPreviewDataUrl: string;
  rightFootScanPreviewDataUrl: string;
  leftFootScanPreviewPlaying: boolean;
  rightFootScanPreviewPlaying: boolean;
  leftFootScanPreviewName: string;
  rightFootScanPreviewName: string;
  leftFootScanBlob: Blob | null;
  rightFootScanBlob: Blob | null;
  leftFootScanFullRecord: boolean;
  rightFootScanFullRecord: boolean;
  leftRescanValue: string;
  rightRescanValue: string;
  // base64 encoded screenshot
  leftFootScanScreenshotData: string;
  rightFootScanScreenshotData: string;
}

export const FootEvalContext = createContext({
  precheckView: PRECHECK_VIEWS.LIGHTING,
  timeRemaining: MAX_RECORDING_TIME,
  precheckConditions: {
    [PRECHECK_VIEWS.LIGHTING]: false,
    [PRECHECK_VIEWS.VISIBLE_HEEL]: false,
    [PRECHECK_VIEWS.OVERALL_GOOD_SCAN]: false
  },
  resetPrecheck: () => {},
  onPrecheckAnswer: (_condition: PRECHECK_VIEWS, _isMet: boolean) => {},
  setResults: (_results: Partial<Results>) => {},
  results: {
    leftFootScanSuccess: false,
    leftFootScanUploading: false,
    leftFoodScanError: '',
    rightFootScanSuccess: false,
    rightFootScanUploading: false,
    rightFoodScanError: '',

    // recording
    leftFootScanRecording: false,
    rightFootScanRecording: false,

    // preview
    leftFootScanPreviewing: false,
    rightFootScanPreviewing: false,
    leftFootScanPreviewDataUrl: '',
    rightFootScanPreviewDataUrl: '',
    leftFootScanPreviewPlaying: false,
    rightFootScanPreviewPlaying: false,
    leftFootScanPreviewName: '',
    rightFootScanPreviewName: '',

    leftFootScanBlob: null,
    rightFootScanBlob: null,
    leftRescanValue: '',
    rightRescanValue: '',
    // screenshots for preview
    leftFootScanScreenshotData: '',
    rightFootScanScreenshotData: '',
    leftFootScanFullRecord: false,
    rightFootScanFullRecord: false
  } as Results,
  shouldRescanPatient: false,
  footSelected: undefined as FeetDirections | undefined,
  showRecordScren: (_foot: FeetDirections) => {},
  startRecording: (_foot: FeetDirections) => {},
  stopRecording: () => {},
  generatePreviewUrl: () => {},
  startPreviewing: () => {},
  stopPreviewing: () => {},
  clearScreenshot: () => {},
  clearPreviewData: () => {},
  resetScan: () => {},
  setPrecheckView: (_view: PRECHECK_VIEWS) => {},
  toggleCamera: () => {}
});

const PRE_CHECK_VIEWS = [
  PRECHECK_VIEWS.FOOT_SELECTOR,
  PRECHECK_VIEWS.LIGHTING,
  PRECHECK_VIEWS.VISIBLE_HEEL,
  PRECHECK_VIEWS.OVERALL_GOOD_SCAN,
  PRECHECK_VIEWS.REPORT
];

export default function FootEval() {
  const { footDirection, visit_id } = useParams();
  const { isPortrait } = useGetDeviceOrientation();
  const os = useOs();
  const webcamRef = useRef<
    Webcam & {
      stream: MediaStream;
    }
  >(null);
  const mediaRecorderRef = useRef<
    | (MediaRecorder & {
        stream: MediaStream;
      })
    | null
  >(null);
  const { width, height } = useViewportSize();
  const [facing, setFacing] = useState('environment');
  const [hasNotAllowedError, setHasNotAllowedError] = useState(false);
  const [footSelected, setFootSelected] = useState<FeetDirections>('left');
  const [precheckView, setPrecheckView] = useState(PRECHECK_VIEWS.FOOT_SELECTOR);
  const [timeRemaining, setTimeRemaining] = useState(MAX_RECORDING_TIME);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [precheckConditions, setPrecheckConditions] = useSetState({
    [PRECHECK_VIEWS.LIGHTING]: false,
    [PRECHECK_VIEWS.VISIBLE_HEEL]: false,
    [PRECHECK_VIEWS.OVERALL_GOOD_SCAN]: false
  });
  const isMimetypeErrorFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.MIMETYPE_ERROR_PREVIEWING);

  const [results, setResults] = useSetState<Results>({
    leftFootScanRecording: false,
    leftFootScanSuccess: false,
    leftFootScanUploading: false,
    leftFoodScanError: '',
    rightFootScanRecording: false,
    rightFootScanSuccess: false,
    rightFootScanUploading: false,
    rightFoodScanError: '',

    leftFootScanPreviewing: false,
    rightFootScanPreviewing: false,
    leftFootScanPreviewDataUrl: '',
    rightFootScanPreviewDataUrl: '',
    leftFootScanPreviewPlaying: false,
    rightFootScanPreviewPlaying: false,
    leftFootScanPreviewName: '',
    rightFootScanPreviewName: '',

    leftFootScanBlob: null,
    rightFootScanBlob: null,
    leftRescanValue: '',
    rightRescanValue: '',
    leftFootScanScreenshotData: '',
    rightFootScanScreenshotData: '',

    leftFootScanFullRecord: false,
    rightFootScanFullRecord: false
  });

  const onUserMediaError = (error: string | DOMException) => {
    if (error instanceof DOMException) {
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      if (error.name === 'NotAllowedError') {
        setHasNotAllowedError(true);
      }
    }
  };

  const togglePrecheckView = () => {
    const currentViewIndex = PRE_CHECK_VIEWS.findIndex((view) => view === precheckView);
    const nextView = PRE_CHECK_VIEWS[currentViewIndex + 1];
    if (nextView) {
      setPrecheckView(nextView);
    }
  };

  const onPrecheckAnswer = (condition: PRECHECK_VIEWS, isMet: boolean) => {
    setPrecheckConditions({ [condition]: isMet });
    togglePrecheckView();
  };

  const resetPrecheck = () => {
    setPrecheckConditions({
      [PRECHECK_VIEWS.LIGHTING]: false,
      [PRECHECK_VIEWS.VISIBLE_HEEL]: false,
      [PRECHECK_VIEWS.OVERALL_GOOD_SCAN]: false
    });
    setPrecheckView(PRECHECK_VIEWS.LIGHTING);
  };

  // if we get to report screen and all precheck conditions are false,
  const shouldRescanPatient =
    Object.values(precheckConditions).some((isMet) => !isMet) && precheckView === PRECHECK_VIEWS.REPORT;

  const [leftFootScanPreviewData, setLeftFootScanPreviewData] = useState<Blob[]>([]);
  const [rightFootScanPreviewData, setRightFootScanPreviewData] = useState<Blob[]>([]);

  const handleDataAvailable = ({ data }: { data: Blob }, foot: FeetDirections) => {
    console.log('data available for ', foot);
    if (data.size > 0) {
      if (foot === 'left') {
        console.warn(`Adding data to left foot scan preview data: ${data}`);
        setLeftFootScanPreviewData((prev) => [...prev, data]);
      }

      if (foot === 'right') {
        console.warn(`Adding data to right foot scan preview data: ${data}`);
        setRightFootScanPreviewData((prev) => [...prev, data]);
      }
    }
  };

  const handleMediaRecorderError = (event: Event) => {
    console.error(JSON.stringify(event, null, 2));
    console.trace();
  };

  const startInterval = () => {
    console.log('[startInterval] Starting timer interval');
    intervalRef.current = setInterval(() => {
      setTimeRemaining((prevTime) => {
        console.log(`[startInterval] Time Remaining: ${prevTime - 1}`);
        return prevTime - 1;
      });
    }, 1000);
  };

  const stopInterval = () => {
    console.log('[stopInterval] Stop interval called');
    if (intervalRef.current) {
      console.log('[stopInterval] Clearing interval');
      clearInterval(intervalRef.current);
      setTimeRemaining(MAX_RECORDING_TIME);
    }
  };
  const startRecording = (foot: FeetDirections) => {
    if (results[`${foot}FootScanFullRecord`] === true) {
      console.log('[startRecording] Recording has already been started.');
      return;
    }
    setLeftFootScanPreviewData([]);
    setRightFootScanPreviewData([]);
    console.log(`Starting recording for ${foot} foot. Recording stream with mimeType ${mimeType}`);
    startInterval();
    if (webcamRef.current) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType
      });
      mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
        handleDataAvailable(event, foot);
      });
      mediaRecorderRef.current.addEventListener('error', handleMediaRecorderError);
      mediaRecorderRef.current.start();
    }
  };
  const clearScreenshot = () => {
    setResults({
      [`${footSelected}FootScanScreenshotData`]: ''
    });
  };
  const generateScreenshot = () => {
    if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot();

      if (screenshot) {
        setResults({
          [`${footSelected}FootScanScreenshotData`]: screenshot
        });
      }
    }
  };
  const stopRecording = () => {
    if (results[`${footSelected}FootScanFullRecord`] === false) {
      console.log('[stopRecording] Recording has already been stopped.');
      return;
    }
    stopInterval();
    console.log(`Stopping recording for ${footSelected} foot`);
    mediaRecorderRef.current?.stop();
    setResults({
      [`${footSelected}FootScanRecording`]: false,
      [`${footSelected}FootScanFullRecord`]: false
    });
    generateScreenshot();
  };

  useEffect(() => {
    console.log('[timer useEffect] Time remaning:', timeRemaining);
    if ((results.leftFootScanFullRecord || results.rightFootScanFullRecord) && timeRemaining === 0) {
      console.log('[timer useEffect] Time remaining is 0. Calling handleStopCaptureClick.');
      stopRecording();
    }
  }, [timeRemaining, results.leftFootScanFullRecord, results.rightFootScanFullRecord]);

  const showRecordScren = (foot: FeetDirections) => {
    setFootSelected(foot);
    setResults({
      [`${foot}FootScanPreviewing`]: false,
      [`${foot}FootScanRecording`]: true
    });
  };

  const generatePreviewUrl = () => {
    const previewData = footSelected === 'left' ? leftFootScanPreviewData : rightFootScanPreviewData;
    console.log(`Generating preview url for ${footSelected} foot scan with ${previewData} blobs`);

    if (!previewData.length) {
      console.error(`No preview data available for ${footSelected} foot scan. Unable to generate preview URL.`);
      return;
    }

    const previewBlob = new Blob(previewData, { type: mimeType });
    console.log(`Generated blob from preview data with size ${previewBlob.size} and type ${previewBlob.type}`);

    const dateNow = Date.now();
    const previewName = `${dateNow}-PREVIEW-${visit_id}-${footSelected}`;

    setResults({
      [`${footSelected}FootScanBlob`]: previewBlob,
      [`${footSelected}FootScanPreviewName`]: previewName
    });

    console.log(`Set blob and preview name: ${previewName}`);

    const previewDataUrl = URL.createObjectURL(previewBlob);

    console.log(`Generated preview url for ${footSelected} foot scan: ${previewDataUrl}`);

    setResults({
      [`${footSelected}FootScanPreviewDataUrl`]: previewDataUrl,
      [`${footSelected}FootScanPreviewing`]: true
    });

    console.log(`Generating preview complete, result state: ${JSON.stringify(results, null, 2)}`);
  };

  const clearPreviewData = () => {
    if (footSelected === 'left') {
      setLeftFootScanPreviewData([]);
    }

    if (footSelected === 'right') {
      setRightFootScanPreviewData([]);
    }
  };

  const startPreviewing = () => {
    console.log(`Starting preview for ${footSelected} foot scan`);
    if (isMimetypeErrorFeatureFlagEnabled) {
      console.log('MimeType FF enabled');
    } else {
      console.log('MimeType FF disabled');
    }
    generatePreviewUrl();
  };

  const stopPreviewing = () => {
    setResults({
      leftFootScanPreviewing: false,
      rightFootScanPreviewing: false,
      leftFootScanPreviewPlaying: false,
      rightFootScanPreviewPlaying: false
    });
  };

  const resetScan = () => {
    resetPrecheck();
    setResults({
      leftFoodScanError: '',
      rightFoodScanError: '',
      leftRescanValue: '',
      rightRescanValue: ''
    });
    setResults({
      [`${footSelected}FootScanFullRecord`]: false,
      [`${footSelected}FootScanRecording`]: false
    });
    setLeftFootScanPreviewData([]);
    setRightFootScanPreviewData([]);
    clearScreenshot();
    stopPreviewing();
  };

  const toggleCamera = async () => {
    if (!results.leftFootScanFullRecord && !results.rightFootScanFullRecord) {
      if (facing === 'user') {
        setFacing('environment');
      } else {
        setFacing('user');
      }
    }
  };

  useEffect(() => {
    if (footDirection) {
      setFootSelected(footDirection as FeetDirections);
    }
  }, [footDirection]);

  const isReadyToPlayPreviewVideo =
    results[`${footSelected}FootScanPreviewing`] &&
    !!results[`${footSelected}FootScanPreviewDataUrl`] && // converts URL string to boolean - returns true if string length > 0
    results[`${footSelected}FootScanPreviewDataUrl`] !== '' && // sanity check, should not be necessary
    results[`${footSelected}FootScanPreviewDataUrl`].startsWith('blob:');

  return (
    <>
      {!hasNotAllowedError && (
        <Modal centered withCloseButton={false} opened={!isPortrait && os === 'android'} onClose={() => {}}>
          <Stack gap="sm" className={css.portraitModal}>
            <Group>
              <IconExclamationCircle />
              <Title order={3}>Rotate your device</Title>
            </Group>
            <Text>Patient scans must be done in portrait mode, please rotate your device to continue.</Text>
          </Stack>
        </Modal>
      )}

      <FootEvalContext.Provider
        value={{
          precheckView,
          precheckConditions,
          onPrecheckAnswer,
          results,
          resetPrecheck,
          setResults,
          shouldRescanPatient,
          footSelected,
          timeRemaining,
          // recording fns
          startRecording,
          stopRecording,
          showRecordScren,
          generatePreviewUrl,
          startPreviewing,
          stopPreviewing,
          clearScreenshot,
          clearPreviewData,
          resetScan,
          setPrecheckView,
          toggleCamera
        }}
      >
        <Box className={css.container}>
          {!hasNotAllowedError && (
            <>
              {precheckView === PRECHECK_VIEWS.FOOT_SELECTOR && <FootSelector />}

              <PreCheckReport />
              <Recording />
              <Previewing />
              {isReadyToPlayPreviewVideo && (
                <ReactPlayer
                  playsinline
                  pip={false}
                  loop
                  width={width}
                  height={height}
                  url={[
                    { src: results[`${footSelected}FootScanPreviewDataUrl`], type: 'video/mp4' },
                    { src: results[`${footSelected}FootScanPreviewDataUrl`], type: 'video/webm' }
                  ]}
                  onReady={() => {
                    if (isMimetypeErrorFeatureFlagEnabled) {
                      console.log(
                        `Mimetype feature flag enabled for this user. Ready to play ${footSelected} foot video with the following URl: ${results[`${footSelected}FootScanPreviewDataUrl`]}`
                      );
                      setResults({
                        [`${footSelected}FootScanPreviewPlaying`]: true
                      });
                    } else {
                      console.log(
                        `Mimetype feature flag disabled for this user. React Player onReady function called for ${footSelected} foot video with the following URl: ${results[`${footSelected}FootScanPreviewDataUrl`]}. No action taken.`
                      );
                    }
                  }}
                  playing={
                    isMimetypeErrorFeatureFlagEnabled
                      ? results[`${footSelected}FootScanPreviewPlaying`]
                      : isReadyToPlayPreviewVideo
                  }
                  onError={(error) => {
                    console.error(`Failed to use preview link: ${results[`${footSelected}FootScanPreviewDataUrl`]}`);
                    console.error('React player onError: ', JSON.stringify(error, null, 2));
                    console.trace();
                  }}
                />
              )}

              <Webcam
                ref={webcamRef}
                width={width}
                height={height}
                disablePictureInPicture
                className={css.webcam}
                onUserMediaError={onUserMediaError}
                videoConstraints={{
                  facingMode: { ideal: facing },
                  width: 1920,
                  height: 1080
                }}
                mirrored={facing === 'user'}
              />
            </>
          )}

          {hasNotAllowedError && <NotAllowedError />}
        </Box>
      </FootEvalContext.Provider>
    </>
  );
}
