import { ROUTES } from '@/Router';
import { useClinicalSubmitOrder, useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useGetInitialCriticalReviewData } from '@/api/forms';
import HikeLoadingOverlay from '@/components/HikeLoadingOverlay/HikeLoadingOverlay';
import { UseCriticalInfoResponse } from '@/types/critical-info.form.type';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { queryClient } from '@/utils/queryClient';
import {
  ActionIcon,
  Alert,
  AppShell,
  Box,
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconChevronLeft, IconPencil } from '@tabler/icons-react';
import { useMutationState } from '@tanstack/react-query';
import { isEmpty, kebabCase, startCase } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Fragment, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Link, Navigate, createSearchParams, generatePath, useParams } from 'react-router-dom';
import ClinicianSignature from '../ClinicalWorkflow/ClinicianSignature/ClinicianSignature';
import { ClinicalPortalTabs } from '../ClinicianPortal/ClinicianPortal';
import css from './ReviewCriticalInfo.module.css';
import ReviewFormFields from './ReviewFormFields';

const HEADER_HEIGHT = 64;

export default function ReviewCriticalInfo() {
  const { visit_id } = useParams() as { visit_id: string };
  const theme = useMantineTheme();
  const [submitOpen, submitHandlers] = useDisclosure(false);
  const isConfirmOrderFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.CONFIRM_ORDER_SCREEN);
  const isReviewCriticalInfoFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.REVIEW_CRITICAL_INFO_SCREEN);
  const {
    data: workflowInfo,
    isLoading: isWorkflowInfoLoading,
    isError: isWorkflowInfoError,
    isSuccess: isWorkflowInfoSuccess
  } = useGetWorkflowDashboard(visit_id, {
    refetchOnMount: true
  });

  const {
    data: queriedFormData,
    isLoading: initialFormDataLoading,
    isError: initialFormDataError,
    isSuccess: initialFormDataSuccess
  } = useGetInitialCriticalReviewData(visit_id, {
    refetchOnMount: true
  });

  const { mutate: mutateSubmit, isPending: submitLoading } = useClinicalSubmitOrder({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      window.scrollTo(0, 0);
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      submitHandlers.open();
    }
  });

  const handleSubmitOrder = () => {
    mutateSubmit({ visit_id });
  };

  const mutateSignaturePDF = useMutationState({
    filters: {
      mutationKey: ['save-signature-and-ambulatory-data'],
      status: 'error'
    }
  });

  const hasFailedSignatureOrAmbulatoryData = mutateSignaturePDF.some((state) => state.status === 'error');

  function waitForAddedNode(id: string, callback: (element: HTMLElement) => void) {
    const observer = new MutationObserver(() => {
      const el = document.getElementById(id);
      if (el) {
        observer.disconnect();
        callback(el);
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true
    });
  }

  const scrollToElement = (element: HTMLElement) => {
    window.scrollTo({
      top: element.offsetTop - HEADER_HEIGHT
    });
    element.classList.add(css.highlighted!);
  };

  useEffect(() => {
    if (window.location.hash) {
      waitForAddedNode(window.location.hash.substring(1), scrollToElement);
    }
  }, []);

  const getEditFormRoute = (formTitle: string, pageTitle?: string, elementId?: string) => {
    if (formTitle === 'clinical-workflow') {
      const searchParam = createSearchParams({
        review: 'true'
      }).toString();

      return {
        pathname: `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
          visit_id
        })}`,
        search: searchParam
      };
    }

    const formPath = `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
      visit_id
    })}/${kebabCase(formTitle)}/${kebabCase(pageTitle)}`;

    const onSaveRouteToParam = createSearchParams({
      onSaveRouteTo: `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
        visit_id
      })}/${ROUTES.REVIEW_CRITICAL_INFO}#${elementId}`
    }).toString();

    return {
      pathname: formPath,
      search: onSaveRouteToParam
    };
  };

  const displayCriticalFormData = (formData: UseCriticalInfoResponse) => {
    const formTitles = Object.keys(formData);
    return formTitles.map((title, titleId) => {
      if (title === 'review-form') {
        return null;
      }
      if (title === 'clinical-workflow') {
        const fields = formData[title];
        return (
          <Fragment key={`${title}-${titleId}`}>
            <Stack pl={8} pr={8} pt={32} pb={32} gap={8}>
              <Group justify="space-between" pb={16}>
                <Text size="lg" fw={700}>
                  Clinical Workflow
                </Text>
                <ActionIcon
                  component={Link}
                  to={getEditFormRoute(title)}
                  size="lg"
                  variant="outline"
                  color={theme.colors.hike?.[9]}
                  style={{
                    borderWidth: 1.5
                  }}
                >
                  <IconPencil />
                </ActionIcon>
              </Group>
              {Object.keys(fields).map((field, id) => (
                <Stack key={`${title}-${field}-${id}`} gap={0}>
                  <Text fw={500}>{startCase(field)}:</Text>
                  <Text fw={300}>{fields[field] ?? '-'}</Text>
                </Stack>
              ))}
            </Stack>
            <Divider />
          </Fragment>
        );
      }
      const pages = formData[title];
      return Object.keys(pages).map((page, pageId) => {
        const fields = pages[page];
        if (!isEmpty(fields) && Object.keys(fields).some((field: string) => !isEmpty(fields[field]))) {
          return (
            <Fragment key={`${page}-${pageId}`}>
              <Stack pl={8} pr={8} pt={32} pb={32} gap={8} id={`${page}-${pageId}`}>
                <Group justify="space-between" pb={16}>
                  <Text size="lg" fw={700}>
                    {startCase(page)}
                  </Text>
                  <ActionIcon
                    component={Link}
                    to={getEditFormRoute(title, page, `${page}-${pageId}`)}
                    size="lg"
                    variant="outline"
                    color={theme.colors.hike?.[9]}
                    style={{
                      borderWidth: 1.5
                    }}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Group>
                {Object.keys(fields).map((field, fieldId) => {
                  const value = fields[field];
                  if (!Array.isArray(value) && typeof value === 'object') {
                    return Object.keys(value).map((subvalue, subvalueId) => (
                      <Stack key={`${title}-${field}-${value}-${subvalue}-${subvalueId}`} gap={0}>
                        <Text fw={500}>{startCase(subvalue)}:</Text>
                        <Text fw={300}>
                          {(Array.isArray(value[subvalue]) ? value[subvalue].join(', ') : value[subvalue]) || '-'}
                        </Text>
                      </Stack>
                    ));
                  }
                  return (
                    <Stack key={`${title}-${field}-${fieldId}`} gap={0}>
                      <Text fw={500}>{startCase(field)}:</Text>
                      <Text fw={300}>{(Array.isArray(value) ? value.join(', ') : value) || '-'}</Text>
                    </Stack>
                  );
                })}
              </Stack>
              <Divider />
            </Fragment>
          );
        }
        return null;
      });
    });
  };

  const routeBackToWorkflowCondition =
    (typeof isReviewCriticalInfoFeatureFlagEnabled !== 'undefined' && !isReviewCriticalInfoFeatureFlagEnabled) ||
    (isWorkflowInfoSuccess && !workflowInfo.are_forms_completed);

  if (routeBackToWorkflowCondition) {
    notifications.show({
      title: 'Missing fields exist',
      message: 'Please complete the form to continue with the review process.',
      color: 'red',
      autoClose: 7500
    });
    return <Navigate to={generatePath(ROUTES.CLINICAL_WORKFLOW)} replace />;
  }

  if (!visit_id) {
    return (
      <Box m="xs">
        <Alert variant="filled" color="#BA1A1A" title="Failed to get visit ID from session storage" mt="xs">
          An error occurred getting the visit ID.
        </Alert>
      </Box>
    );
  }

  const isError = isWorkflowInfoError || initialFormDataError;
  const isLoading = isWorkflowInfoLoading || initialFormDataLoading;

  const isSubmitButtonDisabled =
    !workflowInfo?.is_submittable || hasFailedSignatureOrAmbulatoryData || workflowInfo?.submitted;

  if (isError) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to fetch critical form data" mt="xs">
        An error occurred getting critical form data for visit.
      </Alert>
    );
  }

  return (
    <HikeLoadingOverlay isLoading={isLoading}>
      <AppShell pl="sm" pr="sm" header={{ height: HEADER_HEIGHT }} footer={{ height: 80 }}>
        <AppShell.Header>
          <Group h={HEADER_HEIGHT} p="sm">
            <Button
              data-automation-id="back-to-workflow-button-review-screen"
              aria-label="Back to Dashboard"
              component={Link}
              to={generatePath(ROUTES.CLINICAL_WORKFLOW)}
              variant="light"
              size="sm"
              leftSection={<IconChevronLeft />}
            >
              Back to Workflow
            </Button>
          </Group>
        </AppShell.Header>
        <AppShell.Main>
          <Title aria-label="review-critical-info-title" order={2} mt={24} mb={48}>
            Review Critical Info
          </Title>
          {initialFormDataSuccess && (
            <>
              <ReviewFormFields {...queriedFormData['review-form']} />
              {displayCriticalFormData(queriedFormData)}
            </>
          )}
          {!isConfirmOrderFeatureFlagEnabled && (
            <Box pt={32} pb={32}>
              <ClinicianSignature />
            </Box>
          )}
        </AppShell.Main>
        <AppShell.Footer>
          <Group justify="flex-end" mt="md" pl="md" pr="md">
            {isConfirmOrderFeatureFlagEnabled ? (
              <Button
                size="lg"
                disabled={!workflowInfo?.are_forms_completed}
                component={Link}
                to={`${generatePath(ROUTES.CLINICAL_WORKFLOW, {
                  visit_id
                })}/${ROUTES.CONFIRM_ORDER}`}
                fullWidth
              >
                Next Step: Confirm Order
              </Button>
            ) : (
              <Button
                size="lg"
                disabled={isSubmitButtonDisabled}
                onClick={handleSubmitOrder}
                fullWidth
                loading={submitLoading}
              >
                Submit Order
              </Button>
            )}
          </Group>
        </AppShell.Footer>
      </AppShell>
      {submitOpen && <Confetti />}
      <Modal opened={submitOpen} onClose={() => submitHandlers.close()} centered title="Your order has been submitted!">
        <Stack>
          Congratulations on successfully submitting your order for patient: {workflowInfo?.patient_name}
          <Button
            data-automation-id="return-to-clinician-portal-button"
            component={Link}
            to={generatePath(ROUTES.PORTAL, { tabValue: ClinicalPortalTabs.Completed })}
            size="xs"
            fullWidth
          >
            Return to Clinician Portal
          </Button>
        </Stack>
      </Modal>
    </HikeLoadingOverlay>
  );
}
