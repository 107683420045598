import { useEffect, useState } from 'react';

type Orientation = 'portrait' | 'landscape';

interface UseGetDeviceOrientation {
  orientation: Orientation;
  isPortrait: boolean;
}

export const useGetDeviceOrientation = (): UseGetDeviceOrientation => {
  const [orientation, setOrientation] = useState<Orientation>('portrait');

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      } else {
        setOrientation('landscape');
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return {
    orientation,
    isPortrait: orientation === 'portrait'
  };
};
