import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { ROUTES } from '@/Router';
import { Alert, Button, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure, useDocumentTitle } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { generatePath, Link, useNavigate, useOutlet, useParams } from 'react-router-dom';

export default function ClincialWorkflowWrapper({ children }: { children: React.ReactNode }) {
  const outlet = useOutlet();
  const params = useParams();
  const navigate = useNavigate();
  const visitIdString = params.visit_id as string;
  const [submitOpen, submitHandlers] = useDisclosure(false);

  useEffect(() => {
    const existingVisitIdInSessionStorage = sessionStorage.getItem('visitId');

    if (existingVisitIdInSessionStorage && (!visitIdString || visitIdString === ':visit_id')) {
      navigate(
        generatePath(ROUTES.CLINICAL_WORKFLOW, {
          visit_id: existingVisitIdInSessionStorage
        }),
        {
          replace: true
        }
      );
    } else if ((!existingVisitIdInSessionStorage || existingVisitIdInSessionStorage === 'undefined') && visitIdString) {
      submitHandlers.open();
    } else if (
      (!existingVisitIdInSessionStorage || existingVisitIdInSessionStorage === 'undefined') &&
      (!visitIdString || visitIdString === ':visit_id')
    ) {
      navigate(generatePath(ROUTES.PORTAL));
    } else if (existingVisitIdInSessionStorage !== visitIdString) {
      navigate(generatePath(ROUTES.PORTAL));
    }
  }, [params, visitIdString]);

  const handleModalClose = () => {
    sessionStorage.setItem('visitId', visitIdString);
    submitHandlers.close();
  };

  const { data: workflowInfo } = useGetWorkflowDashboard(visitIdString, {
    enabled: !!visitIdString
  });

  useDocumentTitle('Hike Clinical');

  if (!visitIdString || visitIdString === ':visit_id') {
    return <Loader color="blue" />;
  }
  if (Number.isNaN(parseInt(visitIdString, 10))) {
    return (
      <Alert variant="light" color="red" title="Missing visit id">
        <Text size="sm">Visit ID is not valid. Please go back to the portal and select a patient.</Text>
        <Button component={Link} to={ROUTES.HOME} mt="md" color="red">
          Go back
        </Button>
      </Alert>
    );
  }

  return (
    <>
      <Modal
        title="Verify patient selection"
        opened={submitOpen}
        onClose={submitHandlers.close}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Stack mt="md" gap="0">
          <Text size="sm">Are you sure you want to continue with this patient:</Text>
          <Text size="sm" fw="bold">
            Name: {workflowInfo?.patient_name}
          </Text>
          <Text size="sm" fw="bold">
            PO #: {workflowInfo?.po_number}
          </Text>
        </Stack>
        <Group gap="xs" mt="lg" justify="end">
          <Button variant="light" component={Link} to={generatePath(ROUTES.PORTAL)} color="red">
            Back to Portal
          </Button>
          <Button onClick={handleModalClose}>Yes, Continue</Button>
        </Group>
      </Modal>
      {outlet || children}
    </>
  );
}
