import { useSetPassword, useSignupWithInvitation } from '@/api/auth';
import { PasswordRequirement } from '@/pages/Auth/PasswordRequirement';
import { ROUTES } from '@/Router';
import {
  Box,
  Button,
  Center,
  Image,
  Loader,
  Paper,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text,
  TextInput,
  Title
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { generatePath, Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import css from './Auth.module.css';

interface SignupForm {
  email: string;
  password: string;
  confirmPassword: string;
}

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/, label: 'Includes special symbol' }
];

function getStrength(password: string) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const schema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8),
    confirmPassword: z.string().min(8)
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        path: ['password'],
        code: 'custom',
        message: 'The passwords you have entered do not match'
      });
    }

    // validate password strength
    if (getStrength(password) < 100) {
      ctx.addIssue({
        path: ['password'],
        code: 'custom',
        message: 'Password is too weak'
      });
    }
  });

export default function Auth() {
  const [search] = useSearchParams();
  const authToken = search.get('token');
  const isPasswordResetFlow = search.get('flow') && search.get('flow') === 'reset-password';
  const email = search.get('email');
  const navigate = useNavigate();
  const form = useForm<SignupForm>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validate: zodResolver(schema)
  });

  const [passwordInputVisible, { toggle: togglePasswordVisible }] = useDisclosure(false);
  const [passwordStrengthMeterVisible, setPasswordStrengthMeterVisible] = useState(false);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.password)} />
  ));
  const strength = getStrength(form.values.password);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  const {
    isSuccess: isSignupWithInvitationSuccess,
    isError: isSignupWithInvitationError,
    isPending: isSignupWithInvitationLoading
  } = useSignupWithInvitation(
    {
      email: email!,
      token: authToken!
    },
    {
      enabled: !!authToken && !!email
    }
  );

  const { mutate: updatePassword, isPending: isPasswordUpdating } = useSetPassword({
    onSuccess: () => {
      notifications.show({
        title: isPasswordResetFlow ? 'Success' : 'Welcome to Hike',
        message: isPasswordResetFlow ? 'Password successfully reset' : 'You have successfully registered',
        color: 'teal'
      });
      navigate(ROUTES.HOME);
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Something went wrong. Please try again.',
        color: 'red'
      });
    }
  });

  useEffect(() => {
    if (email) {
      form.setFieldValue('email', email);
    }
  }, [email]);

  const submitCompleteInvitation = () => {
    updatePassword({
      email: form.values.email,
      password: form.values.password
    });
  };

  if (!authToken || !email) {
    return <Navigate to={generatePath(ROUTES.HOME)} replace />;
  }

  return (
    <Box
      className={css.bg}
      style={{
        backgroundColor: 'white'
      }}
      h="100dvh"
    >
      <Center h="100dvh">
        <Stack gap="xl" align="center">
          <Image src="/hike-logo.svg" w={50} />

          <Paper className={css.container} withBorder shadow="lg" radius="md" p={40}>
            {isSignupWithInvitationLoading && (
              <Center>
                <Loader />
              </Center>
            )}

            {isSignupWithInvitationError && (
              <Center>
                <Stack>
                  <Text fw="bold" c="red" size="sm">
                    Invitation link has expired or is invalid
                  </Text>

                  <Button component={Link} to={ROUTES.HOME} variant="light">
                    Go to Login
                  </Button>
                </Stack>
              </Center>
            )}

            {isSignupWithInvitationSuccess && (
              <>
                <Title order={4} component="h1" ta="center" mt="md" mb={50}>
                  {isPasswordResetFlow ? 'Set your new password' : 'You have been invited to Hike'}
                </Title>

                <form onSubmit={form.onSubmit(submitCompleteInvitation)}>
                  <Stack>
                    <TextInput
                      autoComplete="none"
                      name="email"
                      withAsterisk
                      readOnly
                      label="Email address"
                      {...form.getInputProps('email')}
                    />
                    <Popover
                      radius="md"
                      opened={passwordStrengthMeterVisible}
                      position="bottom"
                      width="target"
                      transitionProps={{ transition: 'pop' }}
                    >
                      <Popover.Target>
                        <div
                          onFocusCapture={() => setPasswordStrengthMeterVisible(true)}
                          onBlurCapture={() => setPasswordStrengthMeterVisible(false)}
                        >
                          <PasswordInput
                            name="password"
                            withAsterisk
                            label="Password"
                            autoComplete="new-password"
                            placeholder="Your password"
                            visible={passwordInputVisible}
                            onVisibilityChange={togglePasswordVisible}
                            {...form.getInputProps('password')}
                          />
                        </div>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Progress color={color} value={strength} size={5} mb="xs" />
                        <PasswordRequirement
                          label="Includes at least 8 characters"
                          meets={form.values.password.length >= 8}
                        />
                        {checks}
                      </Popover.Dropdown>
                    </Popover>
                    <PasswordInput
                      name="confirmPassword"
                      withAsterisk
                      autoComplete="new-password"
                      label="Confirm Password"
                      visible={passwordInputVisible}
                      onVisibilityChange={togglePasswordVisible}
                      {...form.getInputProps('confirmPassword')}
                    />
                  </Stack>
                  <Button loading={isPasswordUpdating} type="submit" fullWidth mt="xl" size="md">
                    {isPasswordResetFlow ? 'Reset Password' : 'Accept invitation'}
                  </Button>
                </form>
              </>
            )}
          </Paper>
        </Stack>
      </Center>
    </Box>
  );
}
