import { ROUTES } from '@/Router';
import { ActionIcon, Box, Button, Collapse, Group, List, Paper, rem, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconChevronUp,
  IconCircleCheck,
  IconCircleDotted,
  IconClockPause,
  IconShoe
} from '@tabler/icons-react';
import clsx from 'clsx';
import kebabCase from 'lodash/kebabCase';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import css from './WorkflowCard.module.css';
import { camelCase } from 'lodash';

type WorkflowCardState = 'INCOMPLETE' | 'COMPLETE' | 'SUBMITTED' | 'DEFAULT';

interface WorkflowCardProps {
  workflowTitle: string;
  stateOverride?: WorkflowCardState;
  steps?: { description: string; completed: boolean }[];
  scan_icon?: string;
  onClick?: () => void;
}

export default function WorkflowCard({ workflowTitle, steps, stateOverride, onClick, scan_icon }: WorkflowCardProps) {
  const { visit_id } = useParams();
  const [workflowCardOpen, { toggle: toggleExpand }] = useDisclosure(false);
  const totalSteps = steps?.length || 0;
  const completedSteps = steps?.filter((step) => step.completed).length || 0;
  const navigate = useNavigate();

  const chooseNextStep = (overridePath?: string) => {
    if (overridePath) {
      navigate(overridePath);
      return;
    }

    const firstIncompleteStep = steps?.find((step) => !step.completed);

    if (visit_id) {
      if (firstIncompleteStep) {
        navigate(
          `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
            visit_id
          })}/${kebabCase(workflowTitle)}/${kebabCase(firstIncompleteStep.description)}`
        );
      } else {
        navigate(
          `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
            visit_id
          })}/${kebabCase(workflowTitle)}/${kebabCase(steps?.[0]?.description)}`
        );
      }
    }
  };

  const renderCTAButton = () => {
    switch (true) {
      case stateOverride === 'SUBMITTED':
        return 'View';
      case completedSteps === 0:
        return 'Start';
      case completedSteps < totalSteps:
        return 'Resume';
      case completedSteps === totalSteps:
        return 'Edit';
      default:
        return 'Start';
    }
  };

  return (
    <Paper
      data-automation-id={`workflow-card-${camelCase(workflowTitle)}`}
      radius="lg"
      p="md"
      className={clsx({
        [css.defaultCard as string]: stateOverride === 'DEFAULT' || !totalSteps,
        [css.completeCard as string]: stateOverride === 'COMPLETE' || completedSteps === totalSteps,
        [css.incompleteCard as string]: stateOverride === 'INCOMPLETE' || completedSteps < totalSteps,
        [css.submittedCard as string]: stateOverride === 'SUBMITTED'
      })}
    >
      <Box className={css.wrapper} w="100%" onClick={typeof onClick === 'undefined' ? toggleExpand : onClick}>
        <Group justify="space-between">
          <Group gap="xs">
            <Text size="lg" fw="medium" c={stateOverride === 'SUBMITTED' ? 'black' : 'blue.9'}>
              {workflowTitle}
            </Text>
            {scan_icon &&
              (scan_icon === 'Pending' ? (
                <Tooltip label="Scans are processing">
                  <IconClockPause color="black" style={{ width: rem(24), height: rem(24) }} />
                </Tooltip>
              ) : scan_icon === 'Declined' ? (
                <Tooltip label="Order has failed">
                  <IconAlertCircle color="black" style={{ width: rem(24), height: rem(24) }} />
                </Tooltip>
              ) : (
                <Tooltip label="Scan your Impression Box">
                  <IconShoe color="black" style={{ width: rem(24), height: rem(24) }} />
                </Tooltip>
              ))}
          </Group>
          {typeof onClick === 'undefined' && (
            <ActionIcon
              data-automation-id="workflow-card-toggle-button"
              radius="100%"
              variant="filled"
              aria-label="Collapse Toggle"
              styles={{
                root: {
                  background: '#FFFFFF90'
                }
              }}
            >
              <IconChevronUp
                color="black"
                style={{
                  width: rem(18),
                  height: rem(18),
                  transform: workflowCardOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.2s ease-in-out'
                }}
                stroke={1.5}
              />
            </ActionIcon>
          )}
        </Group>
      </Box>

      {typeof steps !== 'undefined' && steps.length > 0 && (
        <Box mt="md">
          <Group justify="space-between">
            <Button
              data-automation-id="workflow-card-cta-button"
              radius="xl"
              variant="light"
              c="black"
              styles={{
                root: {
                  background: '#FFFFFF90'
                }
              }}
              onClick={() => {
                switch (workflowTitle) {
                  case 'Pain Map':
                    chooseNextStep(generatePath(ROUTES.PAIN_MAP));
                    break;
                  default:
                    chooseNextStep();
                    break;
                }
              }}
            >
              {renderCTAButton()}
            </Button>
            <Text size="sm">
              {stateOverride === 'SUBMITTED'
                ? `${totalSteps}/${totalSteps} Completed`
                : `${completedSteps}/${totalSteps} Completed`}
            </Text>
          </Group>
        </Box>
      )}

      {typeof steps !== 'undefined' && steps.length > 0 && (
        <Collapse in={workflowCardOpen}>
          {steps.map((step, index) => (
            <List
              key={index}
              mt="md"
              spacing="md"
              size="sm"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (visit_id) {
                  navigate(
                    `${generatePath(ROUTES.CLINICAL_WORKFLOW, {
                      visit_id
                    })}/${kebabCase(workflowTitle)}/${kebabCase(step.description)}`,
                    {
                      state: {
                        previousStep: kebabCase(steps[index - 1]?.description)
                      }
                    }
                  );
                }
              }}
              center
              icon={
                stateOverride === 'SUBMITTED' ? (
                  <IconCircleCheck color="teal" style={{ width: rem(18), height: rem(18) }} />
                ) : step.completed ? (
                  <IconCircleCheck color="teal" style={{ width: rem(18), height: rem(18) }} />
                ) : (
                  <IconCircleDotted color="black" style={{ width: rem(18), height: rem(18) }} />
                )
              }
            >
              <List.Item>{step.description}</List.Item>
            </List>
          ))}
        </Collapse>
      )}
    </Paper>
  );
}
