import { ICD10CodeAndDescription } from '@/types/patient-health.form.type';
import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface BillingCodeSearch {
  billing_code: string;
  description: string;
  short_description: string;
}

export const useGetBillingCodes = (
  searchTerm = '',
  options?: Omit<UseQueryOptions<BillingCodeSearch[], ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['billing-codes', searchTerm],
    queryFn: async () => {
      const { data } = await hikeApi.get<BillingCodeSearch[]>('v2/search/billing_codes', {
        params: {
          q: searchTerm
        }
      });
      return data;
    },
    ...options
  });

export interface PhysicianSearch {
  name: string;
  npi: string;
  cpo_branch: string;
  city: string;
  county: string;
  state: string;
  zipcode: string;
  address: string;
  specialization: string;
  secondary_specialization: string;
  primary_taxonomy: string;
}

export const useGetPhysicians = (
  searchTerm = '',
  options?: Omit<UseQueryOptions<PhysicianSearch[], ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['physicians', searchTerm],
    queryFn: async () => {
      const { data } = await hikeApi.get<PhysicianSearch[]>('v2/search/physicians', {
        params: {
          q: searchTerm
        }
      });
      return data;
    },
    ...options
  });

export interface FacilitySearch {
  facility_id: number;
  company_id: number;
  facility_name: string;
  facility_address: string;
  facility_city: string;
  facility_state: string;
  facility_zipcode: string;
}

export const useGetFacilities = (
  searchTerm = '',
  options?: Omit<UseQueryOptions<FacilitySearch[], ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['facilities', searchTerm],
    queryFn: async () => {
      const { data } = await hikeApi.get<FacilitySearch[]>('v2/search/facilities', {
        params: {
          q: searchTerm
        }
      });
      return data;
    },
    ...options
  });

export const useGetDiagnosisCodes = (
  searchTerm = '',
  options?: Omit<UseQueryOptions<ICD10CodeAndDescription[], ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['diagnosis-codes', searchTerm],
    queryFn: async () => {
      const { data } = await hikeApi.get<ICD10CodeAndDescription[]>('v2/search/diagnosis_codes', {
        params: {
          q: searchTerm
        }
      });
      return data;
    },
    ...options
  });
