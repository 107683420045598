import { Box, Button, Group, Modal, Stack, Textarea, rem } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

interface AdditionalNotesProps {
  initialNotes: string;
  onSave: (notes: string, cb: () => void) => Promise<unknown>;
  loading: boolean;
  path?: string;
  disabled?: boolean;
}

export default function AdditionalNotes({ onSave, loading, initialNotes, path, disabled }: AdditionalNotesProps) {
  const params = useParams() as {
    stepName?: string;
  } | null;
  const [notes, setNotes] = useState<string>(initialNotes);
  const [searchParams, setSearchParams] = useSearchParams();
  if (params?.stepName === path) {
    return null;
  }

  const closeNotes = () => {
    const isDifferent = notes !== initialNotes;
    if (isDifferent) {
      if (window.confirm('Are you sure you want to discard your changes?')) {
        searchParams.set('addNotes', 'false');
        setSearchParams(searchParams);

        setNotes(initialNotes);
      }
    } else {
      searchParams.set('addNotes', 'false');
      setSearchParams(searchParams);
    }
  };
  const openNotes = () => {
    searchParams.set('addNotes', 'true');
    setSearchParams(searchParams);
  };

  const onNotesSave = () => {
    searchParams.delete('addNotes');
    onSave(notes, () => setSearchParams(searchParams));
  };

  const isNotesOpen = searchParams.get('addNotes') === 'true';
  return (
    <Box mb="sm">
      <Button
        disabled={disabled}
        onClick={openNotes}
        rightSection={
          <IconPencil
            style={{
              width: rem(20),
              height: rem(20)
            }}
          />
        }
      >
        Notes
      </Button>

      <Modal
        trapFocus={false}
        opened={isNotesOpen}
        onClose={closeNotes}
        centered
        title="Add Additional Evalutation Notes Here"
      >
        <Stack mt="md" gap="0">
          <Textarea
            autosize
            minRows={8}
            size="xl"
            onChange={(e) => setNotes(e.currentTarget.value)}
            value={notes}
            autoFocus
            placeholder="Enter Additional Notes..."
          />
        </Stack>
        <Group gap="xs" mt="lg" justify="end">
          <Button variant="light" color="gray" onClick={closeNotes}>
            Discard
          </Button>
          <Button loading={loading} onClick={onNotesSave} color="hikeGreen">
            Save
          </Button>
        </Group>
      </Modal>
    </Box>
  );
}
