import { ROUTES } from '@/Router';
import { useConfirmLogin } from '@/api/auth';
import AuthContext from '@/context/authContext';
import { Alert, Button, Container, Image, Paper, PasswordInput, Stack, Text, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconSparkles } from '@tabler/icons-react';
import { useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(8)
});

interface FormData {
  email: string;
  password: string;
}

export function HikeLogin() {
  const { login } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const isRedirected = searchParams.get('is_redirected');
  const form = useForm<FormData>({
    initialValues: {
      email: searchParams.get('email') || '',
      password: ''
    },
    validate: zodResolver(schema)
  });

  const { mutate: loginMutation, isPending: loginLoading } = useConfirmLogin({
    onSuccess: (data) => {
      login(data.access_token);
    },
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    }
  });

  const handleLoginSubmission = (values: FormData) => {
    loginMutation({
      tempEmail: values.email,
      password: values.password
    });
  };

  return (
    <Container size={420} mt={90}>
      <Stack align="center">
        <Image src="/hike-logo.svg" w={50} />
        <Title order={1} size="h2" ta="center" fw="600">
          Hike Medical
        </Title>
      </Stack>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {!isRedirected && (
          <Text ta="center" size="sm">
            Login with your credentials below
          </Text>
        )}

        {isRedirected && (
          <Alert icon={<IconSparkles />} title="Welcome to Clinical 2.0">
            You have been redirected to the new clinical portal. Please login with your existing credentials below
          </Alert>
        )}

        <form
          onSubmit={form.onSubmit((values) => {
            handleLoginSubmission({
              email: values.email,
              password: values.password
            });
          })}
        >
          <Stack mt="lg">
            <TextInput
              data-automation-id="email"
              autoComplete="email"
              autoFocus={!searchParams.get('email')}
              type="email"
              label="Email"
              {...form.getInputProps('email')}
              placeholder="Email or phone number"
              required
            />
            <PasswordInput
              data-automation-id="password"
              autoFocus={!!searchParams.get('email')}
              autoComplete="password"
              label="Password"
              placeholder="Password"
              {...form.getInputProps('password')}
              required
            />
          </Stack>
          <Stack>
            <Button data-automation-id="login-button" loading={loginLoading} fullWidth mt="xl" type="submit">
              Login
            </Button>
            <Button
              data-automation-id="forgot-password-button"
              component={Link}
              to={ROUTES.FORGOT_PASSWORD}
              fullWidth
              variant="transparent"
            >
              Forgot Password
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
