import footEvalCss from '@/pages/HikeScan/FootEvalStatus/FootEvalStatus.module.css';
import { FeetDirections, FootEvalContext, PRECHECK_VIEWS } from '@/pages/HikeScan/HikeScan';
import { ActionIcon, Box, Button, Group, Overlay, Text, rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconPlayerRecordFilled, IconPlayerStop, IconRepeat } from '@tabler/icons-react';
import { useContext } from 'react';

const MAX_RECORDING_TIME = 40;
const MIN_VIDEO_LENGTH_IN_SECONDS = 30;
export default function Recording() {
  const {
    results,
    footSelected,
    stopRecording,
    startRecording,
    setResults,
    toggleCamera,
    resetScan,
    setPrecheckView,
    timeRemaining
  } = useContext(FootEvalContext);

  const showRecording = results.leftFootScanRecording || results.rightFootScanRecording;
  const allowStopRecording = results.leftFootScanFullRecord || results.rightFootScanFullRecord;

  if (!showRecording) {
    return null;
  }
  const onStopRecording = async () => {
    if (MAX_RECORDING_TIME - timeRemaining < MIN_VIDEO_LENGTH_IN_SECONDS) {
      const timeLeftToRecord = MIN_VIDEO_LENGTH_IN_SECONDS - (MAX_RECORDING_TIME - timeRemaining);
      notifications.show({
        title: 'Video Too Short',
        message: `You need to record for ${timeLeftToRecord} more seconds!`,
        color: 'red'
      });
      return;
    }
    stopRecording();
  };
  const onBackHandler = () => {
    resetScan();
    setPrecheckView(PRECHECK_VIEWS.FOOT_SELECTOR);
  };
  const handleRecord = () => {
    setResults({
      [`${footSelected}FootScanFullRecord`]: true
    });
    startRecording(footSelected as FeetDirections);
  };
  return (
    <Overlay bg="transparent">
      {!allowStopRecording && (
        <Group pos="absolute" top={10} pt="xl" justify="center" w="100%">
          <Button color="white" variant="light" onClick={onBackHandler}>
            Back to foot selector
          </Button>
        </Group>
      )}
      {allowStopRecording && (
        <Group pos="absolute" top={0} pt="xl" justify="center" w="100%">
          <Box w={10} h={10} className={footEvalCss.recordingLight} bg="red" />
          <Text ta="center">
            Auto Submitting {footSelected === 'left' ? ' Left' : ' Right'} Foot Scan in {timeRemaining} Seconds
          </Text>
        </Group>
      )}
      <Group pos="absolute" bottom={0} pb="xl" justify="center" w="100%">
        {allowStopRecording ? (
          <>
            <ActionIcon
              className={footEvalCss.stopRecordingLight}
              radius="100%"
              size={rem(100)}
              color="red"
              onClick={onStopRecording}
            >
              <IconPlayerStop size={50} />
            </ActionIcon>
          </>
        ) : (
          <ActionIcon
            className={footEvalCss.stopRecordingLight}
            radius="100%"
            size={rem(100)}
            color="red"
            onClick={handleRecord}
          >
            <IconPlayerRecordFilled size={50} />
          </ActionIcon>
        )}

        {!allowStopRecording && (
          <ActionIcon
            className={footEvalCss.stopRecordingLight}
            radius="100%"
            size={rem(100)}
            color="red"
            onClick={toggleCamera}
          >
            <IconRepeat size={50} />
          </ActionIcon>
        )}
      </Group>
    </Overlay>
  );
}
