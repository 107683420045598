import {
  Combobox,
  Group,
  Loader,
  ScrollArea,
  TextInput,
  TextInputProps,
  useCombobox,
  useMantineTheme
} from '@mantine/core';
import { useEffect } from 'react';

interface AutocompleteTextInputProps extends TextInputProps {
  'data-automation-id'?: string;
}

interface AsyncAutocompleteProps<T> {
  label?: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
  inputProps?: AutocompleteTextInputProps;
  data: T[];
  textInputProps?: TextInputProps;
  searchKeys: (keyof T)[];
  optionsMapNode: (item: T) => React.ReactNode;
  leftSection?: React.ReactNode;
  disabled?: boolean;
}

export function AsyncAutocomplete<T>({
  label,
  placeholder,
  value,
  onChange,
  loading = false,
  inputProps,
  data,
  textInputProps,
  searchKeys,
  optionsMapNode,
  leftSection,
  disabled
}: AsyncAutocompleteProps<T>) {
  const theme = useMantineTheme();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const doesItemHaveASearchKeyMatch = (item: T) =>
    searchKeys.some((searchKey) => (item[searchKey] as string).toLowerCase().includes(value.toLowerCase()));

  const getOptionWithMatchingSearchKey = (item: T) => {
    const matchingKey = searchKeys.find((key) => item[key] !== undefined);
    if (!matchingKey) {
      return '';
    }
    return item[matchingKey] as string;
  };

  const shouldFilterOptions = !data.some((item) => {
    if (typeof item === 'string') {
      return item.toLowerCase() === value.toLowerCase();
    }
    return doesItemHaveASearchKeyMatch(item);
  });

  const filteredData = shouldFilterOptions ? data.filter((item) => doesItemHaveASearchKeyMatch(item)) : data;

  const options = filteredData.map((item) => {
    if (typeof item === 'string') {
      return (
        <Combobox.Option value={item} key={item}>
          {item}
        </Combobox.Option>
      );
    }

    return optionsMapNode(item);
  });

  useEffect(() => {
    combobox.selectFirstOption();
  }, [value]);

  return (
    <Combobox
      onOptionSubmit={async (optionValue) => {
        onChange(typeof optionValue === 'string' ? optionValue : getOptionWithMatchingSearchKey(optionValue));
        combobox.closeDropdown();
      }}
      withinPortal={false}
      store={combobox}
      disabled={disabled}
      withArrow
      shadow="md"
      styles={{
        option: {
          borderRadius: theme.radius.md
        }
      }}
    >
      <Combobox.Target>
        <TextInput
          variant="unstyled"
          {...textInputProps}
          label={label}
          disabled={disabled}
          styles={{
            label: {
              fontWeight: 700
            },
            ...textInputProps?.styles
          }}
          placeholder={placeholder}
          {...inputProps}
          value={value}
          onChange={(event) => {
            onChange(
              typeof event.currentTarget.value === 'string'
                ? event.currentTarget.value
                : getOptionWithMatchingSearchKey(event.currentTarget.value)
            );
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => {
            if (!textInputProps?.readOnly) {
              combobox.openDropdown();
            }
          }}
          onFocus={async () => {
            if (!textInputProps?.readOnly) {
              combobox.openDropdown();
              if (!data.length) {
                onChange(value);
              }
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSectionWidth={loading ? undefined : textInputProps?.rightSectionWidth}
          rightSection={<Group justify="end">{loading ? <Loader size={18} /> : textInputProps?.rightSection}</Group>}
          leftSection={leftSection ?? null}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={!data.length}>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="scroll">
            {options.length === 0 ? <Combobox.Empty>Nothing found</Combobox.Empty> : options}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
