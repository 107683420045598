import { ROUTES } from '@/Router';
import { useRemovePassword } from '@/api/auth';
import { Alert, Box, Button, Container, Image, Paper, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email()
});

interface FormData {
  email: string;
}

export default function ForgotPassword() {
  const form = useForm<FormData>({
    initialValues: {
      email: ''
    },
    validate: zodResolver(schema)
  });

  const {
    mutate: removePassword,
    isError: removePasswordError,
    isSuccess: removePasswordSuccess,
    isPending: removePasswordLoading
  } = useRemovePassword();

  return (
    <Box p="lg">
      <Button component={Link} to={ROUTES.HOME} leftSection={<IconArrowLeft />} variant="subtle">
        Back to login
      </Button>
      <Container size={420} mt={90}>
        <Stack align="center">
          <Image src="/hike-logo.svg" w={50} />
        </Stack>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <Stack align="flex-start">
            <Text size="sm">Forgot password? Enter your email to send reset instructions</Text>
          </Stack>

          <form
            onSubmit={form.onSubmit((values) => {
              removePassword({
                email: values.email
              });
            })}
          >
            <Stack mt="lg">
              <TextInput
                autoComplete="email"
                autoFocus
                type="email"
                label="Email"
                {...form.getInputProps('email')}
                placeholder="Email or phone number"
                required
              />
            </Stack>
            <Stack mt="xl">
              {removePasswordError && (
                <Alert variant="light" color="red" title="Error resetting password" icon={<IconExclamationCircle />}>
                  There was an error sending reset instructions, please contact support at{' '}
                  <a href="mailto:support@hikemedical.com">support@hikemedical.com</a>
                </Alert>
              )}
              {removePasswordSuccess && (
                <Alert variant="light" color="green" title="Success" icon={<IconCheck />}>
                  An email with reset instructions has been sent to your email.
                </Alert>
              )}

              <Button
                {...(removePasswordSuccess && {
                  color: 'hikeGreen'
                })}
                loading={removePasswordLoading}
                disabled={removePasswordSuccess}
                fullWidth
                type="submit"
              >
                {removePasswordSuccess ? 'Email sent' : 'Send reset instructions'}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Container>
    </Box>
  );
}
