import { ICD10AutoComplete } from './ICD10Autocomplete';
import { ICD10Card } from './ICD10Card';
import { ICD10ComponentProps } from './icd10.type';

export const ICD10Component = (props: ICD10ComponentProps) => {
  const handleDeleteICD10CodeByIdx = (idx: number) => {
    const newCodes = props.form.values.icd_10_codes_and_descriptions;
    newCodes.splice(idx, 1);
    props.form.setFieldValue('icd_10_codes_and_descriptions', newCodes);
  };

  return (
    <>
      <ICD10AutoComplete props={props} idx={props.form.values.icd_10_codes_and_descriptions.length} />
      {props.form.values.icd_10_codes_and_descriptions?.map((value, idx) => (
        <ICD10Card
          key={`${value.code}-${idx}`}
          disabled={props.disabled}
          value={value}
          idx={idx}
          handleDeleteICD10CodeByIdx={handleDeleteICD10CodeByIdx}
        />
      ))}
    </>
  );
};
