import { ErrorFallback } from '@/components/ErrorBoundary/ErrorFallback';
import MainAppLayout from '@/components/Layouts/MainAppLayout';
import ClinicalWorkflowWrapper from '@/components/WorkflowWrapper/WorkflowWrapper';
import Auth from '@/pages/Auth/Auth';
import ClinicalWorkflow from '@/pages/ClinicalWorkflow/ClinicalWorkflow';
import ClinicianPortal from '@/pages/ClinicianPortal/ClinicianPortal';
import ConfirmOrder from '@/pages/ConfirmOrder/ConfirmOrder';
import Evaluation from '@/pages/Evaluation/Evaluation';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import HikeScan from '@/pages/HikeScan/HikeScan';
import MedicareAI from '@/pages/MedicareAI/MedicareAI';
import OrderForm from '@/pages/OrderForm/OrderForm';
import PainMap from '@/pages/PainMap/PainMap';
import PatientHealth from '@/pages/PatientHealth/PatientHealth';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SearchEvaluations from './pages/ClinicianPortal/SearchEvaluations';
import { HomePage } from './pages/Home.page';
import OrderActions from './pages/OrderActions/OrderActions';
import ReviewCriticalInfo from './pages/ReviewCriticalInfo/ReviewCriticalInfo';

export enum ROUTES {
  HOME = '/',
  AUTH = '/auth',
  FORGOT_PASSWORD = '/forgot-password',
  PORTAL = '/portal/:tabValue?',
  SEARCH = '/search',
  MEDICARE_AI = '/medicareai',
  CLINICAL_WORKFLOW = '/clinical-workflow/:visit_id?',
  // Nested Clinical Workflow Routes (does not start with /)
  PATIENT_HEALTH = 'patient-health/:stepName?',
  EVALUATION = 'evaluation/:stepName?',
  ORDERFORM = 'order-form/:stepName?',
  HIKE_SCAN = 'hike-scan/:footDirection?-foot-scan?',
  PAIN_MAP = 'pain-map/:direction?',
  CONFIRM_ORDER = 'confirm-order',
  REVIEW_CRITICAL_INFO = 'review',
  ORDER_ACTIONS = 'order-actions/:actionType'
}

export const appRoutes = [
  {
    element: <MainAppLayout />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: ROUTES.HOME,
        element: <HomePage />
      },
      {
        path: ROUTES.AUTH,
        element: <Auth />
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPassword />
      },
      {
        path: ROUTES.PORTAL,
        element: <ClinicianPortal />
      },
      {
        path: ROUTES.SEARCH,
        element: <SearchEvaluations />
      },
      {
        path: ROUTES.MEDICARE_AI,
        element: <MedicareAI />
      },
      {
        path: ROUTES.CLINICAL_WORKFLOW,
        element: (
          <ClinicalWorkflowWrapper>
            <ClinicalWorkflow />
          </ClinicalWorkflowWrapper>
        ),
        children: [
          {
            path: ROUTES.PATIENT_HEALTH,
            element: <PatientHealth />
          },
          {
            path: ROUTES.EVALUATION,
            element: <Evaluation />
          },
          {
            path: ROUTES.ORDERFORM,
            element: <OrderForm />
          },
          {
            path: ROUTES.HIKE_SCAN,
            element: <HikeScan />
          },
          {
            path: ROUTES.PAIN_MAP,
            element: <PainMap />,
            exact: true
          },
          {
            path: ROUTES.REVIEW_CRITICAL_INFO,
            element: <ReviewCriticalInfo />,
            exact: true
          },
          {
            path: ROUTES.CONFIRM_ORDER,
            element: <ConfirmOrder />,
            exact: true
          },
          {
            path: ROUTES.ORDER_ACTIONS,
            element: <OrderActions />
          }
        ]
      }
    ]
  }
];

const router = wrapCreateBrowserRouter(createBrowserRouter)(appRoutes);

export const FULL_SCREEN_ROUTES = [
  ROUTES.PORTAL,
  ROUTES.SEARCH,
  ROUTES.HIKE_SCAN,
  ROUTES.AUTH,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.CONFIRM_ORDER,
  ROUTES.REVIEW_CRITICAL_INFO,
  ROUTES.MEDICARE_AI
];

export function Router() {
  return <RouterProvider router={router} future={{ v7_startTransition: true }} />;
}
