import { useGetInsolePricingRules } from '@/api/clincalWorkflow';
import { Alert, Box, Paper, Skeleton, Stack, Table, Text, useMantineTheme } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';
import { groupBy } from 'lodash';
import { useParams } from 'react-router-dom';

export default function OrderItemizedReceipt() {
  const theme = useMantineTheme();
  const { visit_id } = useParams();
  const {
    data: insolePricingRules = [],
    isPending: insolePricingRulesPending,
    isError: insolePricingRulesError
  } = useGetInsolePricingRules(visit_id!, {
    refetchOnMount: true
  });

  const categories = groupBy(insolePricingRules, 'category');

  if (insolePricingRulesPending) {
    return (
      <Stack
        gap={0}
        style={{
          opacity: 0.5,
          borderRadius: theme.radius.lg,
          overflow: 'hidden'
        }}
      >
        <Skeleton height={50} radius={0} />
        <Skeleton height={100} bg="red" radius={0} />
      </Stack>
    );
  }

  if (insolePricingRulesError) {
    return (
      <Alert title="Error" color="red" icon={<IconExclamationCircle />}>
        Error fetching insole pricing rules
      </Alert>
    );
  }

  const orderTotal = insolePricingRules.reduce((acc, option) => acc + option.price, 0);

  return (
    <>
      <Stack gap={0}>
        <div
          style={{
            borderRadius: theme.radius.lg,
            overflow: 'hidden'
          }}
        >
          {Object.keys(categories).map((category, index) => (
            <Paper shadow="lg" radius="0" key={index}>
              <Box py="xs" px="sm" bg={theme.colors.gray[4]}>
                <Text size="sm">{category}</Text>
              </Box>
              <Table striped withTableBorder withColumnBorders bg="white">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th w={150}>Price</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {insolePricingRules
                    ?.filter((option) => option.category === category)
                    .map((option, optionIndex) => (
                      <Table.Tr key={optionIndex}>
                        <Table.Td>{option.label}</Table.Td>
                        <Table.Td>${option.price}</Table.Td>
                      </Table.Tr>
                    ))}
                </Table.Tbody>
              </Table>
            </Paper>
          ))}
        </div>
      </Stack>

      <Paper radius="md" p="sm" withBorder>
        <Text size="md" fw="bold">
          Total: ${orderTotal}
        </Text>
      </Paper>
    </>
  );
}
