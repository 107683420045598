import { useGetWorkflowCard, useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import WorkflowCard from '@/components/WorkflowCard/WorkflowCard';
import { Alert, LoadingOverlay, Stack } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';

export default function ClinicalWorkflowCards() {
  const icon = <IconInfoCircle />;
  const { visit_id } = useParams() as { visit_id: string };

  const workflowTitles = [
    { 'Patient Health': 'patient-health' },
    { Evaluation: 'evaluation' },
    { 'Hike Scan': 'hike-scan' },
    { 'Pain Map': 'pain-map' },
    { 'Order Form': 'order-form' }
  ];
  const { data: workflow_info } = useGetWorkflowDashboard(visit_id, {
    enabled: typeof visit_id !== 'undefined',
    refetchOnMount: true
  });

  const workflowCardResponses = useGetWorkflowCard(workflowTitles.map((obj) => Object.values(obj)).flat(), visit_id, {
    enabled: !!visit_id,
    refetchOnMount: true
  });

  const isLoading = workflowCardResponses.some((query) => query.isLoading);
  const isFetched = workflowCardResponses.every((query) => query.isFetched);
  const isError = workflowCardResponses.some((query) => query.isError);

  if (isError && isFetched) {
    return (
      <Alert variant="light" color="red" title="Failed to fetch Workflow Card Information" icon={icon}>
        An error occurred getting clinical workflow card information.
      </Alert>
    );
  }

  return (
    <Stack gap="sm">
      <LoadingOverlay visible={isLoading} />

      {workflowTitles
        .map((obj) => Object.keys(obj))
        .flat()
        .map((workflow, index) => (
          <WorkflowCard
            key={workflow}
            workflowTitle={workflow}
            steps={workflowCardResponses[index]?.data?.workflowStep}
            scan_icon={workflowCardResponses[index]?.data?.scan_icon}
            {...(workflow_info?.submitted ? { stateOverride: 'SUBMITTED' } : {})}
          />
        ))}
    </Stack>
  );
}
