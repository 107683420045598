export enum OrderStatuses {
  INCOMPLETE = 'Incomplete',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  NEEDS_AUTHORIZATION = 'Needs Authorization',
  AUTHORIZING = 'Authorizing',
  RENDERING = 'Rendering',
  FAILED = 'Failed',
  NEEDS_DEV_VALIDATION = 'Needs Dev Validation',
  NEEDS_VALIDATION = 'Needs Validation',
  VALIDATING = 'Validating',
  NEEDS_PRINTING = 'Needs Printing',
  PRINTING = 'Printing',
  NEEDS_GRINDING = 'Needs Grinding',
  NEEDS_GLUING = 'Needs Gluing',
  NEEDS_ADD_ONS = 'Needs Add Ons',
  NEEDS_FINISHING = 'Needs Finishing',
  AWAITING_FILLER_INSOLE = 'Awaiting Filler Insole',
  NEEDS_SHIPPING = 'Needs Shipping',
  SHIPPED = 'Shipped',
  DELIVERED = 'Completed', // "DELIVERED" is a more fitting enum than "COMPLETED"
  DEMO_SUBMSSION = 'Demo Submission'
}

export const getGenericStatusFromOrderStatus = (order_status: OrderStatuses) => {
  switch (order_status) {
    case OrderStatuses.PENDING:
    case OrderStatuses.NEEDS_AUTHORIZATION:
    case OrderStatuses.AUTHORIZING:
      return OrderStatuses.AUTHORIZING;
    case OrderStatuses.RENDERING:
    case OrderStatuses.FAILED:
    case OrderStatuses.NEEDS_DEV_VALIDATION:
    case OrderStatuses.NEEDS_VALIDATION:
    case OrderStatuses.VALIDATING:
      return 'Processing';
    case OrderStatuses.NEEDS_PRINTING:
    case OrderStatuses.PRINTING:
    case OrderStatuses.NEEDS_GRINDING:
    case OrderStatuses.NEEDS_GLUING:
    case OrderStatuses.NEEDS_ADD_ONS:
    case OrderStatuses.NEEDS_FINISHING:
    case OrderStatuses.AWAITING_FILLER_INSOLE:
      return 'Manufacturing';
    case OrderStatuses.NEEDS_SHIPPING:
      return 'Shipping';
    case OrderStatuses.SHIPPED:
      return OrderStatuses.SHIPPED;
    case OrderStatuses.DELIVERED:
      return 'Delivered';
    default:
      return order_status;
  }
};
