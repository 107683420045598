import { ROUTES } from '@/Router';
import { useClinicalSubmitOrder, useConfirmPONumber, useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import ResetPatientButton from '@/components/ResetPatientButton/ResetPatientButton';
import BillingCodeCard from '@/pages/ClinicalWorkflow/BillingCodeCard/BillingCodeCard';
import ClinicalWorkflowCards from '@/pages/ClinicalWorkflow/ClinicalWorkflowCards';
import { isDevEnv } from '@/utils/constants';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { queryClient } from '@/utils/queryClient';
import { useSavedChangesText } from '@/utils/useSavedChangesText';
import { ActionIcon, Alert, Box, Button, Loader, Modal, NumberInput, Stack, Title, rem } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconCheck, IconInfoCircle } from '@tabler/icons-react';
import { useMutationState } from '@tanstack/react-query';
import clsx from 'clsx';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { Link, generatePath, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { ClinicalPortalTabs } from '../ClinicianPortal/ClinicianPortal';
import { OrderActionType } from '../OrderActions/OrderActions';
import css from './ClinicalWorkflow.module.css';
import ClinicianSignature from './ClinicianSignature/ClinicianSignature';

interface PONumberForm {
  po_number: string;
}

const schema = z.object({
  po_number: z.string().trim().min(1, 'PO Number is required')
});

export default function ClinicalWorkflow() {
  const infoIcon = <IconInfoCircle />;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const review = searchParams.get('review');

  const visit_id = params.visit_id as string;
  const { data: workflowInfo, isLoading, isError } = useGetWorkflowDashboard(visit_id);
  const isConfirmOrderFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.CONFIRM_ORDER_SCREEN);
  const isReviewCriticalInfoFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.REVIEW_CRITICAL_INFO_SCREEN);

  const form = useForm<PONumberForm>({
    initialValues: {
      po_number: workflowInfo?.po_number || ''
    },
    validate: zodResolver(schema),
    validateInputOnChange: true
  });
  const [submitOpen, submitHandlers] = useDisclosure(false);
  const [notesModalOpen, notesModalHandler] = useDisclosure(false);

  const {
    mutate: mutatePoNumber,
    isPending: isPoNumberLoading,
    isError: isPoNumberError,
    isSuccess: isPoNumberSuccess,
    isIdle: isPoNumberIdle
  } = useConfirmPONumber({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['workflow-dashboard', visit_id], data);
    }
  });

  const isSaved = useSavedChangesText({
    isError: isPoNumberError,
    isLoading: isPoNumberLoading,
    isSuccess: isPoNumberSuccess,
    isIdle: isPoNumberIdle
  });

  useEffect(() => {
    if (workflowInfo?.po_number) {
      form.setInitialValues({
        po_number: workflowInfo.po_number
      });
      form.setValues({
        po_number: workflowInfo.po_number
      });
    }
  }, [workflowInfo]);

  const mutateSignaturePDF = useMutationState({
    filters: {
      mutationKey: ['save-signature-and-ambulatory-data'],
      status: 'error'
    }
  });

  const savePONumber = () => {
    mutatePoNumber({ po_number: form.values.po_number, visit_id });
  };

  const { mutate: mutateSubmit, isPending: submitLoading } = useClinicalSubmitOrder({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workflow-dashboard', visit_id] });
      window.scrollTo(0, 0);
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      submitHandlers.open();
    }
  });

  const handleSubmitOrder = () => {
    mutateSubmit({ visit_id });
  };

  if (isError) {
    return (
      <Alert variant="light" color="red" title="Failed to fetch Workflow Information" icon={infoIcon}>
        An error occurred getting workflow information.
      </Alert>
    );
  }

  if (isLoading) {
    return <Loader color="blue" />;
  }

  const hasFailedSignatureOrAmbulatoryData = mutateSignaturePDF.some((state) => state.status === 'error');

  const getNextStepButton = () => {
    if (isConfirmOrderFeatureFlagEnabled && workflowInfo?.submitted) {
      return (
        <Button
          data-automation-id="view-order-confirmation-button"
          component={Link}
          to={ROUTES.CONFIRM_ORDER}
          size="lg"
        >
          View Order Confirmation
        </Button>
      );
    }
    if (workflowInfo?.submitted) {
      return (
        <Button data-automation-id="disabled-order-submitted-button" disabled size="lg">
          Order Has Been Placed
        </Button>
      );
    }
    if (isReviewCriticalInfoFeatureFlagEnabled) {
      return (
        <Button
          data-automation-id="review-order-button"
          component={Link}
          to={ROUTES.REVIEW_CRITICAL_INFO}
          disabled={!workflowInfo?.are_forms_completed}
          size="lg"
        >
          Next Step: Review Order
        </Button>
      );
    }
    if (isConfirmOrderFeatureFlagEnabled) {
      return (
        <Button
          data-automation-id="confirm-order-button"
          component={Link}
          to={ROUTES.CONFIRM_ORDER}
          disabled={!workflowInfo?.are_forms_completed}
          size="lg"
        >
          Next Step: Confirm Order
        </Button>
      );
    }
    return (
      <Button
        data-automation-id="submit-order-button"
        disabled={!workflowInfo?.is_submittable || hasFailedSignatureOrAmbulatoryData}
        size="lg"
        onClick={handleSubmitOrder}
        loading={submitLoading}
      >
        Submit Order
      </Button>
    );
  };

  return (
    <>
      <Stack py="sm" align="flex-start">
        <ActionIcon variant="light" component={Link} to={generatePath(ROUTES.PORTAL)} size="lg">
          <IconArrowLeft />
        </ActionIcon>
        <Title order={2} component="h1" data-automation-id="workflow-title">
          {workflowInfo?.patient_name} {workflowInfo?.patient_id ? `- ${workflowInfo?.patient_id}` : null}
        </Title>
      </Stack>
      {workflowInfo?.submitted && (
        <Button
          variant="light"
          color="blue"
          onClick={() => {
            notesModalHandler.open();
          }}
          size="lg"
          fullWidth
        >
          Order Actions
        </Button>
      )}
      <Modal opened={notesModalOpen} onClose={() => notesModalHandler.close()} centered title="View Order Actions">
        <Stack>
          <Button
            color="clear"
            size="lg"
            variant="light"
            data-automation-id="view-clinical-form-notes"
            component={Link}
            to={`https://forms-clinical-orders.s3.us-east-2.amazonaws.com/${workflowInfo?.clinical_pdf_url}`}
            fullWidth
            target="_blank"
          >
            View Clinical Form PDF
          </Button>
          <Button
            color="clear"
            size="lg"
            variant="light"
            data-automation-id="view-order-form-notess"
            component={Link}
            to={`https://forms-orders.s3.us-east-2.amazonaws.com/${workflowInfo?.order_pdf_url}`}
            fullWidth
            target="_blank"
          >
            View Order Form PDF
          </Button>

          {workflowInfo?.is_completed && (
            <>
              <Button
                data-automation-id="remake-order-button"
                fullWidth
                variant="light"
                size="lg"
                color="clear"
                component={Link}
                to={generatePath(ROUTES.ORDER_ACTIONS, { actionType: OrderActionType.REMAKE })}
              >
                Request Remake
              </Button>
              <Button
                data-automation-id="reorder-order-button"
                fullWidth
                variant="light"
                size="lg"
                color="clear"
                component={Link}
                to={generatePath(ROUTES.ORDER_ACTIONS, { actionType: OrderActionType.REORDER })}
              >
                Reorder Previous Order
              </Button>
            </>
          )}
        </Stack>
      </Modal>

      <Box mt="sm">
        <NumberInput
          data-automation-id="po-number-input"
          className={clsx({
            [css.highlighted as string]: review === 'true'
          })}
          allowDecimal={false}
          valueIsNumericString
          inputMode="text"
          variant="filled"
          leftSection="PO"
          placeholder="Enter PO Number..."
          mb="lg"
          radius="lg"
          {...form.getInputProps('po_number')}
          onChange={(value) => form.setFieldValue('po_number', String(value))}
          error={form.errors.po_number}
          styles={{
            input: {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#f5f5f5',
              padding: rem(24),
              paddingLeft: rem(48)
            }
          }}
          disabled={workflowInfo?.submitted}
          size="md"
          rightSectionWidth={isSaved ? rem(110) : rem(80)}
          rightSection={
            <Button
              data-automation-id="save-po-number-button"
              loading={isPoNumberLoading}
              onClick={savePONumber}
              variant="light"
              size="compact-sm"
              disabled={workflowInfo?.submitted}
              rightSection={
                isSaved ? (
                  <IconCheck
                    style={{
                      width: rem(20),
                      height: rem(20)
                    }}
                  />
                ) : null
              }
              color={isSaved ? 'green' : 'gray'}
            >
              {isSaved ? 'Saved' : 'Save'}
            </Button>
          }
        />
        <Stack gap="sm">
          <ClinicalWorkflowCards />
          <BillingCodeCard />
          {!isConfirmOrderFeatureFlagEnabled && !isReviewCriticalInfoFeatureFlagEnabled && <ClinicianSignature />}
          {getNextStepButton()}
        </Stack>
        {isDevEnv && workflowInfo?.submitted && <ResetPatientButton />}

        {submitOpen && <Confetti />}
        <Modal
          opened={submitOpen}
          onClose={() => submitHandlers.close()}
          centered
          title="Your order has been submitted!"
        >
          <Stack>
            Congratulations on successfully submitting your order for patient: {workflowInfo?.patient_name}!
            <Button
              data-automation-id="return-to-clinician-portal-button"
              component={Link}
              to={generatePath(ROUTES.PORTAL, { tabValue: ClinicalPortalTabs.Completed })}
              size="xs"
              fullWidth
            >
              Return to Clinician Portal
            </Button>
          </Stack>
        </Modal>
      </Box>
    </>
  );
}
