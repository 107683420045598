import { useGetDiagnosisCodes } from '@/api/search';
import { AsyncAutocomplete } from '@/components/Autocomplete/AsyncAutocomplete';
import { ICD10CodeAndDescription } from '@/types/patient-health.form.type';
import { Combobox, TextInput } from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { ICD10ComponentProps } from './icd10.type';

interface ICD10AutoCompleteProps {
  props: ICD10ComponentProps;
  idx: number;
}

export const ICD10AutoComplete: React.FC<ICD10AutoCompleteProps> = ({ props, idx }) => {
  const [query, setQuery] = useInputState<string>('');
  const [deferredQuery] = useDebouncedValue(query, 200);

  const {
    data: icd10Codes,
    isLoading: icd10CodesLoading,
    isError: icd10CodesError
  } = useGetDiagnosisCodes(deferredQuery, {
    enabled: !!deferredQuery
  });

  if (icd10CodesError) {
    return (
      <TextInput
        label={`ICD-10 Code-${idx}`}
        disabled={props.disabled}
        placeholder="Enter the ICD 10 Code"
        {...props.form.getInputProps('icd_10_codes_and_descriptions')}
        variant="default"
        required
      />
    );
  }

  return (
    <AsyncAutocomplete<ICD10CodeAndDescription>
      loading={icd10CodesLoading}
      disabled={props.disabled}
      placeholder="Search for an ICD 10 Code"
      leftSection={<IconSearch size={18} />}
      inputProps={props.form.getInputProps('icd_10_codes_and_descriptions')}
      value={query ?? ''}
      onChange={(value) => {
        setQuery(value);
        const codeMatch = icd10Codes?.find((code) => code.code === value);
        if (codeMatch) {
          const newCodes = props.form.values.icd_10_codes_and_descriptions as Array<ICD10CodeAndDescription>;
          if (idx === newCodes.length) {
            newCodes.push({
              code: codeMatch.code,
              description: codeMatch.description
            });
          } else {
            newCodes[idx] = {
              code: codeMatch.code,
              description: codeMatch.description
            };
          }
          props.form.setFieldValue('icd_10_codes_and_descriptions', newCodes);
          setQuery('');
        }
      }}
      data={icd10Codes || []}
      searchKeys={['code', 'description']}
      textInputProps={{
        variant: 'default',
        required: true
      }}
      optionsMapNode={(code) => (
        <Combobox.Option value={code.code} key={code.code}>
          {code.code} - {code.description}
        </Combobox.Option>
      )}
    />
  );
};
