import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function MortonExtension({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_morton_extension`]: [
          {
            field: `${footDirection}_morton_extension`,
            label: `${startCase(footDirection)} Morton Extension`,
            type: FormTypes.checkbox,
            initialValue: initialValues.morton_extension?.[`${footDirection}_morton_extension`],
            options: [
              {
                label: `${startCase(footDirection)} Morton Extension to the end of toes`,
                value: `${footDirection}-morton-extension-end-of-toes`
              },
              {
                label: `${startCase(footDirection)} Morton Extension NOT to the end of toes`,
                value: `${footDirection}-morton-extension-not-end-of-toes`
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
