import { ROUTES } from '@/Router';
import { captureException } from '@sentry/react';
import axios from 'axios';

export const hikeApi = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

hikeApi.interceptors.request.use((config) => {
  const internalConfig = config;

  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    internalConfig.headers.Authorization = `Bearer ${authToken}`;
  }

  return internalConfig;
});

hikeApi.interceptors.response.use(
  (response) => response,
  (error) => {
    captureException(error);
    if (error.response.status === 401) {
      if (error.response?.data?.versionMismatch === true && error.response?.data?.authToken) {
        localStorage.setItem('authToken', error.response?.data?.authToken);
        localStorage.setItem('updatedVersion', 'true');
        window.location.reload();
      } else {
        window.location.href = ROUTES.HOME;
        localStorage.removeItem('authToken');
        return Promise.reject(error.response.data);
      }
    }

    return Promise.reject(error.response.data);
  }
);
