import { ROUTES } from '@/Router';
import { useClinicalSubmitOrder, useGetWorkflowDashboard, useSaveAdditionalOrderNotes } from '@/api/clincalWorkflow';
import ClinicianSignature from '@/pages/ClinicalWorkflow/ClinicianSignature/ClinicianSignature';
import OrderItemizedReceipt from '@/pages/ConfirmOrder/OrderItemizedReceipt';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { queryClient } from '@/utils/queryClient';
import { useSavedChangesText } from '@/utils/useSavedChangesText';
import { ActionIcon, Button, Center, Group, Loader, Modal, Pill, Stack, Textarea, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft } from '@tabler/icons-react';
import { useMutationState } from '@tanstack/react-query';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { Link, Navigate, generatePath, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { ClinicalPortalTabs } from '../ClinicianPortal/ClinicianPortal';
import css from './ConfirmOrder.module.css';

interface FormData {
  additionalOrderNotes: string;
}

const schema = z.object({
  additionalOrderNotes: z.string()
});

export default function ConfirmOrder() {
  const { visit_id } = useParams();
  const navigate = useNavigate();
  const [submitOpen, submitHandlers] = useDisclosure(false);

  const { mutate: submitOrder, isPending: isSubmitting } = useClinicalSubmitOrder({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      window.scrollTo(0, 0);
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      submitHandlers.open();
    }
  });

  const { data: workflowInfo, isLoading: workflowLoading } = useGetWorkflowDashboard(visit_id!);
  const form = useForm<FormData>({
    initialValues: { additionalOrderNotes: '' },
    validate: zodResolver(schema)
  });

  const isConfirmOrderFeatureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG.CONFIRM_ORDER_SCREEN);

  const {
    mutate: saveAdditionalOrderNotes,
    isPending: isSavingAdditionalOrderNotes,
    isSuccess: isAdditionalOrderNotesSaved,
    isIdle: isAdditionalOrderNotesIdle,
    isError: isAdditionalOrderNotesError
  } = useSaveAdditionalOrderNotes({
    onError: () => {
      form.reset();
      notifications.show({
        title: 'Error',
        message: 'Error saving additional order notes',
        color: 'red'
      });
    }
  });

  const notesSaved = useSavedChangesText({
    isIdle: isAdditionalOrderNotesIdle,
    isSuccess: isAdditionalOrderNotesSaved,
    isError: isAdditionalOrderNotesError,
    isLoading: isSavingAdditionalOrderNotes
  });

  const mutateSignaturePDF = useMutationState({
    filters: {
      mutationKey: ['save-signature-and-ambulatory-data'],
      status: 'error'
    }
  });

  const hasFailedSignatureOrAmbulatoryData = mutateSignaturePDF.some((state) => state.status === 'error');

  useEffect(() => {
    if (workflowInfo?.additional_order_notes) {
      form.setInitialValues({ additionalOrderNotes: workflowInfo?.additional_order_notes });
      form.setFieldValue('additionalOrderNotes', workflowInfo?.additional_order_notes);
    }
  }, [workflowInfo]);

  if (workflowLoading) {
    return (
      <Center p="lg" className={css.background}>
        <Loader />
      </Center>
    );
  }

  const routeBackToWorkflowCondition =
    (typeof isConfirmOrderFeatureFlagEnabled !== 'undefined' && !isConfirmOrderFeatureFlagEnabled) ||
    !workflowInfo?.are_forms_completed;

  if (routeBackToWorkflowCondition) {
    return <Navigate to={generatePath(ROUTES.CLINICAL_WORKFLOW)} replace />;
  }

  const isSubmitButtonDisabled =
    !workflowInfo.is_submittable || hasFailedSignatureOrAmbulatoryData || workflowInfo.submitted;

  return (
    <Stack p="lg" className={css.background}>
      <Group pos="relative">
        <ActionIcon size="lg" onClick={() => navigate(-1)} pos="absolute" left={0}>
          <IconArrowLeft />
        </ActionIcon>
        <Title flex={1} ta="center" order={3} component="h1" c="white">
          Confirm Order
        </Title>
      </Group>

      <OrderItemizedReceipt />

      <Textarea
        label="Additional Order Notes"
        disabled={workflowInfo.submitted}
        autosize
        minRows={2}
        placeholder="Enter any order notes"
        {...form.getInputProps('additionalOrderNotes')}
        onBlur={() => {
          saveAdditionalOrderNotes({
            visit_id: visit_id!,
            additional_order_notes: form.values.additionalOrderNotes
          });
        }}
      />

      {notesSaved && (
        <Pill w="max-content" c="white" bg="green" size="sm">
          Additional notes saved
        </Pill>
      )}

      <ClinicianSignature />

      <Button
        size="xl"
        fz="md"
        fullWidth
        color="hikeGreen.2"
        c="black"
        loading={isSubmitting}
        disabled={isSubmitButtonDisabled}
        onClick={() => {
          submitOrder({ visit_id: visit_id! });
        }}
      >
        {workflowInfo.submitted ? 'Order Has Been Placed' : 'Click to Place Order'}
      </Button>
      {submitOpen && <Confetti />}
      <Modal opened={submitOpen} onClose={() => submitHandlers.close()} centered title="Your order has been submitted!">
        <Stack>
          Congratulations on successfully submitting your order for patient: {workflowInfo?.patient_name}
          <Button
            data-automation-id="return-to-clinician-portal-button"
            component={Link}
            to={generatePath(ROUTES.PORTAL, { tabValue: ClinicalPortalTabs.Completed })}
            size="xs"
            fullWidth
          >
            Return to Clinician Portal
          </Button>
        </Stack>
      </Modal>
    </Stack>
  );
}
