import {
  useMedicareFileInput,
  useMedicareThreadContinueChatInput,
  useMedicareThreadStartInput
} from '@/api/medicare-ai';
import {
  ActionIcon,
  AppShell,
  BackgroundImage,
  Box,
  Button,
  FileButton,
  Group,
  ScrollArea,
  Stack,
  Text,
  Textarea
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChecklist, IconPaperclip } from '@tabler/icons-react';

import { useElementSize, useInputState, useViewportSize } from '@mantine/hooks';

type Message = {
  content: string;
  sender: 'user' | 'medicare';
};

export default function ChatbotUpload() {
  const [inputMessage, setInputMessage] = useInputState<string>('');
  const [fileArr, setFileArr] = useInputState<File[]>([]);
  const [fileIds, setFileIDs] = useInputState<string[]>([]);
  const [threadId, setThreadId] = useInputState<string>('');
  const [messageArr, setMessageArr] = useInputState<Message[]>([]);
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref: footerRef, height: footerHeight } = useElementSize();
  const { height: viewportHeight } = useViewportSize();

  const handleSubmit = () => {
    let newArray = messageArr;
    if (fileArr.length > 0) {
      const fileMessages: Message[] = fileArr.map((file) => ({
        content: `${file.name}`,
        sender: 'user'
      })) as Message[];
      newArray = [...newArray, ...fileMessages];
    }
    if (inputMessage) {
      newArray = [...newArray, { content: `${inputMessage}`, sender: 'user' }];
    }
    setMessageArr(newArray);

    if (!isChatStarteSuccessfully) {
      startChatThread({ file_ids: fileIds, input: inputMessage });
    } else {
      startThreadContinueChat({ thread_id: threadId, file_ids: fileIds, input: inputMessage });
    }

    setFileIDs([]);
    setFileArr([]);
    setInputMessage('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !(isChatStartPending || isChatContinuePending || isMedicareFilePending)
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const { isPending: isMedicareFilePending, mutate: addFileToMedicare } = useMedicareFileInput({
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Error sending file to medicare.ai',
        color: 'red'
      });
    },
    onSuccess: (data) => {
      setFileIDs(data.file_ids);
    }
  });

  const {
    isSuccess: isChatStarteSuccessfully,
    isPending: isChatStartPending,
    mutate: startChatThread
  } = useMedicareThreadStartInput({
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Error starting chat thread',
        color: 'red'
      });
    },
    onSuccess: (data) => {
      setThreadId(data.thread_id);
      let newArray = messageArr;
      if (data.last_message) {
        newArray = [...newArray, { content: `${data.last_message}`, sender: 'medicare' }];
      }
      setMessageArr(newArray);
    }
  });

  const { mutate: startThreadContinueChat, isPending: isChatContinuePending } = useMedicareThreadContinueChatInput({
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Error starting chat thread',
        color: 'red'
      });
    },
    onSuccess: (data) => {
      let newArray = messageArr;

      if (data.response) {
        newArray = [...newArray, { content: `${data.response}`, sender: 'medicare' }];
      }
      setMessageArr(newArray);
    }
  });

  const handleFileChange = (files) => {
    if (files.length < 11) {
      setFileArr(files);
      addFileToMedicare({ fileInput: files });
    } else {
      notifications.show({
        title: 'Error',
        message: 'Medicare AI only reads a maximum of 10 files at one time',
        color: 'red'
      });
    }
  };
  const rightSection = (
    <FileButton onChange={handleFileChange} multiple>
      {(props) => (
        <ActionIcon loading={isMedicareFilePending} variant="transparent" {...props}>
          <IconPaperclip size={28} />{' '}
        </ActionIcon>
      )}
    </FileButton>
  );
  return (
    <AppShell padding="sm">
      <BackgroundImage src="/medicareailightning.svg" radius="xs">
        <AppShell.Header ref={headerRef} withBorder={false} m={12}>
          <Group gap="xs" m={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box style={{ flex: 1 }}>
              <Text c="#1B2559" style={{ fontSize: '24px', textAlign: 'left' }}>
                <span style={{ fontWeight: 'bold' }}>MEDICARE AI</span>
              </Text>
            </Box>
            <Box style={{ flex: 2 }}>
              <Text c="#1B2559" style={{ fontSize: '32px', textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Upload Files to Get Started</span>
              </Text>
            </Box>
            <Box style={{ flex: 1 }}> </Box>
          </Group>
        </AppShell.Header>
        <AppShell.Main pt={headerHeight} pb={footerHeight}>
          <ScrollArea mt={15} h={viewportHeight - headerHeight - footerHeight}>
            <Stack style={{}}>
              {messageArr.map((message, index) => (
                <div
                  key={index}
                  style={{
                    background: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    margin: '1px',
                    padding: '10px'
                  }}
                >
                  <Text>
                    {message.sender === 'user' && (
                      <>
                        <Text component="span" style={{ color: '#3A7CA5', fontWeight: 700 }}>
                          You:
                        </Text>{' '}
                        <span style={{ color: '#4A4A4A' }}>{message.content}</span> {/* User message color */}
                      </>
                    )}
                    {message.sender === 'medicare' && (
                      <>
                        <Text component="span" style={{ color: '#1B2559', fontWeight: 700 }}>
                          Medicare AI:
                        </Text>{' '}
                        <span style={{ color: '#4A4A4A' }}>{message.content}</span> {/* AI message color */}
                      </>
                    )}
                  </Text>
                </div>
              ))}
              {(isChatStartPending || isChatContinuePending) && (
                <div
                  style={{
                    background: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    margin: '1px',
                    padding: '10px'
                  }}
                >
                  <Text>
                    <>
                      <Text component="span" style={{ color: '#1B2559', fontWeight: 700 }}>
                        Medicare AI:
                      </Text>{' '}
                      <span style={{ color: '#4A4A4A' }}>I am thinking...</span>
                    </>
                  </Text>
                </div>
              )}

              {fileArr.map((fileInput, index) => (
                <Textarea
                  key={index}
                  radius={20}
                  style={{ flex: 1, marginRight: '1%' }}
                  value={fileInput?.name}
                  disabled
                  leftSection={<IconChecklist size={28} />}
                />
              ))}
            </Stack>
          </ScrollArea>
        </AppShell.Main>
        <AppShell.Footer ref={footerRef} withBorder={false} m={12}>
          <Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Box style={{ width: '60%', display: 'flex' }}>
              <Textarea
                placeholder="Send a message"
                radius={20}
                style={{ flex: 1, marginRight: '1%', width: '80%' }}
                value={inputMessage}
                onChange={setInputMessage}
                rightSection={rightSection}
                onKeyDown={handleKeyDown}
              />

              <Button
                type="submit"
                style={{ width: '20%' }}
                color="#3A7CA5"
                size="lg"
                loading={isChatStartPending || isChatContinuePending || isMedicareFilePending}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Text size="sm" c="dimmed" mt="md">
            MEDICARE AI MAY MAKE MISTAKES, INACCURACIES, ETC. PLEASE VERIFY INFORMATION USED.
          </Text>
          <Text size="sm" c="dimmed" mt="xs">
            © 2024 HIKE MEDICAL AI Rights Reserved.
          </Text>
        </AppShell.Footer>
      </BackgroundImage>
    </AppShell>
  );
}
