import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useGetBillingCodes } from '@/api/search';
import { ActionIcon, Group, Loader, Paper, Stack, Text } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import css from './BillingCodeCard.module.css';
import BillingCodeField from './BillingCodeField';

export interface BillingCode {
  key: string;
  name: string | undefined;
  quantity: number;
  isEditing: boolean;
}

export interface BillingCodeForm {
  billingCodes: BillingCode[];
}

const schema = z.object({
  billingCodes: z.array(
    z.object({
      key: z.string(),
      name: z.string().min(1, 'Billing Code is required'),
      quantity: z.number().min(1, 'Quantity must be greater than 0'),
      isEditing: z.boolean()
    })
  )
});

export default function BillingCodeCard() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const review = searchParams.get('review');
  const visit_id = params.visit_id as string;

  const { isRefetching: isRefetchingWorkflowData, data: workflowInfo } = useGetWorkflowDashboard(visit_id);

  const { isLoading: billingCodeLoading } = useGetBillingCodes();

  const form = useForm<BillingCodeForm>({
    initialValues: {
      billingCodes: []
    },
    validate: zodResolver(schema)
  });

  const mapAPIBillingCodeToForm = () =>
    workflowInfo?.billing_code?.map((code) => {
      const [codeName, codeQuantity] = code.split('x');
      return {
        key: randomId(),
        name: codeName,
        quantity: Number(codeQuantity),
        isEditing: false
      };
    }) || [];

  const isEditing = form.values.billingCodes.some((code) => code.isEditing);
  useEffect(() => {
    if (!isEditing) {
      form.setInitialValues({
        billingCodes: mapAPIBillingCodeToForm()
      });
      form.setValues({
        billingCodes: mapAPIBillingCodeToForm()
      });
    }
  }, [isRefetchingWorkflowData]);

  const addSingleBillingCode = () => {
    form.setFieldValue('billingCodes', [
      ...form.values.billingCodes,
      {
        key: randomId(),
        name: '',
        quantity: 1,
        isEditing: true
      }
    ]);
  };

  return (
    <Paper
      data-automation-id="billing-code-card"
      radius="lg"
      p="md"
      className={clsx({
        [css.completeCard as string]: form.values.billingCodes.length > 0,
        [css.incompleteCard as string]: form.values.billingCodes.length === 0,
        [css.submittedCard as string]: workflowInfo?.submitted,
        [css.highlighted as string]: review === 'true'
      })}
    >
      <Group justify="space-between">
        <Group>
          <Text size="lg" fw="medium" c={workflowInfo?.submitted ? 'black' : 'blue.9'}>
            Billing Codes
          </Text>
          {billingCodeLoading && <Loader size="xs" />}
        </Group>
        <Group>
          <ActionIcon
            data-automation-id="add-billing-code-button"
            disabled={workflowInfo?.submitted}
            onClick={addSingleBillingCode}
          >
            <IconPlus />
          </ActionIcon>
        </Group>
      </Group>

      <Stack mt="sm" gap="xs">
        {form.values.billingCodes.map((billingCode, index) => (
          <BillingCodeField
            key={billingCode.key}
            billingCode={billingCode}
            index={index}
            disabled={workflowInfo?.submitted}
            form={form}
          />
        ))}

        {form.values.billingCodes.length === 0 && !isEditing && <Text size="sm">Billing codes are required</Text>}

        {Object.keys(form.errors).length > 0 && (
          <Text size="sm" c="red">
            Billing code name and quantity cannot be empty
          </Text>
        )}
      </Stack>
    </Paper>
  );
}
