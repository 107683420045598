import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';

export default function FatPad({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_fat_pad`]: [
          {
            field: `${footDirection}_fat_pad`,
            type: FormTypes.checkbox,
            initialValue: initialValues.fat_pad?.[`${footDirection}_fat_pad`],
            options: [
              {
                label: 'Normal',
                value: 'normal'
              },
              {
                label: 'Adequate',
                value: 'adequate'
              },
              {
                label: 'Inadequate',
                value: 'inadequate'
              },
              {
                label: 'Decreased',
                value: 'decreased'
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
