import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';

export default function CharcoatArthopathy({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_charcoat_arthopathy`]: [
          {
            field: `${footDirection}_charcoat_arthopathy`,
            type: FormTypes.multiCheckbox,
            initialValue: initialValues.charcoat_arthopathy?.[`${footDirection}_charcoat_arthopathy`],
            options: [
              {
                label: 'Past History',
                value: 'past-history'
              },
              {
                label: 'Active',
                value: 'active'
              },
              {
                label: 'Phalanges',
                value: 'phalanges'
              },
              {
                label: 'Forefoot',
                value: 'forefoot'
              },
              {
                label: 'Midfoot',
                value: 'midfoot'
              },
              {
                label: 'Hindfoot',
                value: 'hindfoot'
              }
            ],
            validate: zodHelpers.optionalMultiCheckbox
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
