import { Evaluation } from '@/api/evaluations';
import { Alert, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import FormItem from '../FormItem/FormItem';

export interface TicketListProps {
  evaluations?: Evaluation[];
  isError?: boolean;
  isLoading?: boolean;
  notFoundColor?: string;
  allowEdit: boolean;
  openNewClinicalRedirectModal?: () => void;
}

export default function TicketList({
  evaluations,
  isError,
  isLoading,
  notFoundColor,
  allowEdit,
  openNewClinicalRedirectModal
}: TicketListProps): React.ReactElement | null {
  const icon = <IconInfoCircle />;

  const getEvaluationType = (start_time?: Date, end_time?: Date, is_v1?: boolean, order_submitted?: Date) => {
    if (end_time || (is_v1 && !!order_submitted)) {
      return 'COMPLETED';
    }

    if (start_time) {
      return 'INCOMPLETE';
    }

    return 'NOT STARTED';
  };

  if (isError) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to fetch evaluations" icon={icon} mt="xs">
        An error occurred getting patient evaluations for clinician.
      </Alert>
    );
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (!evaluations || evaluations.length === 0) {
    return (
      <Group style={{ justifyContent: 'center', color: notFoundColor || 'black' }} py="lg">
        <Text size="md" fw={500} opacity={0.5}>
          No results found
        </Text>
      </Group>
    );
  }
  return (
    <Stack gap="xs">
      {evaluations.map((evaluation: Evaluation) => (
        <FormItem
          key={evaluation.patient_id ? evaluation.patient_id : evaluation.name}
          type={getEvaluationType(
            evaluation.start_time,
            evaluation.end_time,
            evaluation.is_v1,
            evaluation.order_submitted
          )}
          openNewClinicalRedirectModal={openNewClinicalRedirectModal}
          allowEdit={allowEdit}
          patientID={evaluation.patient_id}
          patientName={evaluation.name}
          isDiabetic={evaluation.is_diabetic}
          poNumber={evaluation.po_number}
          orderStatus={evaluation.order_status}
          completedDate={evaluation.is_v1 ? evaluation.order_submitted : evaluation.end_time}
          startedDate={evaluation.start_time}
          isV1={evaluation.is_v1}
          visit_id={evaluation.visit_id}
          user_id={evaluation.user_id}
          attach_scans={evaluation.attach_scans}
          clinician_name={evaluation.clinician_name}
        />
      ))}
    </Stack>
  );
}
