import { useCommitReviewForm } from '@/api/forms';
import { ReviewForm } from '@/types/critical-info.form.type';
import { useSavedChangesText } from '@/utils/useSavedChangesText';
import { zodHelpers } from '@/utils/zodHelpers';
import { ActionIcon, Divider, Fieldset, Group, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconDeviceFloppy, IconPencil } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

export default function ReviewFormFields({ date_of_birth, patient_id, user_name }: ReviewForm) {
  const { visit_id } = useParams() as { visit_id: string };
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const theme = useMantineTheme();

  const schema = z.object({
    user_name: zodHelpers.requiredText,
    patient_id: zodHelpers.requiredText,
    date_of_birth: zodHelpers.requiredDate
  });

  const form = useForm<ReviewForm>({
    initialValues: {
      user_name,
      patient_id,
      date_of_birth: date_of_birth ? dayjs(date_of_birth).toDate() : null
    },
    validate: zodResolver(schema),
    validateInputOnChange: true
  });

  const {
    mutateAsync: mutateReviewForm,
    isPending: reviewFormUpdateLoading,
    isError: reviewFormUpdateError,
    isSuccess: reviewFormUpdateSuccess,
    isIdle: isReviewFormUpdateIdle
  } = useCommitReviewForm({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
      setIsEditing(true);
    }
  });

  const isSaved = useSavedChangesText({
    isError: reviewFormUpdateError,
    isLoading: reviewFormUpdateLoading,
    isSuccess: reviewFormUpdateSuccess,
    isIdle: isReviewFormUpdateIdle
  });

  const getEditButtonIcon = () => {
    if (isEditing) {
      return <IconDeviceFloppy />;
    }
    if (isSaved) {
      return <IconCheck />;
    }
    return <IconPencil color={theme.colors.hike?.[9]} />;
  };

  const handleSubmit = async (values: ReviewForm) => {
    if (isEditing) {
      setIsEditing(false);
      await mutateReviewForm({
        visitID: visit_id,
        formChanges: values
      });
    } else {
      setIsEditing(true);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack pl={8} pr={8} pb={32} gap={0}>
        <Group justify="space-between" pb={24}>
          <Text size="lg" fw={700}>
            Patient Data
          </Text>
          <ActionIcon
            type="submit"
            size="lg"
            variant={isSaved ? 'filled' : 'outline'}
            disabled={reviewFormUpdateLoading}
            loading={reviewFormUpdateLoading}
            color={isSaved ? 'green' : theme.colors.hike?.[9]}
            style={{
              borderWidth: 1.5
            }}
          >
            {getEditButtonIcon()}
          </ActionIcon>
        </Group>
        <Fieldset disabled={!isEditing} variant="unstyled">
          <Stack gap={8}>
            <TextInput
              label="Patient Name"
              required
              placeholder="N/A"
              {...form.getInputProps('user_name')}
              styles={{
                label: {
                  fontWeight: 700
                }
              }}
            />
            <TextInput
              label="Patient ID"
              required
              placeholder="N/A"
              {...form.getInputProps('patient_id')}
              styles={{
                label: {
                  fontWeight: 700
                }
              }}
            />
            <DateInput
              label="Date of Birth"
              maxDate={new Date()}
              required
              {...form.getInputProps('date_of_birth')}
              styles={{
                label: {
                  fontWeight: 700
                }
              }}
            />
          </Stack>
        </Fieldset>
      </Stack>
      <Divider />
    </form>
  );
}
