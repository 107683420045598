import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { Button, Image, Stack, Text } from '@mantine/core';
import { useState } from 'react';

const BaseCarouselItem = ({ children }: { children: React.ReactNode }) => (
  <Carousel.Slide>
    <Stack align="center" h="100%" justify="center">
      {children}
    </Stack>
  </Carousel.Slide>
);

export default function IOSPermissionsSetup() {
  const [embla, setEmbla] = useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, 200);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Carousel height="100%" getEmblaApi={setEmbla}>
      <BaseCarouselItem>
        <Image src="/camera-permissions-ios/Settings.PNG" w={300} />
        <Text size="sm" component="span">
          Go to your device{' '}
          <Text fw="bold" component="span">
            Settings
          </Text>
        </Text>
      </BaseCarouselItem>
      <BaseCarouselItem>
        <Image src="/camera-permissions-ios/Safari.PNG" w={300} />
        <Text size="sm" component="span">
          Scroll down and tap `
          <Text fw="bold" component="span">
            Safari
          </Text>
          `
        </Text>
      </BaseCarouselItem>
      <BaseCarouselItem>
        <Image src="/camera-permissions-ios/settings for websites.PNG" w={300} />
        <Text size="sm" component="span">
          Locate `
          <Text fw="bold" component="span">
            Camera
          </Text>
          ` under{' '}
          <Text fw="bold" component="span">
            Settings for Websites
          </Text>
        </Text>
      </BaseCarouselItem>
      <BaseCarouselItem>
        <Image src="/camera-permissions-ios/askcam.PNG" w={300} />
        <Text size="sm" component="span">
          Under `
          <Text fw="bold" component="span">
            Camera Access on All Websites
          </Text>
          `, check that the access is set to `
          <Text fw="bold" component="span">
            Ask
          </Text>
          `
        </Text>
      </BaseCarouselItem>
      <BaseCarouselItem>
        <Text size="sm" component="span">
          Now refresh this page and try again
        </Text>
        <Button onClick={handleRefresh}>Refresh</Button>
      </BaseCarouselItem>
    </Carousel>
  );
}
