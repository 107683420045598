import AuthContext from '@/context/authContext';
import { ClinicalPortalTabs } from '@/pages/ClinicianPortal/ClinicianPortal';
import { HikeLogin } from '@/pages/Login/Login';
import { ROUTES } from '@/Router';
import { useContext } from 'react';
import { generatePath, Navigate } from 'react-router-dom';

export function HomePage() {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return (
      <Navigate
        to={generatePath(ROUTES.PORTAL, {
          tabValue: ClinicalPortalTabs.Incomplete
        })}
        replace
      />
    );
  }

  return <HikeLogin />;
}
