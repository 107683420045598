import { useGetClinicalMaps, useSkipClinicalMap } from '@/api/painMap';
import { PainMapFoot } from '@/pages/PainMap/PainMap';
import { queryClient } from '@/utils/queryClient';
import { Button, Group, Stack, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCircleCheck } from '@tabler/icons-react';
import { camelCase } from 'lodash';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PainMapProps } from './painmap.type';

export const FootIssues = ['Pain', 'Callus', 'Neuroma', 'Ulcer'];

export default function FootIssueSelector({ disabled }: PainMapProps) {
  const theme = useMantineTheme();
  const { direction, visit_id } = useParams<{ direction?: PainMapFoot; visit_id: string }>();
  const [activeFoot] = direction?.split('-') || [];
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: clinicalMaps } = useGetClinicalMaps(visit_id!);

  const onIssueClick = (issue: string) => {
    setSearchParams({ ...searchParams, issue });
  };
  const { mutate: submitClinicalMap, isPending: skipClinicalMapLoading } = useSkipClinicalMap({
    onSuccess: (data) => {
      notifications.show({
        title: 'Success',
        message: `The ${activeFoot} symptom map was successfully skipped.`,
        color: 'green'
      });
      queryClient.setQueryData(['clinical-maps', visit_id!], data);
      navigate(location.pathname.replace(direction!, ''));
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'There was an error saving the clinical map',
        color: 'red'
      });
    }
  });
  return (
    <Stack mt="lg">
      <Title order={4}>Select the {activeFoot} foot issue</Title>
      {FootIssues.map((issue) => {
        const hasIssue = clinicalMaps
          ? !!clinicalMaps[`${activeFoot?.toLowerCase()}_${camelCase(issue).toLowerCase()}_map`]
          : false;

        return (
          <Button onClick={() => onIssueClick(issue)} variant="outline" key={issue}>
            <Group>
              {issue} {hasIssue && <IconCircleCheck size={16} color={theme.colors.green[8]} />}
            </Group>
          </Button>
        );
      })}
      <Button
        variant="outline"
        loading={skipClinicalMapLoading}
        disabled={disabled}
        onClick={() => submitClinicalMap({ foot: activeFoot!, visitId: visit_id! })}
      >
        <Group>
          Skip{' '}
          {clinicalMaps && clinicalMaps[`${activeFoot?.toLowerCase()}_map_skip`] && (
            <IconCircleCheck size={16} color={theme.colors.green[8]} />
          )}
        </Group>
      </Button>
    </Stack>
  );
}
