import { Box, Center, Loader, Transition } from '@mantine/core';

interface HikeLoadingOverlayProps {
  isLoading: boolean;
  children: React.ReactNode;
}

export default function HikeLoadingOverlay({ isLoading, children }: HikeLoadingOverlayProps) {
  return (
    <>
      <Transition mounted={isLoading} transition="fade" timingFunction="ease">
        {(styles) => (
          <Center h="100vh" style={styles} bg="white">
            <Loader />
          </Center>
        )}
      </Transition>

      <Transition mounted={!isLoading} duration={800} transition="fade" timingFunction="ease">
        {(styles) => <Box style={styles}>{children}</Box>}
      </Transition>
    </>
  );
}
