import { ICD10CodeAndDescription } from '@/types/patient-health.form.type';
import { ActionIcon, Group, Stack, Text, rem } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

interface ICD10CardProps {
  value: ICD10CodeAndDescription;
  idx: number;
  handleDeleteICD10CodeByIdx: (idx: number) => void;
  disabled?: boolean;
}

export const ICD10Card = ({ value, idx, handleDeleteICD10CodeByIdx, disabled }: ICD10CardProps) => (
  <Stack
    bg={disabled ? '#f1f3f5' : 'hike.1'}
    p="sm"
    style={{
      borderRadius: rem(8)
    }}
  >
    <Group justify="space-between">
      <Text fw="bold" size="lg">
        {value.code}
      </Text>
      <ActionIcon color="red" disabled={disabled} onClick={() => handleDeleteICD10CodeByIdx(idx)}>
        <IconTrash
          style={{
            width: rem(15),
            height: rem(15)
          }}
        />
      </ActionIcon>
    </Group>
    <Text size="md">Diagnosis: {value.description}</Text>
  </Stack>
);
