import { Uploader } from './uploader.js';

export const startMultipartUpload = async ({ fileName, file, visitId }) => {
  const uploaderOpts = {
    file,
    baseURL: import.meta.env.VITE_API_BASE_URL,
    chunkSize: 7,
    threadsQuantity: 10,
    fileName,
    visitId
  };

  const uploader = new Uploader(uploaderOpts);

  uploader.onError((error) => {
    console.error('Error:', error);
    return Promise.reject(error);
  });

  return uploader.start();
};
