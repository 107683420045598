import { FULL_SCREEN_ROUTES } from '@/Router';
import AuthContext from '@/context/authContext';
import { Button, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();
  const { isLoggedIn, logout } = useContext(AuthContext);

  if (FULL_SCREEN_ROUTES.some((fullScreenRoute) => location.pathname.endsWith(fullScreenRoute))) {
    return null;
  }

  return (
    <Stack my="xl" ta="center">
      <Text size="sm" c="gray">
        Copyright &copy; {new Date().getFullYear()} Hike Medical
      </Text>
      {isLoggedIn && (
        <Text size="sm" c="gray">
          <Button data-automation-id="logout-button" variant="light" color="gray" size="sm" onClick={logout}>
            Logout
          </Button>
        </Text>
      )}
    </Stack>
  );
}
