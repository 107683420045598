import AuthContext from '@/context/authContext';
import { ActionIcon, Menu, rem } from '@mantine/core';
import { IconLogout2, IconMenu2 } from '@tabler/icons-react';
import { useContext } from 'react';

export default function PortalMenu() {
  const { logout } = useContext(AuthContext);

  return (
    <Menu shadow="md" width={200} data-automation-id="portal-menu-button">
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray" size="lg">
          <IconMenu2 size={18} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>My Account</Menu.Label>
        <Menu.Item
          color="red"
          onClick={logout}
          leftSection={<IconLogout2 style={{ width: rem(14), height: rem(14) }} />}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
