import { FULL_SCREEN_ROUTES } from '@/Router';
import Footer from '@/components/Footer/Footer';
import AuthContext from '@/context/authContext';
import { Box } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';
import posthog from 'posthog-js';
import { useContext, useEffect } from 'react';
import { matchPath, useLocation, useOutlet } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

function isFullScreenRoute(pathname: string): boolean {
  return FULL_SCREEN_ROUTES.some(
    (route) =>
      pathname.endsWith(route) ||
      matchPath({ path: route }, pathname) ||
      // TODO: remove this
      pathname.indexOf('hike-scan') !== -1
  );
}

const isUpdatedVersion = localStorage.getItem('updatedVersion');

export default function MainAppLayout() {
  const outlet = useOutlet();
  const location = useLocation();
  const { token: jwtToken } = useContext(AuthContext);

  useEffect(() => {
    if (jwtToken) {
      const jwtDecoded = jwtDecode<{
        sub: string;
        role: string;
      }>(jwtToken);
      posthog.identify(String(jwtDecoded.sub), {
        role: jwtDecoded.role,
        user_id: String(jwtDecoded.sub)
      });
    }
  }, [jwtToken]);

  useEffect(() => {
    if (isUpdatedVersion) {
      localStorage.removeItem('updatedVersion');

      notifications.show({
        id: 'new-version',
        title: 'New version installed',
        message: 'A new version of Hike Clinical has automatically been installed'
      });
    }
  }, []);

  if (isFullScreenRoute(location.pathname)) {
    return outlet;
  }

  return (
    <Box p="md">
      {outlet}
      <Footer />
    </Box>
  );
}
