import { ROUTES } from '@/Router';
import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useCreateOrderAction } from '@/api/orderAction';
import { ClinicalPortalTabs } from '@/pages/ClinicianPortal/ClinicianPortal';
import { queryClient } from '@/utils/queryClient';
import { formatConstant } from '@hike/sdk';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Chip,
  FileInput,
  Loader,
  Modal,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconInfoCircle } from '@tabler/icons-react';
import { zodResolver } from 'mantine-form-zod-resolver';
import Confetti from 'react-confetti';
import { Link, generatePath, useParams } from 'react-router-dom';
import * as z from 'zod';

export enum OrderActionType {
  REMAKE = 'remake',
  REORDER = 'reorder'
}

export default function OrderActions() {
  const infoIcon = <IconInfoCircle />;
  const params = useParams();
  const visitId = params.visit_id as string;
  const actionType = params.actionType as OrderActionType;
  const [leftFile, setLeftFile] = useInputState<File | null>(null);
  const [rightFile, setRightFile] = useInputState<File | null>(null);
  const { data: workflowInfo, isLoading, isError } = useGetWorkflowDashboard(visitId);
  const [actionInfoOpen, actionInfoHandlers] = useDisclosure(true);
  const [attachScansOpen, attachScansHandlers] = useDisclosure(false);

  const orderFormSchema = z.object({
    orderSide: z.enum(['left', 'right', 'both']),
    orderQuantity: z.number().int().positive().max(5).default(1).describe('Order Quantity'),
    reasonsForAction: z.string().min(1, { message: `Please provide a reason for ${formatConstant(actionType)}` }),
    instructionsForAction: z
      .string()
      .min(1, { message: `Please provide instructions for ${formatConstant(actionType)}` }),
    ...(actionType === OrderActionType.REORDER
      ? {
          PONumber: z
            .string()
            .min(1, { message: 'Please provide a valid PO Number' })
            .regex(/^\d+$/, 'PO Number must contain only digits')
        }
      : {})
  });
  const [submitOpen, submitHandlers] = useDisclosure(false);

  const { mutate: mutateSubmit, isPending: submitLoading } = useCreateOrderAction({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workflow-dashboard', visitId] });
      window.scrollTo(0, 0);
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      submitHandlers.open();
    }
  });

  const orderForm = useForm({
    initialValues: {
      orderSide: 'both',
      orderQuantity: 1,
      reasonsForAction: '',
      instructionsForAction: '',
      ...(actionType === OrderActionType.REORDER ? { PONumber: '' } : {})
    },
    validate: zodResolver(orderFormSchema),
    validateInputOnChange: true
  });

  function isValidOrderActionType(actionType: OrderActionType): actionType is OrderActionType {
    return Object.values(OrderActionType).includes(actionType as OrderActionType);
  }

  if (isError || !isValidOrderActionType(actionType)) {
    return (
      <Alert variant="light" color="red" title="Failed to fetch Workflow Information" icon={infoIcon}>
        An error occurred getting workflow information.
      </Alert>
    );
  }

  if (isLoading) {
    return <Loader color="blue" />;
  }

  return (
    <Stack align="flex-start">
      <Modal
        opened={attachScansOpen}
        onClose={() => attachScansHandlers.close()}
        centered
        title="Attach Your Impression Box Scans Here"
      >
        <Stack>
          <FileInput
            accept=".stl, .obj, .ply"
            autoFocus
            label="Attach Left Impression Box Scan"
            clearable
            value={leftFile}
            placeholder="Upload Left File"
            onChange={setLeftFile}
          />
          <FileInput
            accept=".stl, .obj, .ply"
            label="Attach Right Impression Box Scan"
            clearable
            value={rightFile}
            placeholder="Upload Right File"
            onChange={setRightFile}
          />

          <Button fullWidth disabled={!leftFile && !rightFile} onClick={() => attachScansHandlers.close()}>
            Submit Impression Box Scans
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={actionInfoOpen}
        onClose={() => actionInfoHandlers.close()}
        centered
        title={<Title order={3}>Before You Proceed</Title>}
      >
        <Stack>
          <Text>
            {actionType === OrderActionType.REMAKE
              ? 'A member of our team will review your case and determine whether this remake will be billed.'
              : 'Remember: You will be billed again for this reorder.'}
          </Text>
          <Text color="dimmed">Be on the lookout for updates on your Hike Portal!</Text>
          <Button
            data-automation-id="proceed-order-action"
            onClick={() => actionInfoHandlers.close()}
            fullWidth
            variant="light"
            color="clear"
          >
            Proceed
          </Button>
        </Stack>
      </Modal>
      <ActionIcon variant="light" component={Link} to={generatePath(ROUTES.CLINICAL_WORKFLOW)} size="lg">
        <IconArrowLeft />
      </ActionIcon>
      <Title order={2} component="h1" data-automation-id="workflow-title">
        {workflowInfo?.patient_name} {workflowInfo?.patient_id ? `- ${workflowInfo?.patient_id}` : null}
      </Title>
      <Chip size="lg" checked={false} variant="outline">
        PO Number: {workflowInfo?.po_number ? ` ${workflowInfo?.po_number}` : null}
      </Chip>
      <Button
        data-automation-id="attach-scans-button"
        variant="light"
        size="md"
        onClick={() => attachScansHandlers.open()}
      >
        Attach Impression Box Scans (Optional)
      </Button>
      <Box w="100%">
        <form
          onSubmit={orderForm.onSubmit((values) =>
            mutateSubmit({
              leftStl: leftFile,
              rightStl: rightFile,
              poNumber: values.PONumber,
              actionType,
              visitId,
              ...values
            })
          )}
        >
          <Stack gap="lg" style={{ fontSize: '1.5rem' }}>
            <Select
              label="Order Side"
              placeholder="Select Order Side"
              data={[
                { value: 'both', label: 'Both' },
                { value: 'left', label: 'Left' },
                { value: 'right', label: 'Right' }
              ]}
              {...orderForm.getInputProps('orderSide')}
            />
            <NumberInput
              label="Order Quantity"
              placeholder="Enter Order Quantity"
              {...orderForm.getInputProps('orderQuantity')}
            />
            {actionType === OrderActionType.REORDER && (
              <TextInput
                label="New PO Number"
                placeholder="Enter Your New PO Number"
                {...orderForm.getInputProps('PONumber')}
              />
            )}
            <Textarea
              label={`Reasons for ${formatConstant(actionType)}`}
              placeholder={`Enter reasons for ${formatConstant(actionType)}`}
              {...orderForm.getInputProps('reasonsForAction')}
            />
            <Textarea
              label={`Instructions for ${formatConstant(actionType)}`}
              placeholder={`Enter instructions for ${formatConstant(actionType)}`}
              {...orderForm.getInputProps('instructionsForAction')}
            />
            <Button
              loading={submitLoading}
              type="submit"
              fullWidth
              variant="light"
              color="blue"
              style={{ marginTop: 20, fontSize: '1.1rem' }}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
      {submitOpen && <Confetti />}
      <Modal opened={submitOpen} onClose={() => submitHandlers.close()} centered title="Your order has been submitted!">
        <Stack>
          Congratulations! We have successfully received your order request for patient: {workflowInfo?.patient_name}
          <Button
            data-automation-id="return-to-clinician-portal-button"
            component={Link}
            to={generatePath(ROUTES.PORTAL, { tabValue: ClinicalPortalTabs.Completed })}
            size="xs"
            fullWidth
          >
            Return to Clinician Portal
          </Button>
        </Stack>
      </Modal>
    </Stack>
  );
}
