import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';

export default function Proprioception({ form, initialValue, isOther, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_proprioception`]: [
          {
            field: `${footDirection}_proprioception`,
            type: FormTypes.multiCheckbox,
            initialValue: initialValues.proprioception?.[`${footDirection}_proprioception`],
            options: [
              {
                label: 'None',
                value: 'None'
              },
              {
                label: 'Retinopathy',
                value: 'Retinopathy'
              },
              {
                label: 'Dialysis',
                value: 'Dialysis'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            validate: zodHelpers.optionalMultiCheckbox
          },
          {
            field: `${footDirection}_proprioception_other`,
            type: FormTypes.text,
            hidden: !isOther[footDirection],
            label: `Please describe the ${
              footDirection !== 'bilateral' ? `${footDirection} foot` : ''
            } proprioception:`,
            initialValue: initialValues.proprioception?.[`${footDirection}_proprioception_other`],
            validate: zodHelpers.requiredText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
