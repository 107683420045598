import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './global.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5346823038d5fa84106ceb10d62d3f73@o4506468164435968.ingest.sentry.io/4506672146546688',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['https://clinical.hikemedical.com']
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
