import AuthContext from '@/context/authContext';
import { queryClient } from '@/utils/queryClient';
import { HikeConfig } from '@hike/sdk';
import { HikeProvider } from '@hike/sdk/ui';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { ErrorFallback } from './components/ErrorBoundary/ErrorFallback';
import { Router, ROUTES } from './Router';
import { theme } from './theme';

const postHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST
};

const hikeConfig = {
  appEnv: import.meta.env.MODE,
  appId: npm_package_name,
  appVersion: npm_package_version
} satisfies HikeConfig;

export default function App() {
  const [authToken, setAuthToken] = useLocalStorage({
    key: 'authToken',
    defaultValue: '',
    serialize: (value) => {
      if (value) {
        return value;
      }

      return '';
    },
    deserialize: (value) => {
      if (value) {
        return value.replaceAll('"', '');
      }

      return '';
    }
  });

  return (
    <React.StrictMode>
      <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
        <MantineProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
              <HikeProvider config={hikeConfig}>
                <AuthContext.Provider
                  value={{
                    isLoggedIn: !!authToken,
                    login: (token: string) => {
                      setAuthToken(token);
                    },
                    logout: () => {
                      setAuthToken('');
                      window.location.href = ROUTES.HOME;
                    },
                    token: authToken
                  }}
                >
                  <Notifications position="top-right" />
                  <Router />
                </AuthContext.Provider>
              </HikeProvider>
            </Sentry.ErrorBoundary>
          </QueryClientProvider>
        </MantineProvider>
      </PostHogProvider>
    </React.StrictMode>
  );
}
