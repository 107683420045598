import SignatureCanvas from 'react-signature-canvas';
import { Button, Group, Loader, Pill, Stack, Text } from '@mantine/core';
import { useEffect, useRef } from 'react';
import css from './ClinicianSignature.module.css';
import { useGetWorkflowDashboard, useSaveSignatureAndAmbulatoryData } from '@/api/clincalWorkflow';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { queryClient } from '@/utils/queryClient';

export default function ClinicianSignature() {
  const signatureRef = useRef<SignatureCanvas>(null);
  const { visit_id } = useParams() as { visit_id: string };

  const {
    mutate: saveSignatureAndAmbulatoryData,
    isPending: isSavingSignatureAndAmbulatoryData,
    isSuccess: isSignatureAndAmbulatoryDataSaved
  } = useSaveSignatureAndAmbulatoryData({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['workflow-dashboard', visit_id]
      });
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: "Couldn't save signature and ambulatory data",
        color: 'red'
      });
    }
  });

  const { data: workflowInfo } = useGetWorkflowDashboard(visit_id);

  const form = useForm({
    initialValues: {
      signature: '',
      ambulatory: false
    }
  });

  useEffect(() => {
    if (signatureRef.current) {
      if (workflowInfo?.submitted) {
        signatureRef.current.off();
      } else {
        signatureRef.current.on();
      }
    }
  }, [workflowInfo?.submitted]);

  useEffect(() => {
    if (form.isTouched()) {
      if (workflowInfo?.submitted) {
        return;
      }
      saveSignatureAndAmbulatoryData({
        signature_base64: form.values.signature,
        is_ambulatory: form.values.ambulatory,
        visit_id
      });
    }
  }, [form.values]);

  useEffect(() => {
    if (workflowInfo) {
      form.initialize({
        signature: workflowInfo.signature_base64,
        ambulatory: workflowInfo.is_ambulatory
      });

      signatureRef.current?.fromDataURL(workflowInfo.signature_base64);
    }
  }, [workflowInfo, signatureRef]);

  const onSignatureEnd = () => {
    const data = signatureRef.current?.toDataURL();

    if (data) {
      form.setFieldValue('signature', data);
    }
  };

  const onSignatureClear = () => {
    signatureRef.current?.clear();
    form.setFieldValue('signature', '');
  };

  const wasSignatureAndAmbulatoryDataSaved = isSignatureAndAmbulatoryDataSaved && !isSavingSignatureAndAmbulatoryData;

  return (
    <Stack py="md">
      <Text size="md" fw="bold">
        Clinician Signature
      </Text>
      <SignatureCanvas
        ref={signatureRef}
        clearOnResize={false}
        penColor="black"
        backgroundColor="white"
        canvasProps={{ className: css.signatureBox, id: 'signature-canvas' }}
        onEnd={onSignatureEnd}
      />
      <Group justify="space-between" w="100%">
        {isSavingSignatureAndAmbulatoryData && <Loader size="xs" />}
        <Pill
          bg="green"
          c="white"
          size="sm"
          style={{
            visibility: wasSignatureAndAmbulatoryDataSaved ? 'visible' : 'hidden'
          }}
        >
          Signature and ambulatory data saved
        </Pill>
        <Button
          data-automation-id="clear-signature-button"
          size="compact-sm"
          color="dark"
          onClick={onSignatureClear}
          disabled={workflowInfo?.submitted}
        >
          Clear
        </Button>
      </Group>
    </Stack>
  );
}
