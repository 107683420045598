import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { zodHelpers } from '@/utils/zodHelpers';
import { Loader } from '@mantine/core';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

type FootDirection = 'bilateral' | 'left' | 'right';

const dvfOptions = [
  {
    label: 'Swelling',
    value: 'Swelling'
  },
  {
    label: 'Red/Blush Skin',
    value: 'Red/Blush Skin'
  }
];

export default function VascularObservations({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;
  const diabeticDvfOptionsFeatureFlag = useFeatureFlagEnabled(FEATURE_FLAG.DIABETIC_DVF_OPTIONS);
  const { visit_id } = useParams() as { visit_id: string };
  const { data: workflowInfo } = useGetWorkflowDashboard(visit_id);

  const renderDiabeticDvfOptions = () => {
    if (!diabeticDvfOptionsFeatureFlag || !workflowInfo?.is_diabetic) {
      return [];
    }

    return dvfOptions;
  };

  const featureFlaggedDiabeticOptions = renderDiabeticDvfOptions();

  // When the feature flag is off, we want to reset the field so the UI isn't confusing
  const vascularObservationsInitialValue = useCallback(
    (footDirection: FootDirection) => {
      const valueFromDB = initialValues.vascular_observations?.[`${footDirection}_circulation`];

      if (dvfOptions.some((option) => option.label === valueFromDB) && !diabeticDvfOptionsFeatureFlag) {
        return '';
      }

      return valueFromDB;
    },
    [initialValues, diabeticDvfOptionsFeatureFlag]
  );

  // Loading state of feature flag
  if (typeof diabeticDvfOptionsFeatureFlag === 'undefined') {
    return <Loader />;
  }

  const renderForm = (footDirection: FootDirection) => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_vascular_observations`]: [
          {
            field: `${footDirection}_circulation`,
            type: FormTypes.checkbox,
            label: 'Circulation',
            options: [
              {
                label: 'Normal',
                value: 'normal'
              },
              {
                label: 'Reduced',
                value: 'reduced'
              },
              {
                label: 'History of Intermittent Claudication',
                value: 'historyOfIntermittentClaudication'
              },
              ...featureFlaggedDiabeticOptions
            ],
            initialValue: vascularObservationsInitialValue(footDirection),
            validate: zodHelpers.optionalText
          },
          {
            field: `${footDirection}_foot_temperature`,
            type: FormTypes.checkbox,
            label: 'Foot Temperature',
            options: [
              {
                label: 'Normal',
                value: 'normal'
              },
              {
                label: 'Cool to Touch',
                value: 'coolToTouch'
              },
              {
                label: 'Warm to Touch',
                value: 'warmToTouch'
              }
            ],
            validate: zodHelpers.optionalText,
            initialValue: initialValues.vascular_observations?.[`${footDirection}_foot_temperature`]
          },
          {
            field: `${footDirection}_foot_color`,
            type: FormTypes.checkbox,
            label: 'Foot Color',
            options: [
              {
                label: 'Normal',
                value: 'normal'
              },
              {
                label: 'Bluish/Dusky',
                value: 'bluishDusky'
              },
              {
                label: 'Red',
                value: 'red'
              }
            ],
            validate: zodHelpers.optionalText,
            initialValue: initialValues.vascular_observations?.[`${footDirection}_foot_color`]
          },
          {
            field: `${footDirection}_hair_growth`,
            type: FormTypes.checkbox,
            label: 'Hair Growth',
            options: [
              {
                label: 'Normal',
                value: 'normal'
              },
              {
                label: 'Reduced Growth',
                value: 'reducedGrowth'
              },
              {
                label: 'Absent',
                value: 'absent'
              }
            ],
            validate: zodHelpers.optionalText,
            initialValue: initialValues.vascular_observations?.[`${footDirection}_hair_growth`]
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
