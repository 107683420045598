import { useEffect, useState } from 'react';

interface SaveChangesProps {
  isIdle: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
}

export const useSavedChangesText = ({ isIdle, isError, isSuccess, isLoading }: SaveChangesProps) => {
  const [showSavedText, setShowSavedText] = useState(false);

  useEffect(() => {
    if (isIdle || isError || isLoading) {
      setShowSavedText(false);
    }

    if (isSuccess) {
      setShowSavedText(true);
      setTimeout(() => {
        setShowSavedText(false);
      }, 3000);
    }
  }, [isIdle, isError, isSuccess, isLoading]);

  return showSavedText;
};
