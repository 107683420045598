import { ROUTES } from '@/Router';
import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useCommitFormData, useGetInitialFormData } from '@/api/forms';
import { FormTypes, IsOtherFormFootSelect } from '@/components/FormPage/FormBuilder';
import FormStep, { FormStepProps } from '@/components/FormPage/FormStep';
import HikeLoadingOverlay from '@/components/HikeLoadingOverlay/HikeLoadingOverlay';
import AdditionalNotes from '@/components/Notes/AdditionalNotes';
import ArchCondition from '@/pages/Evaluation/Conditions/ArchCondition';
import CharcoatArthopathy from '@/pages/Evaluation/Conditions/CharcoatArthopathy';
import Edema from '@/pages/Evaluation/Conditions/Edema';
import FatPad from '@/pages/Evaluation/Conditions/FatPad';
import Nails from '@/pages/Evaluation/Conditions/Nails';
import Neuropathy from '@/pages/Evaluation/Conditions/Neuropathy';
import Proprioception from '@/pages/Evaluation/Conditions/Proprioception';
import SubtalarJoint from '@/pages/Evaluation/Conditions/SubtalarJoint';
import VascularObservations from '@/pages/Evaluation/Conditions/VascularObservations';
import { UseEvaluationFormResponse } from '@/types/evaluation.form.type';
import { queryClient } from '@/utils/queryClient';
import { zodHelpers } from '@/utils/zodHelpers';
import { Alert } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { snakeCase } from 'lodash';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const formTitle = 'evaluation';

const formFootSelectOptions = ['bilateral', 'left', 'right'];

const patientDiscussionOptions = [
  {
    value: 'treatment_plan',
    label: 'Treatment Plan'
  },
  {
    value: 'expectations',
    label: 'Expectations'
  },
  {
    value: 'goals',
    label: 'Goals'
  },
  {
    value: 'fitting_timeline',
    label: 'Fitting timeline'
  },
  {
    value: 'follow_up_plan',
    label: 'Follow up plan'
  },
  {
    value: 'wear_usage_schedule',
    label: 'Wear/usage schedule'
  },
  {
    value: 'appropriate_shoes_socks',
    label: 'Appropriate Shoes & Socks'
  }
];

const EVALUATION_CONDITIONS_LIST = [
  'vascular_observations',
  'arch_conditions',
  'subtalar_joint',
  'charcoat_arthopathy',
  'fat_pad',
  'edema',
  'nails',
  'neuropathy',
  'proprioception'
];

export default function Evaluation() {
  const [selectedForm, setSelectedForm] = useState<string[]>([
    'patient-evaluation',
    ...EVALUATION_CONDITIONS_LIST,
    'patient-footwear',
    'current-patient-footwear',
    'footwear',
    'shoe-modification',
    'reason-for-custom-orthoses',
    'additional-observations',
    'patient-discussion',
    'patient-plan'
  ]);

  const [areCurrentShoesWornOut, setAreCurrentShoesWornOut] = useState<boolean>(false);
  const [isWornConditionOther, setIsWornConditionOther] = useState<boolean>(false);
  const [isShoeModificationOther, setIsShoeModificationOther] = useState<boolean>(false);
  const [isCustomOrthosesReasonOther, setIsCustomOrthosesReasonOther] = useState<boolean>(false);
  const [isPatientDiscussionOther, setIsPatientDiscussionOther] = useState<boolean>(false);
  const [isPatientPlanOther, setIsPatientPlanOther] = useState<boolean>(false);
  const [isConditionOther, setIsConditionOther] = useSetState<Record<string, IsOtherFormFootSelect>>({
    archCondition: {
      bilateral: false,
      left: false,
      right: false
    },
    edema: {
      bilateral: false,
      left: false,
      right: false
    },
    nails: {
      bilateral: false,
      left: false,
      right: false
    },
    neuropathy: {
      bilateral: false,
      left: false,
      right: false
    },
    proprioception: {
      bilateral: false,
      left: false,
      right: false
    }
  });

  const { visit_id } = useParams() as { visit_id: string };
  const navigate = useNavigate();

  const filterOtherValuesForComponents = (isOther: IsOtherFormFootSelect, field: string) => {
    const splits = field.split('_');
    if (splits.length === 0) {
      return false;
    }
    const foot = splits[0];

    if (!foot) {
      return false;
    }

    if (!formFootSelectOptions.includes(foot)) {
      return false;
    }

    if (isOther[foot as keyof IsOtherFormFootSelect]) {
      return true;
    }

    const isFieldOther = splits[splits.length - 1] === 'other';
    return !isFieldOther;
  };

  const filterConditionValuesForPatch = (values: Record<string, string | string[]>, isOther?: IsOtherFormFootSelect) =>
    Object.keys(values).reduce((acc: Record<string, string | string[]>, field: string) => {
      const value: string | string[] = values[field] as string | string[];
      if (isOther && filterOtherValuesForComponents(isOther, field)) {
        acc[field] = value;
      }
      return acc;
    }, {});

  const {
    data: queriedFormData,
    isLoading: initialFormDataLoading,
    isError: initialFormDataError,
    refetch: refetchInitialFormData
  } = useGetInitialFormData<UseEvaluationFormResponse>(formTitle, visit_id);

  const { mutateAsync: mutateFormPage, isPending: submitFormPageLoading } = useCommitFormData({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: ({ data }, { pageTitle }) =>
      queryClient.setQueryData(['initial-form-data', formTitle, visit_id], (prev: UseEvaluationFormResponse) => {
        const prevPageFields = prev[pageTitle as keyof UseEvaluationFormResponse];

        return {
          ...prev,
          [pageTitle]: {
            ...prevPageFields,
            ...data
          }
        };
      })
  });

  const { data: workflow_info } = useGetWorkflowDashboard(visit_id, {
    enabled: typeof visit_id !== 'undefined',
    refetchOnMount: true
  });

  const allForms: FormStepProps['fields'] = {
    'patient-evaluation': [
      {
        field: 'patient_evaluation',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Vascular Observations',
            value: 'vascular_observations'
          },
          {
            label: 'Arch Conditions',
            value: 'arch_conditions'
          },
          {
            label: 'Subtalar Joint',
            value: 'subtalar_joint'
          },
          {
            label: 'Charcoat Arthopathy',
            value: 'charcoat_arthopathy'
          },
          {
            label: 'Fat Pad',
            value: 'fat_pad'
          },
          {
            label: 'Edema',
            value: 'edema'
          },
          {
            label: 'Nails',
            value: 'nails'
          },
          {
            label: 'Neuropathy',
            value: 'neuropathy'
          },
          {
            label: 'Proprioception',
            value: 'proprioception'
          }
        ],
        initialValue: queriedFormData?.patient_evaluation.patient_evaluation,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    vascular_observations: {
      initialValue: queriedFormData?.vascular_observations,
      component: VascularObservations
    },
    arch_conditions: {
      initialValue: queriedFormData?.arch_conditions,
      isOther: isConditionOther.archCondition,
      component: ArchCondition
    },
    subtalar_joint: {
      initialValue: queriedFormData?.subtalar_joint,
      component: SubtalarJoint
    },
    charcoat_arthopathy: {
      initialValue: queriedFormData?.charcoat_arthopathy,
      component: CharcoatArthopathy
    },
    fat_pad: {
      initialValue: queriedFormData?.fat_pad,
      component: FatPad
    },
    edema: {
      initialValue: queriedFormData?.edema,
      isOther: isConditionOther.edema,
      component: Edema
    },
    nails: {
      initialValue: queriedFormData?.nails,
      isOther: isConditionOther.nails,
      component: Nails
    },
    neuropathy: {
      initialValue: queriedFormData?.neuropathy,
      isOther: isConditionOther.neuropathy,
      component: Neuropathy
    },
    proprioception: {
      initialValue: queriedFormData?.proprioception,
      isOther: isConditionOther.proprioception,
      component: Proprioception
    },
    'patient-footwear': [
      {
        field: 'requires_footwear',
        description: 'Does the patient require footwear?',
        label: 'Does the patient require footwear?',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'No',
            value: 'no'
          },
          {
            label: 'Yes',
            value: 'yes'
          }
        ],
        initialValue:
          queriedFormData?.patient_footwear.requires_footwear === true
            ? 'Yes'
            : queriedFormData?.patient_footwear.requires_footwear === false
              ? 'No'
              : null,
        validate: zodHelpers.requiredCheckbox
      }
    ],
    'current-patient-footwear': [
      {
        field: 'current_footwear_manufacturer',
        type: FormTypes.text,
        label: 'Manufacturer',
        initialValue: queriedFormData?.patient_footwear.current_footwear_manufacturer,
        validate: zodHelpers.optionalText
      },
      {
        field: 'current_footwear_model',
        type: FormTypes.text,
        label: 'Model/Style',
        initialValue: queriedFormData?.patient_footwear.current_footwear_model,
        validate: zodHelpers.optionalText
      }
    ],
    footwear: [
      {
        // Per: https://linear.app/hike-medical/issue/ENG-612/implement-changes-for-patient-plan-patient-discussion-shoe-mods-and, leaving field still have 'custom' in it
        field: 'reasons_for_custom_footwear',
        label: 'Reasons for footwear',
        type: FormTypes.multiCheckbox,
        options: [
          {
            label: 'Foot will not fit in off-the-shelf orthopedic shoes and requires custom shoes',
            value: 'customShoes'
          },
          {
            label: 'Extra depth design necessary for recommended shoes due to foot deformities/anomalies',
            value: 'extraDepthDeformities'
          },
          {
            label: 'Extra depth shoes required to allow for use of accommodative/corrective foot orthoses',
            value: 'extraDepthOrthoses'
          },
          {
            label: 'Foot does not fit in commercial shoes but requires properly fit off-the-shelf orthopedic shoes',
            value: 'properlyFitOrthopedicShoes'
          },
          {
            label: 'Current shoes are worn and need to be replaced',
            value: 'wornAndNeedReplacement'
          }
        ],
        initialValue:
          queriedFormData?.patient_footwear.custom_footwear &&
          typeof queriedFormData.patient_footwear.custom_footwear === 'object'
            ? queriedFormData.patient_footwear.custom_footwear.reasons_for_custom_footwear
            : [],
        validate: zodHelpers.optionalMultiCheckbox
      },
      {
        field: 'worn_conditions',
        type: FormTypes.multiCheckbox,
        hidden: !areCurrentShoesWornOut,
        label: 'Which part of the shoes are worn out?',
        options: [
          {
            label: 'General Wear',
            value: 'General Wear'
          },
          {
            label: 'Worn on Heel',
            value: 'Worn on Heel'
          },
          {
            label: 'Worn on Toe',
            value: 'Worn on Toe'
          },
          {
            label: 'Worn Velcro Straps',
            value: 'Worn Velcro Straps'
          },
          {
            label: 'Worn Toe Box',
            value: 'Worn Toe Box'
          },
          {
            label: 'Other',
            value: 'Other'
          }
        ],
        validate: zodHelpers.requiredMultiCheckbox,
        initialValue:
          queriedFormData?.patient_footwear.custom_footwear &&
          typeof queriedFormData.patient_footwear.custom_footwear === 'object'
            ? queriedFormData.patient_footwear.custom_footwear.worn_conditions
            : []
      },
      {
        field: 'worn_condition_other',
        type: FormTypes.text,
        hidden: !isWornConditionOther,
        label: 'Please describe which part of the shoes are worn out',
        validate: zodHelpers.requiredText,
        initialValue:
          queriedFormData?.patient_footwear.custom_footwear &&
          typeof queriedFormData.patient_footwear.custom_footwear === 'object'
            ? queriedFormData.patient_footwear.custom_footwear.worn_condition_other
            : ''
      }
    ],
    'shoe-modification': [
      {
        field: 'shoe_modification',
        label: 'Shoe Modifications',
        type: FormTypes.multiCheckbox,
        options: [
          {
            label: 'Ankle/Foot deformity',
            value: 'ankleFootDeformity'
          },
          {
            label: 'Leg length discrepancy',
            value: 'legLengthDiscrepancy'
          },
          {
            label: 'Reduce/Redistribute plantar surface pressures',
            value: 'reducePressure'
          },
          {
            label: 'Full-length steel shank for weight transfer',
            value: 'steelShank'
          },
          {
            label: 'Other',
            value: 'Other'
          }
        ],
        initialValue:
          queriedFormData?.patient_footwear.shoe_modification &&
          typeof queriedFormData.patient_footwear.shoe_modification === 'object'
            ? queriedFormData.patient_footwear.shoe_modification.shoe_modification
            : [],
        validate: zodHelpers.optionalMultiCheckbox
      },
      {
        field: 'shoe_modification_other',
        type: FormTypes.text,
        hidden: !isShoeModificationOther,
        label: 'Please describe the shoe modification',
        initialValue:
          queriedFormData?.patient_footwear.shoe_modification &&
          typeof queriedFormData.patient_footwear.shoe_modification === 'object'
            ? queriedFormData.patient_footwear.shoe_modification.shoe_modification_other
            : '',
        validate: zodHelpers.requiredText
      }
    ],
    'reason-for-custom-orthoses': [
      {
        field: 'custom_orthoses_reasons',
        type: FormTypes.multiCheckbox,
        options: [
          {
            label: 'Foot shape/alignment will not accommodate prefabricated design',
            value: 'footShapeAlignmentWillNotAccommodatePreFab'
          },
          {
            label: 'Degree of correction needed for biomechanical alignment not possible with prefabricated design',
            value: 'degreeOfCorrection'
          },
          {
            label: 'Reduce/Redistribute plantar surface pressures',
            value: 'reducePressure'
          },
          {
            label: 'Full-length steel shank for weight transfer',
            value: 'steelShank'
          },
          {
            label: 'Other',
            value: 'Other'
          }
        ],
        initialValue:
          queriedFormData?.patient_footwear.custom_orthoses_reasons &&
          typeof queriedFormData.patient_footwear.custom_orthoses_reasons === 'object'
            ? queriedFormData.patient_footwear.custom_orthoses_reasons.custom_orthoses_reasons
            : [],
        validate: zodHelpers.optionalMultiCheckbox
      },
      {
        field: 'custom_orthoses_reasons_other',
        type: FormTypes.text,
        hidden: !isCustomOrthosesReasonOther,
        label: 'Please describe the reason for custom orthoses',
        initialValue:
          queriedFormData?.patient_footwear.custom_orthoses_reasons &&
          typeof queriedFormData.patient_footwear.custom_orthoses_reasons === 'object'
            ? queriedFormData.patient_footwear.custom_orthoses_reasons.custom_orthoses_reasons_other
            : '',
        validate: zodHelpers.requiredText
      }
    ],
    'additional-observations': [
      {
        field: 'additional_observations',
        type: FormTypes.textArea,
        initialValue: queriedFormData?.additional_observations?.additional_observations,
        validate: zodHelpers.optionalText
      }
    ],
    'patient-discussion': [
      {
        field: 'patient_discussion',
        type: FormTypes.multiCheckbox,
        label: 'Patient Discussion',
        description: 'Mark boxes once discussed with patient.',
        options: [
          ...patientDiscussionOptions,
          {
            value: 'other',
            label: 'Other'
          }
        ],
        initialValue:
          queriedFormData?.patient_discussion.patient_discussion &&
          typeof queriedFormData.patient_discussion.patient_discussion === 'object'
            ? queriedFormData.patient_discussion.patient_discussion.patient_discussion
            : [],
        validate: zodHelpers.optionalMultiCheckbox
      },
      {
        field: 'patient_discussion_other',
        label: 'What else was discussed?',
        type: FormTypes.text,
        hidden: !isPatientDiscussionOther,
        initialValue:
          queriedFormData?.patient_discussion.patient_discussion &&
          typeof queriedFormData.patient_discussion.patient_discussion === 'object'
            ? queriedFormData.patient_discussion.patient_discussion.patient_discussion_other
            : '',
        validate: zodHelpers.requiredText
      }
    ],
    'patient-plan': [
      {
        field: 'patient_plan',
        type: FormTypes.checkbox,
        label: 'Plan to see the patient back for a fitting appointment',
        options: [
          {
            label: '1 week',
            value: '1_week'
          },
          {
            label: '2 weeks',
            value: '2_weeks'
          },
          {
            label: '3 weeks',
            value: '3_weeks'
          },
          {
            label: '4 weeks',
            value: '4_weeks'
          },
          {
            label: 'Schedule visit once insoles are ready to be fitted',
            value: 'schedule_visit_post_printing'
          },
          {
            label: 'Other',
            value: 'other'
          }
        ],
        initialValue:
          !queriedFormData?.patient_plan.patient_plan ||
          ['1 week', '2 weeks', '3 weeks', '4 weeks', 'Schedule visit once insoles are ready to be fitted'].includes(
            queriedFormData.patient_plan.patient_plan
          )
            ? queriedFormData?.patient_plan.patient_plan
            : 'Other',
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'patient_plan_other',
        label: 'Timeline',
        type: FormTypes.text,
        hidden: !isPatientPlanOther,
        initialValue: ![
          '1 week',
          '2 weeks',
          '3 weeks',
          '4 weeks',
          'Schedule visit once insoles are ready to be fitted'
        ].includes(queriedFormData?.patient_plan.patient_plan)
          ? queriedFormData?.patient_plan.patient_plan
          : '',
        validate: zodHelpers.requiredText
      }
    ]
  };

  const filteredFormsBasedOnSelection = Object.entries(allForms).reduce(
    (acc, [key, value]) => {
      if (selectedForm.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );

  if (initialFormDataError) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to fetch evaluation form data" mt="xs">
        An error occurred getting initial evaluation form data for visit.
      </Alert>
    );
  }

  return (
    <HikeLoadingOverlay isLoading={initialFormDataLoading}>
      <FormStep
        showTitlesAtEachStep
        notes={
          <AdditionalNotes
            path="additional-observations"
            disabled={workflow_info?.submitted}
            loading={submitFormPageLoading}
            initialNotes={queriedFormData?.additional_observations?.additional_observations}
            onSave={async (notes, callback) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'additional_observations',
                formChanges: {
                  additional_observations: notes
                }
              });

              await refetchInitialFormData();

              callback();
            }}
          />
        }
        onPageSubmit={[
          {
            'patient-evaluation': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_evaluation',
                formChanges: {
                  patient_evaluation: values.patient_evaluation
                }
              }),
            vascular_observations: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'vascular_observations',
                formChanges: {
                  vascular_observations: values
                }
              }),
            subtalar_joint: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'subtalar_joint',
                formChanges: {
                  subtalar_joint: values
                }
              }),
            arch_conditions: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'arch_conditions',
                formChanges: {
                  arch_conditions: filterConditionValuesForPatch(values, isConditionOther.archCondition)
                }
              }),
            charcoat_arthopathy: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'charcoat_arthopathy',
                formChanges: {
                  charcoat_arthopathy: values
                }
              }),
            fat_pad: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'fat_pad',
                formChanges: {
                  fat_pad: values
                }
              }),
            edema: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'edema',
                formChanges: {
                  edema: filterConditionValuesForPatch(values, isConditionOther.edema)
                }
              }),
            nails: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'nails',
                formChanges: {
                  nails: filterConditionValuesForPatch(values, isConditionOther.nails)
                }
              }),
            neuropathy: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'neuropathy',
                formChanges: {
                  neuropathy: filterConditionValuesForPatch(values, isConditionOther.neuropathy)
                }
              }),
            proprioception: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'proprioception',
                formChanges: {
                  proprioception: filterConditionValuesForPatch(values, isConditionOther.proprioception)
                }
              }),
            'patient-footwear': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_footwear',
                formChanges: {
                  requires_footwear:
                    values.requires_footwear === 'Yes' ? true : values.requires_footwear === 'No' ? false : null
                }
              }),
            'current-patient-footwear': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_footwear',
                formChanges: {
                  current_footwear_manufacturer: values.current_footwear_manufacturer,
                  current_footwear_model: values.current_footwear_model
                }
              }),
            footwear: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_footwear',
                formChanges: {
                  custom_footwear: {
                    reasons_for_custom_footwear: values.reasons_for_custom_footwear,
                    worn_conditions: areCurrentShoesWornOut ? values.worn_conditions : [],
                    worn_condition_other: isWornConditionOther ? values.worn_condition_other : ''
                  }
                }
              }),
            'shoe-modification': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_footwear',
                formChanges: {
                  shoe_modification: {
                    shoe_modification: values.shoe_modification,
                    shoe_modification_other: isShoeModificationOther ? values.shoe_modification_other : ''
                  }
                }
              }),
            'reason-for-custom-orthoses': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_footwear',
                formChanges: {
                  custom_orthoses_reasons: {
                    custom_orthoses_reasons: values.custom_orthoses_reasons,
                    custom_orthoses_reasons_other: isCustomOrthosesReasonOther
                      ? values.custom_orthoses_reasons_other
                      : ''
                  }
                }
              }),
            'patient-discussion': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_discussion',
                formChanges: {
                  patient_discussion: {
                    patient_discussion: values.patient_discussion,
                    patient_discussion_other: isPatientDiscussionOther ? values.patient_discussion_other : ''
                  }
                }
              }),
            'patient-plan': async (values) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_plan',
                formChanges: {
                  patient_plan: isPatientPlanOther ? values.patient_plan_other : values.patient_plan
                }
              });

              navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
            },
            'additional-observations': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'additional_observations',
                formChanges: {
                  additional_observations: values.additional_observations
                }
              })
          }
        ]}
        buttonProps={{
          loading: submitFormPageLoading,
          disabled: !selectedForm.length
        }}
        onFormInputChange={[
          {
            'patient-evaluation': (values: { patient_evaluation: string[] }) => {
              setSelectedForm((prev: string[]) => {
                const finalForms = [
                  ...prev.filter((item) => !EVALUATION_CONDITIONS_LIST.includes(snakeCase(item))),
                  ...values.patient_evaluation.map((patient_eval) => snakeCase(patient_eval))
                ];
                return finalForms;
              });
            },
            'patient-footwear': (values) => {
              if (values.requires_footwear === 'Yes') {
                setSelectedForm((prev) => [...prev, 'footwear', 'shoe-modification']);
              } else {
                setSelectedForm((prev) => prev.filter((form) => form !== 'footwear' && form !== 'shoe-modification'));
              }
            },
            footwear: (values) => {
              setAreCurrentShoesWornOut(
                values.reasons_for_custom_footwear.includes('Current shoes are worn and need to be replaced')
              );
              setIsWornConditionOther(values.worn_conditions.includes('Other'));
            },
            arch_conditions: (values) => {
              setIsConditionOther({
                archCondition: {
                  bilateral: values.bilateral_arch_conditions?.includes('Other') || false,
                  left: values.left_arch_conditions?.includes('Other') || false,
                  right: values.right_arch_conditions?.includes('Other') || false
                }
              });
            },
            edema: (values) => {
              setIsConditionOther({
                edema: {
                  bilateral: values.bilateral_edema?.includes('Other') || false,
                  left: values.left_edema?.includes('Other') || false,
                  right: values.right_edema?.includes('Other') || false
                }
              });
            },
            nails: (values) => {
              setIsConditionOther({
                nails: {
                  bilateral: values.bilateral_nails?.includes('Other') || false,
                  left: values.left_nails?.includes('Other') || false,
                  right: values.right_nails?.includes('Other') || false
                }
              });
            },
            neuropathy: (values) => {
              setIsConditionOther({
                neuropathy: {
                  bilateral: values.bilateral_neuropathy?.includes('Other') || false,
                  left: values.left_neuropathy?.includes('Other') || false,
                  right: values.right_neuropathy?.includes('Other') || false
                }
              });
            },
            proprioception: (values) => {
              setIsConditionOther({
                proprioception: {
                  bilateral: values.bilateral_proprioception?.includes('Other') || false,
                  left: values.left_proprioception?.includes('Other') || false,
                  right: values.right_proprioception?.includes('Other') || false
                }
              });
            },
            'shoe-modification': (values) => {
              setIsShoeModificationOther(values.shoe_modification.includes('Other'));
            },
            'reason-for-custom-orthoses': (values) => {
              setIsCustomOrthosesReasonOther(values.custom_orthoses_reasons.includes('Other'));
            },
            'patient-discussion': (values) => {
              setIsPatientDiscussionOther(values.patient_discussion.includes('Other'));
            },
            'patient-plan': (values) => {
              setIsPatientPlanOther(values.patient_plan === 'Other');
            }
          }
        ]}
        title="Evaluation"
        disabled={workflow_info?.submitted}
        fields={{
          ...filteredFormsBasedOnSelection
        }}
      />
    </HikeLoadingOverlay>
  );
}
