import { FacilitySearch, useGetFacilities } from '@/api/search';
import { AsyncAutocomplete } from '@/components/Autocomplete/AsyncAutocomplete';
import { FormBuilderFnProps } from '@/components/FormPage/FormBuilder';
import { Combobox, TextInput } from '@mantine/core';

export const ClinicAutocomplete = (props: FormBuilderFnProps) => {
  const activeSearchTerm = props.form.values.clinic_name as string;

  const { data: clinics, isLoading: clinicsLoading, isError: clinicsError } = useGetFacilities(activeSearchTerm);

  if (clinicsError) {
    return (
      <TextInput
        label="Clinic Name"
        placeholder="Enter the clinic name"
        {...props.form.getInputProps('clinic_name')}
        variant="default"
        required
      />
    );
  }

  return (
    <AsyncAutocomplete<FacilitySearch>
      loading={clinicsLoading}
      label="Clinic Name"
      placeholder="Enter the clinic name"
      value={props.form.values.clinic_name as string}
      disabled={props.disabled}
      inputProps={props.form.getInputProps('clinic_name')}
      onChange={(value) => {
        props.form.setFieldValue('clinic_name', value);
      }}
      data={clinics || []}
      searchKeys={['facility_name']}
      textInputProps={{
        variant: 'default',
        required: true
      }}
      optionsMapNode={(facility) => (
        <Combobox.Option value={facility.facility_name} key={facility.facility_id}>
          {facility.facility_name}
        </Combobox.Option>
      )}
    />
  );
};
