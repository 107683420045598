import { useActionSingleBillingCode, useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { BillingCodeSearch as BillingCodeSearchResult, useGetBillingCodes } from '@/api/search';
import { AsyncAutocomplete } from '@/components/Autocomplete/AsyncAutocomplete';
import { BillingCode, BillingCodeForm } from '@/pages/ClinicalWorkflow/BillingCodeCard/BillingCodeCard';
import { ActionIcon, Button, Combobox, Group, NumberInput, Stack, Text, rem, useMantineTheme } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconTrash, IconX } from '@tabler/icons-react';
import { keepPreviousData } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

interface BillingCodeProps {
  index: number;
  billingCode: BillingCode;
  form: UseFormReturnType<BillingCodeForm>;
  disabled?: boolean;
}

export default function BillingCodeField({ billingCode, form, index, disabled }: BillingCodeProps) {
  const theme = useMantineTheme();
  const params = useParams();
  const visit_id = params.visit_id as string;
  const [debouncedSaveFieldValue] = useDebouncedValue(form.values.billingCodes[index]?.name, 100);
  const { data: billingCodesAutofillData, isLoading: billingCodesAutofillLoading } = useGetBillingCodes(
    debouncedSaveFieldValue,
    {
      enabled: form.values.billingCodes[index]?.isEditing && !!debouncedSaveFieldValue,
      placeholderData: keepPreviousData
    }
  );

  const { data: workflowInfo, refetch: refetchBillingCodes } = useGetWorkflowDashboard(visit_id);

  const { mutate: mutateSingleBillingCode, isPending: singleBillingCodeLoading } = useActionSingleBillingCode({
    onSuccess: () => {
      form.setFieldValue(`billingCodes.${index}.isEditing`, false);
      refetchBillingCodes();
    },
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    }
  });

  const saveFieldValue = () => {
    const isEmpty = !form.values.billingCodes[index]?.name || !form.values.billingCodes[index]?.quantity;

    if (isEmpty) {
      form.setFieldError(`billingCodes.${index}.name`, 'Please enter a billing code');
      return;
    }

    mutateSingleBillingCode({
      index,
      action: 'save',
      visit_id,
      code: `${form.values.billingCodes[index]?.name}x${form.values.billingCodes[index]?.quantity}`
    });
  };

  const deleteBillingCode = () => {
    const billingCodeExists = workflowInfo?.billing_code?.includes(
      `${form.values.billingCodes[index]?.name}x${form.values.billingCodes[index]?.quantity}`
    );

    if (billingCodeExists) {
      mutateSingleBillingCode({
        index,
        action: 'delete',
        code: `${form.values.billingCodes[index]?.name}x${form.values.billingCodes[index]?.quantity}`,
        visit_id
      });
    } else {
      form.setFieldValue('billingCodes', [
        ...form.values.billingCodes.slice(0, index),
        ...form.values.billingCodes.slice(index + 1)
      ]);
    }

    form.clearFieldError(`billingCodes.${index}.name`);
  };

  return (
    <Group
      pl={billingCode.isEditing ? '3' : 'sm'}
      bg={billingCode.isEditing ? theme.colors.blue[0] : 'gray.1'}
      justify="space-between"
      style={{
        borderRadius: theme.radius.md
      }}
    >
      {billingCode.isEditing ? (
        <Stack p="sm" flex={1} justify="space-between">
          <AsyncAutocomplete<BillingCodeSearchResult>
            searchKeys={['billing_code']}
            disabled={disabled}
            key={billingCode.key}
            placeholder="Enter a billing code"
            loading={billingCodesAutofillLoading}
            value={billingCode.name || ''}
            onChange={(code) => {
              form.setFieldValue(`billingCodes.${index}.name`, code);
            }}
            inputProps={{
              'data-automation-id': 'billing-code-autocomplete'
            }}
            data={billingCodesAutofillData || []}
            textInputProps={{
              readOnly: !billingCode.isEditing,
              size: 'md',
              variant: 'default'
            }}
            optionsMapNode={(item) => (
              <Combobox.Option key={item.billing_code} value={item.billing_code} mb="1">
                <Text size="sm" fw="bold">
                  {item.billing_code}
                </Text>
                <Text size="xs" c="default" lineClamp={2}>
                  {item.description}
                </Text>
              </Combobox.Option>
            )}
          />
          <NumberInput
            data-automation-id="billing-code-input"
            inputMode="numeric"
            hideControls
            leftSection="Qty"
            min={1}
            max={999}
            styles={{
              section: {
                fontSize: rem(14)
              }
            }}
            size="md"
            radius="md"
            readOnly={!billingCode.isEditing}
            {...form.getInputProps(`billingCodes.${index}.quantity`)}
            value={billingCode.quantity}
            disabled={disabled}
          />

          <Group flex={1} p="sm">
            <ActionIcon
              flex={1}
              color="red"
              disabled={disabled}
              onClick={deleteBillingCode}
              data-automation-id="delete-billing-code-button"
            >
              <IconTrash
                style={{
                  width: rem(15),
                  height: rem(15)
                }}
              />
            </ActionIcon>
            <Button
              data-automation-id="save-billing-code-button"
              flex={1}
              disabled={disabled}
              loading={singleBillingCodeLoading}
              color="hikeGreen"
              size="compact-sm"
              onClick={saveFieldValue}
            >
              Save
            </Button>
          </Group>
        </Stack>
      ) : (
        <Group flex={1} p="xs" justify="space-between">
          <Group>
            <Text size="md">{billingCode.name}</Text>
            <Text size="md">
              <IconX
                style={{
                  width: rem(12),
                  height: rem(12)
                }}
              />
            </Text>
            <Text size="md">{billingCode.quantity}</Text>
          </Group>
          <Button
            data-automation-id="edit-billing-code-button"
            variant="light"
            size="compact-sm"
            disabled={disabled}
            onClick={() => {
              form.setFieldValue(`billingCodes.${index}.isEditing`, true);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </Group>
  );
}
