import { Tabs, useMantineTheme } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

interface FormBuilderFnProps {
  errors: Record<string, unknown>;
  leftNode: React.ReactNode;
  rightNode: React.ReactNode;
  bilateralNode: React.ReactNode;
}

const defaultFoot = 'bilateral';

export default function FormFootSelect({ errors, leftNode, rightNode, bilateralNode }: FormBuilderFnProps) {
  const theme = useMantineTheme();

  const conditionalErrorIcon = (footDirection: 'bilateral' | 'left' | 'right') => {
    if (Object.keys(errors).some((key) => key.startsWith(footDirection))) {
      return <IconExclamationCircle color="red" />;
    }
    return null;
  };

  return (
    <Tabs defaultValue={defaultFoot} variant="default" color={theme.colors.hike?.[8]}>
      <Tabs.List display="flex">
        <Tabs.Tab flex={1} value="bilateral" rightSection={conditionalErrorIcon('bilateral')}>
          Bilateral
        </Tabs.Tab>
        <Tabs.Tab flex={1} value="left" rightSection={conditionalErrorIcon('left')}>
          Left
        </Tabs.Tab>
        <Tabs.Tab flex={1} value="right" rightSection={conditionalErrorIcon('right')}>
          Right
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="bilateral" pt="sm">
        {bilateralNode}
      </Tabs.Panel>
      <Tabs.Panel value="left" pt="sm">
        {leftNode}
      </Tabs.Panel>
      <Tabs.Panel value="right" pt="sm">
        {rightNode}
      </Tabs.Panel>
    </Tabs>
  );
}
