import { ROUTES } from '@/Router';
import { Button, Container, Group, Text, Title } from '@mantine/core';
import { PathPattern, matchPath, useLocation } from 'react-router';
import { Link, generatePath } from 'react-router-dom';
import css from './ErrorFallback.module.css';

export function ErrorFallback() {
  const errorString = 'Something went wrong and our engineers are on it';
  const location = useLocation();

  const chooseNavigation = () => {
    const pattern: PathPattern = {
      path: ROUTES.CLINICAL_WORKFLOW,
      end: true
    };

    const match = matchPath(pattern, location.pathname);

    if (match) {
      return (
        <Button component={Link} to={generatePath(ROUTES.PORTAL)} size="md">
          Back to Portal
        </Button>
      );
    }

    return (
      <Button component={Link} to={generatePath(ROUTES.CLINICAL_WORKFLOW)} size="md">
        Back to Clinical Workflow
      </Button>
    );
  };

  return (
    <Container className={css.root}>
      <div className={css.inner}>
        <div className={css.content}>
          <Title className={css.title}>Uh oh</Title>
          <Text c="dimmed" size="lg" ta="center" className={css.description}>
            {errorString}
          </Text>
          <Group justify="center">{chooseNavigation()}</Group>
        </div>
      </div>
    </Container>
  );
}
