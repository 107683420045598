import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function MetHead({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_met_head`]: [
          {
            field: `${footDirection}_met_head`,
            label: `${startCase(footDirection)} Off-Load Met Heads`,
            type: FormTypes.multiCheckbox,
            initialValue: initialValues.off_load_met_head?.[`${footDirection}_met_head`],
            options: [
              {
                label: '1st Toe Met Head',
                value: '1'
              },
              {
                label: '2nd Toe Met Head',
                value: '2'
              },
              {
                label: '3rd Toe Met Head',
                value: '3'
              },
              {
                label: '4th Toe Met Head',
                value: '4'
              },
              {
                label: '5th Toe Met Head',
                value: '5'
              }
            ],
            validate: zodHelpers.optionalMultiCheckbox
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
