import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { useMutation, UseMutationOptions, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface UseWorkflowStepResponse {
  description: string;
  completed: boolean;
}

export interface UseWorkflowResponse {
  workflowStep: UseWorkflowStepResponse[];
  scan_icon: string;
}

interface PONumberMutationParams {
  po_number: string;
  visit_id: string;
}

interface BillingCodesMutationParams {
  billing_codes: string[];
  visit_id: string;
}

interface BillingCodeSingleIndex {
  index: number;
  action: 'save' | 'delete';
  code: string;
  visit_id: string;
}

interface UseSubmitOrderParam {
  visit_id: string;
}

export interface UseWorkflowDashboard {
  patient_name: string;
  billing_code?: string[];
  po_number: string;
  submitted: boolean;
  are_forms_completed: boolean;
  is_submittable: boolean;
  signature_base64: string;
  is_diabetic: boolean;
  is_ambulatory: boolean;
  patient_id?: string;
  additional_order_notes: string;
  clinical_pdf_url: string;
  order_pdf_url: string;
  is_completed: boolean;
}

export const useGetWorkflowCardResponse = (
  workflowTitle: string,
  visit_id: string,
  options?: UseQueryOptions<UseWorkflowResponse[], ApiError>
) =>
  useQuery({
    queryKey: ['workflow-info', visit_id, workflowTitle],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseWorkflowResponse[]>(`v2/clinical/workflow/${visit_id}/${workflowTitle}`);
      return data;
    },
    ...options
  });

export const useGetWorkflowDashboard = (
  visit_id: string,
  options?: Omit<UseQueryOptions<UseWorkflowDashboard, ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['workflow-dashboard', visit_id],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseWorkflowDashboard>(`/v2/clinical/workflow/info/${visit_id}`);
      return data;
    },
    refetchInterval: 15000,
    ...options
  });

export const useGetWorkflowCard = (
  workflowTitles: string[],
  visit_id: string,
  options?: Omit<UseQueryOptions<UseWorkflowResponse, ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQueries({
    queries: workflowTitles.map((workflowTitle) => ({
      queryKey: ['workflow-info', visit_id, workflowTitle],
      queryFn: async () => {
        const { data } = await hikeApi.get<UseWorkflowResponse>(
          `v2/clinical/workflow/card/${visit_id}/${workflowTitle}`
        );
        return data;
      },
      refetchInterval: 15000,
      ...options
    }))
  });

export const useConfirmPONumber = (
  mutationOptions?: UseMutationOptions<UseWorkflowDashboard, ApiError, PONumberMutationParams>
) =>
  useMutation({
    mutationFn: async ({ po_number, visit_id }: PONumberMutationParams) => {
      const { data } = await hikeApi.post<UseWorkflowDashboard>(`v2/clinical/workflow/info/${visit_id}`, {
        po_number
      });
      return data;
    },

    ...mutationOptions
  });

export const useConfirmBillingCodes = (
  mutationOptions?: UseMutationOptions<UseWorkflowDashboard, ApiError, BillingCodesMutationParams>
) =>
  useMutation({
    mutationFn: async ({ billing_codes, visit_id }: BillingCodesMutationParams) => {
      const { data } = await hikeApi.post<UseWorkflowDashboard>(`v2/clinical/workflow/info/${visit_id}`, {
        billing_codes
      });
      return data;
    },

    ...mutationOptions
  });

export const useActionSingleBillingCode = (
  mutationOptions?: UseMutationOptions<void, ApiError, BillingCodeSingleIndex>
) =>
  useMutation({
    mutationFn: async ({ index, action, code, visit_id }: BillingCodeSingleIndex) => {
      const { data } = await hikeApi.post<void>('v2/clinical/workflow/billing_code', {
        index,
        action,
        code,
        visit_id
      });
      return data;
    },

    ...mutationOptions
  });

export const useClinicalSubmitOrder = (mutationOptions?: UseMutationOptions<void, ApiError, UseSubmitOrderParam>) =>
  useMutation({
    mutationFn: async ({ visit_id }: UseSubmitOrderParam) => {
      const { data } = await hikeApi.post<void>('v2/clinical/workflow/submit_order', {
        visit_id
      });
      return data;
    },
    ...mutationOptions
  });

interface UseSignatureAndAmbulatoryData {
  visit_id: string;
  signature_base64?: string; // Optional as API requires either signature_base64 or is_ambulatory
  is_ambulatory?: boolean; // Optional as API requires either signature_base64 or is_ambulatory
}

interface UseSignatureAndAmbulatoryDataResponse {
  message: string;
}

export const useSaveSignatureAndAmbulatoryData = (
  mutationOptions?: UseMutationOptions<UseSignatureAndAmbulatoryDataResponse, ApiError, UseSignatureAndAmbulatoryData>
) =>
  useMutation({
    mutationKey: ['save-signature-and-ambulatory-data'],
    mutationFn: async (data: UseSignatureAndAmbulatoryData) => {
      const { data: response } = await hikeApi.post<UseSignatureAndAmbulatoryDataResponse>(
        `v2/submit_ambulatory_and_clinician_signature/${data.visit_id}`,
        {
          signature_base64: data.signature_base64,
          is_ambulatory: data.is_ambulatory
        }
      );
      return response;
    },
    ...mutationOptions
  });

export const useResetPatientInDevMode = (mutationOptions?: UseMutationOptions<void, ApiError, UseSubmitOrderParam>) =>
  useMutation({
    mutationFn: async ({ visit_id }: UseSubmitOrderParam) => {
      const { data } = await hikeApi.post<void>('v2/mark_incomplete_patient', {
        visit_id
      });
      return data;
    },
    ...mutationOptions
  });

interface InsolePricingRule {
  attribute_map_field: string;
  category: string;
  company_id: string;
  currency: string;
  facility_id: string;
  id: string;
  is_active: boolean;
  label: string;
  matching_value: string;
  price: number;
  updated_at: Date;
}

export const useGetInsolePricingRules = (
  visit_id: string | number,
  options?: Omit<UseQueryOptions<InsolePricingRule[], ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery({
    queryKey: ['insole-pricing-rules', visit_id],
    queryFn: async () => {
      const { data } = await hikeApi.get<InsolePricingRule[]>(
        `v2/clinical/workflow/get-order-pricing-rules/${visit_id}`
      );
      return data;
    },
    ...options
  });

interface AdditionalOrderNotesMutationParams {
  visit_id: string;
  additional_order_notes: string;
}

interface AdditionalOrderNotesResponse {
  message: string;
}

export const useSaveAdditionalOrderNotes = (
  mutationOptions?: UseMutationOptions<AdditionalOrderNotesResponse, ApiError, AdditionalOrderNotesMutationParams>
) =>
  useMutation({
    mutationFn: async ({ visit_id, additional_order_notes }: AdditionalOrderNotesMutationParams) => {
      const { data } = await hikeApi.post<AdditionalOrderNotesResponse>(
        `v2/clinical/workflow/additional_order_notes/${visit_id}`,
        {
          additional_order_notes
        }
      );
      return data;
    },
    ...mutationOptions
  });
