import { useMultiPartVideoSend, useUploadPreviewToS3MultiPart } from '@/api/footScan';
import { FeetDirections, FootEvalContext, PRECHECK_VIEWS } from '@/pages/HikeScan/HikeScan';
import PreCheckQuestion from '@/pages/HikeScan/PreCheck/PreCheckQuestion';
import { Button, Center, Loader, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { IconCircleCheck, IconExclamationCircle } from '@tabler/icons-react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

export default function PreCheck() {
  const { precheckView, onPrecheckAnswer, footSelected, setResults, shouldRescanPatient } = useContext(FootEvalContext);
  const { visit_id } = useParams();
  const theme = useMantineTheme();
  const { results, resetScan, resetPrecheck, setPrecheckView, stopPreviewing } = useContext(FootEvalContext);

  const {
    mutate: uploadVideo,
    isPending: isUploadingVideo,
    isError: isUploadVideoError,
    reset: resetUploadVideo
  } = useMultiPartVideoSend({
    onSuccess: () => {
      setResults({
        [`${footSelected}FootScanUploading`]: false,
        [`${footSelected}FootScanSuccess`]: true,
        [`${footSelected}FootScanBlob`]: ''
      });
    },
    onError: (error) => {
      console.error(error);
      setResults({
        [`${footSelected}FootScanUploading`]: false,
        [`${footSelected}FootScanSuccess`]: false,
        [`${footSelected}FootScanError`]: error.error || 'Something went wrong'
      });
    }
  });

  const previewName = footSelected && results[`${footSelected}FootScanPreviewName`];

  const {
    mutate: uploadPreview,
    isPending: isUploadingPreview,
    isError: isUploadPreviewError,
    error: uploadPreviewError,
    isSuccess: isUploadPreviewSuccess,
    reset: resetPreviewUploadPreview
  } = useUploadPreviewToS3MultiPart({
    onSuccess: () => {
      if (footSelected && previewName) {
        uploadVideo({
          visitId: visit_id as string,
          preview: previewName,
          foot: footSelected as FeetDirections,
          rescan: footSelected === 'left' ? results.leftRescanValue : results.rightRescanValue
        });
      }
    },
    onError: (error) => {
      console.error(error);
      setResults({
        [`${footSelected}FootScanUploading`]: false,
        [`${footSelected}FootScanSuccess`]: false,
        [`${footSelected}FootScanError`]: error.error || 'Something went wrong'
      });
    }
  });

  const onVideoUpload = async () => {
    if (footSelected && previewName) {
      uploadPreview({
        previewName,
        videoBlob: (footSelected === 'left' ? results.leftFootScanBlob : results.rightFootScanBlob) as Blob,
        visitId: visit_id as string
      });
    }
  };

  const isError = results.leftFoodScanError || results.rightFoodScanError;
  const isSuccess = results.leftFootScanSuccess || results.rightFootScanSuccess;
  const isUploading = results.leftFootScanUploading || results.rightFootScanUploading;

  const handleRetry = () => {
    // if API failed to upload, let the user re-try the upload process
    setResults({
      leftFoodScanError: '',
      rightFoodScanError: '',
      leftFootScanUploading: footSelected === 'left',
      rightFootScanUploading: footSelected === 'right'
    });
    resetUploadVideo();
    resetPreviewUploadPreview();
    onVideoUpload();
  };

  const handleContinueScanClick = () => {
    resetScan();
    stopPreviewing();
    resetPrecheck();
    setPrecheckView(PRECHECK_VIEWS.FOOT_SELECTOR);
  };

  if (precheckView === PRECHECK_VIEWS.FOOT_SELECTOR) {
    return null;
  }

  if (precheckView === PRECHECK_VIEWS.REPORT && !shouldRescanPatient) {
    if (isUploadPreviewError || isUploadVideoError || isError) {
      return (
        <Stack align="center">
          <IconExclamationCircle size={96} color={theme.colors.red[6]} />
          <Title order={3}>Scan failed to upload</Title>
          <Text>
            {uploadPreviewError?.error ||
              results.leftFoodScanError ||
              results.rightFoodScanError ||
              'Something went wrong'}
          </Text>
          <Button onClick={handleRetry}>Try again</Button>
        </Stack>
      );
    }

    if (isUploadingPreview || isUploadingVideo || isUploading) {
      return (
        <Stack align="center">
          <Title order={3}>
            Uploading
            {footSelected === 'left' ? ' Left' : ' Right'} Foot Scan
          </Title>
          <Text w="80%" ta="center">
            Please dont leave the page, it might take upto one minute to upload
          </Text>
          <Center>
            <Loader my="xl" size="xl" color="white" />
          </Center>
        </Stack>
      );
    }

    if (isUploadPreviewSuccess || isSuccess) {
      return (
        <Stack align="center">
          <IconCircleCheck size={96} color={theme.colors.hikeGreen?.[3]} />
          <Title order={3}>Scan uploaded successfully</Title>
          <Button onClick={handleContinueScanClick}>Back to foot selector</Button>
        </Stack>
      );
    }
  }
  return (
    <>
      {precheckView === PRECHECK_VIEWS.LIGHTING && (
        <PreCheckQuestion
          title="Is the lighting good?"
          onNoClick={() => onPrecheckAnswer(PRECHECK_VIEWS.LIGHTING, false)}
          onYesClick={() => onPrecheckAnswer(PRECHECK_VIEWS.LIGHTING, true)}
        />
      )}
      {precheckView === PRECHECK_VIEWS.VISIBLE_HEEL && (
        <PreCheckQuestion
          title="Is the heel visible?"
          onNoClick={() => onPrecheckAnswer(PRECHECK_VIEWS.VISIBLE_HEEL, false)}
          onYesClick={() => onPrecheckAnswer(PRECHECK_VIEWS.VISIBLE_HEEL, true)}
        />
      )}
      {precheckView === PRECHECK_VIEWS.OVERALL_GOOD_SCAN && (
        <PreCheckQuestion
          title="Is the scan overall good?"
          onNoClick={() => onPrecheckAnswer(PRECHECK_VIEWS.OVERALL_GOOD_SCAN, false)}
          onYesClick={() => {
            onPrecheckAnswer(PRECHECK_VIEWS.OVERALL_GOOD_SCAN, true);
            onVideoUpload();

            setResults({
              [`${footSelected}FootScanUploading`]: true
            });
          }}
        />
      )}
    </>
  );
}
