import { ROUTES } from '@/Router';
import { useGetWorkflowDashboard, useSaveSignatureAndAmbulatoryData } from '@/api/clincalWorkflow';
import { useCommitFormData, useGetInitialFormData } from '@/api/forms';
import { FormTypes } from '@/components/FormPage/FormBuilder';
import FormStep from '@/components/FormPage/FormStep';
import HikeLoadingOverlay from '@/components/HikeLoadingOverlay/HikeLoadingOverlay';
import { DiabeticCarePhysicianAutocomplete } from '@/pages/PatientHealth/DiabeticCarePhysicianAutocomplete';
import { ICD10Component } from '@/pages/PatientHealth/ICD10/ICD10Component';
import { PrescribingPhysicianAutocomplete } from '@/pages/PatientHealth/PrescribingPhysicianAutocomplete';
import { UseOrderFormResponse } from '@/types/order.form.type';
import { ICD10CodeAndDescription, UsePatientHealthFormResponse } from '@/types/patient-health.form.type';
import { getStringInputFromBooleanForSingleOption } from '@/utils/InitialValueFieldsHelper';
import { FEATURE_FLAG } from '@/utils/featureFlag';
import { queryClient } from '@/utils/queryClient';
import { zodHelpers } from '@/utils/zodHelpers';
import { Alert, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const formTitle = 'patient-health';

export default function PatientHealth() {
  const [isDiabetic, setIsDiabetic] = useState<boolean>(false);
  const [isDeformitiesOther, setIsDeformitiesOther] = useState<boolean>(false);
  const [isLivingStatusOther, setIsLivingStatusOther] = useState<boolean>(false);
  const [isGoalsOfServiceOther, setIsGoalsOfServiceOther] = useState<boolean>(false);
  const [hasPriorOrthosis, setHasPriorOrthosis] = useState<boolean>(false);
  const navigate = useNavigate();
  const diabeticDvfOptionsFeatureFlag = useFeatureFlagEnabled(FEATURE_FLAG.DIABETIC_DVF_OPTIONS);
  const { visit_id } = useParams() as { visit_id: string };

  const {
    data: queriedFormData,
    isLoading: initialFormDataLoading,
    isError
  } = useGetInitialFormData<UsePatientHealthFormResponse>(formTitle, visit_id);

  const {
    isLoading: workflowInfoLoading,
    data: workflowInfo,
    refetch: refetchWorkflowInfo
  } = useGetWorkflowDashboard(visit_id, {
    enabled: typeof visit_id !== 'undefined',
    refetchOnMount: true
  });

  const { mutateAsync: mutateFormPage, isPending: submitFormPageLoading } = useCommitFormData({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: async ({ data }, { pageTitle }) => {
      queryClient.setQueryData(['initial-form-data', formTitle, visit_id], (prev: UsePatientHealthFormResponse) => {
        const prevPageFields = prev[pageTitle as keyof UsePatientHealthFormResponse];

        return {
          ...prev,
          [pageTitle]: {
            ...prevPageFields,
            ...data
          }
        };
      });
      if (pageTitle === 'health_profile') {
        const orderFormQueryData = queryClient.getQueryState(['initial-form-data', 'order-form', visit_id]);
        if (orderFormQueryData) {
          queryClient.setQueryData(['initial-form-data', 'order-form', visit_id], (prev: UseOrderFormResponse) => ({
            ...prev,
            is_diabetic: data.is_diabetic
          }));
        }
      }
    }
  });

  if (!visit_id) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to get visit ID from session storage" mt="xs">
        An error occurred getting the visit ID.
      </Alert>
    );
  }
  const getPreviousOrthosisMap = (valueFromDb?: boolean) => {
    if (valueFromDb === true) {
      return 'Fit in the Past';
    }
    if (valueFromDb === false) {
      return 'New Service';
    }
    return null;
  };

  const { mutate: saveSignatureAndAmbulatoryData } = useSaveSignatureAndAmbulatoryData({
    onSuccess: () => {
      refetchWorkflowInfo();
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: "Couldn't save ambulatory data",
        color: 'red'
      });
    }
  });

  if (typeof diabeticDvfOptionsFeatureFlag === 'undefined') {
    return <Loader />;
  }
  const extractICD10Codes = (codesAndDescriptions?: Array<ICD10CodeAndDescription>) =>
    codesAndDescriptions?.map((value) => value.code);

  const allForms = {
    'health-profile': [
      {
        field: 'gender',
        type: FormTypes.singleOption,
        label: 'Patient Gender',
        options: [
          {
            label: 'Male',
            value: 'male'
          },
          {
            label: 'Female',
            value: 'female'
          },
          {
            label: 'Other',
            value: 'Other'
          }
        ],
        initialValue: queriedFormData?.health_profile.gender,
        validate: zodHelpers.requiredText
      },
      {
        field: 'is_ambulatory',
        type: FormTypes.singleOption,
        label: 'Is the Patient Ambulatory?',
        initialValue: getStringInputFromBooleanForSingleOption(workflowInfo?.is_ambulatory),
        validate: zodHelpers.requiredCheckbox,
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        field: 'height_in_feet',
        type: FormTypes.number,
        label: 'Height (feet)',
        initialValue:
          typeof queriedFormData?.health_profile.height === 'number'
            ? Math.floor(queriedFormData.health_profile.height / 12)
            : null,
        validate: zodHelpers.requiredNonNegativeNumber
      },
      {
        field: 'height_in_inches',
        type: FormTypes.number,
        label: 'Height (inches)',
        initialValue:
          typeof queriedFormData?.health_profile.height === 'number'
            ? queriedFormData.health_profile.height % 12
            : null,
        validate: zodHelpers.requiredNonNegativeNumber
      },
      {
        field: 'weight',
        type: FormTypes.number,
        label: 'Weight (lbs)',
        initialValue:
          typeof queriedFormData?.health_profile.weight === 'number' ? queriedFormData.health_profile.weight : null,
        validate: zodHelpers.requiredNonNegativeNumber
      },
      {
        field: 'is_diabetic',
        type: FormTypes.singleOption,
        label: 'Is the Patient Diabetic?',
        options: [
          {
            label: 'Yes',
            value: 'true'
          },
          {
            label: 'No',
            value: 'false'
          }
        ],
        initialValue: getStringInputFromBooleanForSingleOption(queriedFormData?.health_profile.is_diabetic),
        validate: zodHelpers.requiredDropdown
      },
      {
        hidden: !isDiabetic || !diabeticDvfOptionsFeatureFlag,
        field: 'diabetic_foot_complications',
        type: FormTypes.multiCheckbox,
        label: 'Does the patient have any of the following?',
        options: [
          {
            value: 'History of partial or complete amputation of the foot',
            label: 'History of partial or complete amputation of the foot'
          },
          {
            value: 'Peripheral neuropathy with evidence of callus formation',
            label: 'Peripheral neuropathy with evidence of callus formation'
          },
          { value: 'History of previous foot ulceration', label: 'History of previous foot ulceration' },
          { value: 'History pre-ulcerative calluses', label: 'History pre-ulcerative calluses' },
          { value: 'Foot deformity', label: 'Foot deformity' },
          { value: 'Poor circulation', label: 'Poor circulation' }
        ],
        initialValue: queriedFormData?.health_profile.diabetic_foot_complications,
        validate: z.array(z.string()).min(1, {
          message: 'At least one of these must be selected for the patient to qualify for the service'
        })
      },
      {
        field: 'is_amputee',
        type: FormTypes.singleOption,
        label: 'Is the Patient an Amputee?',
        options: [
          {
            label: 'Yes',
            value: 'true'
          },
          {
            label: 'No',
            value: 'false'
          }
        ],
        initialValue: getStringInputFromBooleanForSingleOption(queriedFormData?.health_profile.is_amputee),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'is_veteran_administration',
        type: FormTypes.singleOption,
        label: 'Is this a VA patient?',
        options: [
          {
            label: 'Yes',
            value: 'true'
          },
          {
            label: 'No',
            value: 'false'
          }
        ],
        initialValue: getStringInputFromBooleanForSingleOption(
          queriedFormData?.health_profile.is_veteran_administration
        ),
        validate: zodHelpers.requiredDropdown
      },
      {
        field: 'assistive_devices',
        type: FormTypes.multiSelectBubble,
        label: 'Mark assistive devices used:',
        options: [
          {
            label: 'Wheelchair',
            value: 'Wheelchair'
          },
          {
            label: 'Walker',
            value: 'Walker'
          },
          {
            label: 'Cane',
            value: 'Cane'
          },
          {
            label: 'Crutches',
            value: 'Crutches'
          }
        ],
        initialValue: queriedFormData?.health_profile.assistive_devices,
        validate: zodHelpers.optionalMultiCheckbox
      },
      {
        field: 'past_patient_surgeries',
        type: FormTypes.text,
        label: 'Describe any past patient foot surgeries below, if any:',
        initialValue: queriedFormData?.health_profile.past_patient_surgeries,
        validate: zodHelpers.optionalText
      },
      {
        field: 'patient_complaints',
        type: FormTypes.text,
        label: 'The patient complains of the following',
        initialValue: queriedFormData?.health_profile.patient_complaints,
        validate: zodHelpers.requiredText
      }
    ],
    'prescription-information': [
      {
        field: 'diabetic_care_physician',
        type: FormTypes.autoComplete,
        hidden: !isDiabetic,
        label: 'Diabetic Care Physician',
        initialValue: queriedFormData?.prescription_information.diabetic_care_physician,
        validate: zodHelpers.optionalText,
        component: DiabeticCarePhysicianAutocomplete
      },
      {
        field: 'prescribing_physician',
        type: FormTypes.autoComplete,
        label: 'Prescribing Physician',
        initialValue: queriedFormData?.prescription_information.prescribing_physician,
        validate: zodHelpers.optionalText,
        component: PrescribingPhysicianAutocomplete
      },
      {
        field: 'prescription_type',
        type: FormTypes.text,
        label: 'Prescription Type',
        initialValue: queriedFormData?.prescription_information.prescription_type,
        validate: zodHelpers.optionalText
      },
      {
        field: 'last_physician_visit',
        type: FormTypes.month,
        maxDate: new Date(),
        label: 'Approx. Date of Last Physician Visit',
        initialValue:
          queriedFormData?.prescription_information.last_physician_visit &&
          typeof queriedFormData.prescription_information.last_physician_visit !== 'undefined' &&
          dayjs(queriedFormData.prescription_information.last_physician_visit).toDate(),
        validate: zodHelpers.optionalDate
      }
    ],
    diagnosis: [
      {
        field: 'icd_10_codes_and_descriptions',
        type: FormTypes.autoComplete,
        component: ICD10Component,
        label: 'ICD-10 Codes',
        initialValue: queriedFormData?.diagnosis.icd_10_codes_and_descriptions ?? [],
        validate: z
          .array(
            z.object({
              code: z.string(),
              description: z.string()
            })
          )
          .min(1, {
            message: 'Please select at least one diagnosis code'
          })
      }
    ],
    'previous-orthosis': [
      {
        field: 'has_previous_orthosis',
        type: FormTypes.singleOption,
        label: 'Is this a new service or has the patient been fit with an identical or similar device in the past?',
        options: [
          {
            value: 'false',
            label: 'New Service'
          },
          {
            value: 'true',
            label: 'Fit in the Past'
          }
        ],
        initialValue: getPreviousOrthosisMap(queriedFormData?.previous_orthosis.has_previous_orthosis),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'previous_orthosis_date',
        hidden: !hasPriorOrthosis,
        type: FormTypes.month,
        maxDate: new Date(),
        label: 'Approx. when was the patient last fit with the device?',
        initialValue:
          queriedFormData?.previous_orthosis.previous_orthosis_date &&
          typeof queriedFormData.previous_orthosis.previous_orthosis_date !== 'undefined' &&
          dayjs(queriedFormData.previous_orthosis.previous_orthosis_date).toDate(),
        validate: zodHelpers.requiredDate
      },
      {
        field: 'prior_orthosis_conditions',
        label: 'Why does it need to be replaced?',
        hidden: !hasPriorOrthosis,
        type: FormTypes.multiCheckbox,
        initialValue: queriedFormData?.previous_orthosis.prior_orthosis_conditions,
        options: [
          {
            value: 'The patient no longer has the device.',
            label: 'The patient no longer has the device.'
          },
          {
            value: 'The shape of the device is no longer matching that of the patient.',
            label: 'The shape of the device is no longer matching that of the patient.'
          },
          {
            value: 'The patient has outgrown the previous device.',
            label: 'The patient has outgrown the previous device.'
          },
          {
            value: 'The patient describes different functional support than their previous device provides.',
            label: 'The patient describes different functional support than their previous device provides.'
          },
          {
            value: 'The previous device is at risk for breakage because of visible stress marks in the plastic.',
            label: 'The previous device is at risk for breakage because of visible stress marks in the plastic.'
          },
          {
            value: 'The patient device is broken and not repairable.',
            label: 'The patient device is broken and not repairable.'
          },
          {
            value: 'The patient has had surgery that has altered the anatomy that the device was made for.',
            label: 'The patient has had surgery that has altered the anatomy that the device was made for.'
          }
        ],
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'patient-lifestyle': [
      {
        field: 'living_status',
        type: FormTypes.dropdown,
        label: 'Living Status',
        initialValue:
          queriedFormData?.patient_lifestyle.living_status &&
          typeof queriedFormData.patient_lifestyle.living_status === 'object'
            ? queriedFormData.patient_lifestyle.living_status.living_status
            : '',
        options: [
          {
            label: 'Alone or without assistance',
            value: 'Alone or without assistance'
          },
          {
            label: 'Long-term care facility',
            value: 'Long-term care facility'
          },
          {
            label: 'Home with assistance',
            value: 'Home with assistance'
          },
          {
            label: 'Other',
            value: 'Other'
          }
        ],
        validate: zodHelpers.requiredDropdown
      },
      {
        field: 'living_status_other',
        type: FormTypes.text,
        hidden: !isLivingStatusOther,
        label: "Describe the patient's living status:",
        initialValue:
          queriedFormData?.patient_lifestyle.living_status &&
          typeof queriedFormData.patient_lifestyle.living_status === 'object'
            ? queriedFormData.patient_lifestyle.living_status.living_status_other
            : '',
        validate: zodHelpers.requiredText
      },
      {
        field: 'hours_spent_sitting',
        type: FormTypes.number,
        label: 'Hours per day spent sitting',
        initialValue: queriedFormData?.patient_lifestyle.hours_spent_sitting,
        validate: zodHelpers.optionalNonNegativeNumber
      },
      {
        field: 'hours_spent_standing',
        type: FormTypes.number,
        label: 'Hours per day spent standing',
        initialValue: queriedFormData?.patient_lifestyle.hours_spent_standing,
        validate: zodHelpers.optionalNonNegativeNumber
      }
    ],
    'goals-of-service': [
      {
        field: 'goals_of_service',
        type: FormTypes.multiCheckbox,
        label: 'Select all goals of service',
        options: [
          {
            value: 'Protection of sensation in compromised foot',
            label: 'Protection of sensation in compromised foot'
          },
          {
            value: 'Correction of a flexible deformity',
            label: 'Correction of a flexible deformity'
          },
          {
            value: 'Provision of appropriate footwear for protection, support, stability, correction, and comfort.',
            label: 'Provision of appropriate footwear for protection, support, stability, correction, and comfort.'
          },
          {
            value: 'Correction of anatomical alignment to improve biomechanical gait pattern',
            label: 'Correction of anatomical alignment to improve biomechanical gait pattern'
          },
          {
            value: 'Accommodation of deformity created by diabetic/dyvascular changes',
            label: 'Accommodation of deformity created by diabetic/dyvascular changes'
          },
          {
            value: 'Optimize Gait by reduction of adverse pressures on the feet',
            label: 'Optimize Gait by reduction of adverse pressures on the feet'
          },
          {
            value: 'Provision of appropriate footwear to use with inserts',
            label: 'Provision of appropriate footwear to use with inserts'
          },
          {
            value: 'Offload wounds and/or deformities',
            label: 'Offload wounds and/or deformities'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ],
        initialValue:
          queriedFormData?.goals_of_service.goals_of_service &&
          typeof queriedFormData.goals_of_service.goals_of_service === 'object'
            ? queriedFormData.goals_of_service.goals_of_service.goals_of_service
            : [],
        validate: zodHelpers.requiredMultiCheckbox
      },
      {
        field: 'goals_of_service_other',
        type: FormTypes.text,
        hidden: !isGoalsOfServiceOther,
        label: 'Describe the goals of service:',
        initialValue:
          queriedFormData?.goals_of_service.goals_of_service &&
          typeof queriedFormData.goals_of_service.goals_of_service === 'object'
            ? queriedFormData.goals_of_service.goals_of_service.goals_of_service_other
            : '',
        validate: zodHelpers.requiredText
      }
    ],
    deformities: [
      {
        field: 'deformities',
        type: FormTypes.multiCheckbox,
        label: 'Select all deformities',
        options: [
          {
            value: 'Bunion',
            label: 'Bunion'
          },
          {
            value: 'Hammer Toes',
            label: 'Hammer Toes'
          },
          {
            value: 'Scarring',
            label: 'Scarring'
          },
          {
            value: 'Callus',
            label: 'Callus'
          },
          {
            value: 'Overlapping Toes',
            label: 'Overlapping Toes'
          },
          {
            value: 'Hallux Valgus',
            label: 'Hallux Valgus'
          },
          {
            value: 'Hallux Rigidus',
            label: 'Hallux Rigidus'
          },
          {
            value: 'Clawing',
            label: 'Clawing'
          },
          {
            value: 'History of Partial or Complete Amputation of the Foot',
            label: 'History of Partial or Complete Amputation of the Foot'
          },
          {
            value: 'None',
            label: 'None'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ],
        initialValue:
          queriedFormData?.deformities.deformities && typeof queriedFormData.deformities.deformities === 'object'
            ? queriedFormData.deformities.deformities.deformities
            : '',
        validate: zodHelpers.requiredMultiCheckbox
      },
      {
        field: 'deformities_other',
        type: FormTypes.text,
        hidden: !isDeformitiesOther,
        label: 'Describe the deformities:',
        initialValue:
          queriedFormData?.deformities.deformities && typeof queriedFormData.deformities.deformities === 'object'
            ? queriedFormData.deformities.deformities.deformities_other
            : '',
        validate: zodHelpers.requiredText
      }
    ]
  };

  if (isError) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to fetch patient health data" mt="xs">
        An error occurred getting initial patient health form data for visit.
      </Alert>
    );
  }

  const isLoading = initialFormDataLoading || workflowInfoLoading;

  return (
    <HikeLoadingOverlay isLoading={isLoading}>
      <FormStep
        showTitlesAtEachStep
        disabled={workflowInfo?.submitted}
        onPageSubmit={[
          {
            deformities: async (values) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'deformities',
                formChanges: {
                  deformities: {
                    deformities: values.deformities,
                    deformities_other: isDeformitiesOther ? values.deformities_other : ''
                  }
                }
              });

              navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
            },
            diagnosis: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'diagnosis',
                formChanges: {
                  icd_10_codes: extractICD10Codes(values.icd_10_codes_and_descriptions)
                }
              }),
            'goals-of-service': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'goals_of_service',
                formChanges: {
                  goals_of_service: {
                    goals_of_service: values.goals_of_service,
                    goals_of_service_other: isGoalsOfServiceOther ? values.goals_of_service_other : ''
                  }
                }
              }),
            'health-profile': async (values) => {
              const isAmbulatoryCheck: 'Yes' | 'No' = values.is_ambulatory;
              saveSignatureAndAmbulatoryData({
                visit_id,
                is_ambulatory: isAmbulatoryCheck === 'Yes'
              });

              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'health_profile',
                formChanges: {
                  gender: values.gender,
                  height: values.height_in_feet * 12 + values.height_in_inches,
                  weight: values.weight,
                  diabetic_foot_complications: values.diabetic_foot_complications,
                  is_diabetic: values.is_diabetic === 'Yes' ? true : values.is_diabetic === 'No' ? false : null,
                  is_amputee: values.is_amputee === 'Yes' ? true : values.is_amputee === 'No' ? false : null,
                  is_veteran_administration:
                    values.is_veteran_administration === 'Yes'
                      ? true
                      : values.is_veteran_administration === 'No'
                        ? false
                        : null,
                  assistive_devices: values.assistive_devices,
                  past_patient_surgeries: values.past_patient_surgeries,
                  patient_complaints: values.patient_complaints
                }
              });
            },
            'patient-lifestyle': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_lifestyle',
                formChanges: {
                  hours_spent_sitting:
                    typeof values.hours_spent_sitting === 'number' ? values.hours_spent_sitting : null,
                  hours_spent_standing:
                    typeof values.hours_spent_standing === 'number' ? values.hours_spent_standing : null,
                  living_status: {
                    living_status: values.living_status,
                    living_status_other: isLivingStatusOther ? values.living_status_other : ''
                  }
                }
              }),
            'prescription-information': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'prescription_information',
                formChanges: {
                  diabetic_care_physician: values.diabetic_care_physician,
                  prescribing_physician: values.prescribing_physician,
                  prescription_type: values.prescription_type,
                  last_physician_visit: values.last_physician_visit
                }
              }),
            'previous-orthosis': (values) => {
              const has_previous_orthosis =
                values.has_previous_orthosis === 'Fit in the Past'
                  ? true
                  : values.has_previous_orthosis === 'New Service'
                    ? false
                    : null;

              return mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'previous_orthosis',
                formChanges: {
                  has_previous_orthosis,
                  previous_orthosis_date: has_previous_orthosis ? values.previous_orthosis_date : null,
                  prior_orthosis_conditions: has_previous_orthosis ? values.prior_orthosis_conditions : null
                }
              });
            }
          }
        ]}
        onFormInputChange={[
          {
            'health-profile': (values: any, form) => {
              if (values.is_diabetic === 'No') {
                form?.clearFieldError('diabetic_foot_complications');
              }
              setIsDiabetic(values.is_diabetic === 'Yes');
            },
            'patient-lifestyle': (values: any) => {
              setIsLivingStatusOther(values.living_status === 'Other');
            },
            'previous-orthosis': (values: any, form) => {
              form?.clearErrors();
              setHasPriorOrthosis(values.has_previous_orthosis === 'Fit in the Past');
            },
            'goals-of-service': (values: any) => {
              setIsGoalsOfServiceOther(values.goals_of_service.includes('Other'));
            },
            deformities: (values: any) => {
              setIsDeformitiesOther(values.deformities.includes('Other'));
            }
          }
        ]}
        title="Patient Health"
        fields={allForms}
        buttonProps={{
          loading: submitFormPageLoading
        }}
      />
    </HikeLoadingOverlay>
  );
}
