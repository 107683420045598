import { ROUTES } from '@/Router';
import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useCommitFormData, useGetInitialFormData, useShipImpressionBox } from '@/api/forms';
import { FormTypes } from '@/components/FormPage/FormBuilder';
import FormStep from '@/components/FormPage/FormStep';
import HikeLoadingOverlay from '@/components/HikeLoadingOverlay/HikeLoadingOverlay';
import ArchHeight from '@/pages/OrderForm/Accomodations/ArchHeight';
import FlangeHeight from '@/pages/OrderForm/Accomodations/FlangeHeight';
import ForefootPosting from '@/pages/OrderForm/Accomodations/ForefootPosting';
import HeelCup from '@/pages/OrderForm/Accomodations/HeelCup';
import HeelRaise from '@/pages/OrderForm/Accomodations/HeelRaise';
import RearfootPosting from '@/pages/OrderForm/Accomodations/RearfootPosting';
import MetBar from '@/pages/OrderForm/AdditionsAndExtensions/MetBar';
import MetHead from '@/pages/OrderForm/AdditionsAndExtensions/MetHead';
import MetPad from '@/pages/OrderForm/AdditionsAndExtensions/MetPad';
import MortonExtension from '@/pages/OrderForm/AdditionsAndExtensions/MortonExtension';
import ReverseMortonExtension from '@/pages/OrderForm/AdditionsAndExtensions/ReverseMortonExtension';
import { ClinicAutocomplete } from '@/pages/OrderForm/ClinicAutocomplete';
import { UseOrderFormResponse } from '@/types/order.form.type';
import { getStringInputFromBooleanForSingleOption } from '@/utils/InitialValueFieldsHelper';
import { queryClient } from '@/utils/queryClient';
import { zodHelpers } from '@/utils/zodHelpers';
import { Alert, Button, Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import kebabCase from 'lodash/kebabCase';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import productTypesAndDescriptions from '../../constants/product_types_and_descriptions.json';

const formTitle = 'order-form';

const productOptions = {
  diabetic: [
    {
      label: 'Sweet Solemate',
      value: 'sweet_solemate'
    },
    {
      label: 'Double Sweet Solemate',
      value: 'double_sweet_solemate'
    },
    {
      label: 'Triple Sweet Solemate',
      value: 'triple_sweet_solemate'
    },
    {
      label: 'Build Your Own Diabetic Insert',
      value: 'build_your_own_diabetic_insert'
    }
  ],
  non_diabetic: [
    {
      label: 'SM Sport',
      value: 'sm_sport'
    },
    {
      label: 'SM Everyday',
      value: 'sm_everyday'
    },
    {
      label: 'SM Dress',
      value: 'sm_dress'
    },
    {
      label: 'SM Corkbase',
      value: 'sm_corkbase'
    },
    {
      label: 'SM Specialty Styles',
      value: 'sm_specialty_styles'
    },
    {
      label: 'Build Your Own Functional Insert',
      value: 'build_your_own_functional_insert'
    }
  ]
};

const ACCOMMODATION_LIST = [
  'forefoot-posting',
  'rearfoot-posting',
  'arch-height',
  'heel-cup',
  'flange-height',
  'heel-raise',
  'first-cutout',
  'fifth-cutout',
  'filler'
];

const EXTENSIONS_LIST = [
  'off-load-met-head',
  'met-pad',
  'met-bar',
  'dancer-pad',
  'toe-crest',
  'cuboid-raise',
  'morton-extension',
  'reverse-morton-extension'
];

const validateShoeSize = z
  .number()
  .gt(0, {
    message: 'Shoe size must be greater than 0.'
  })
  .lte(20, {
    message: 'Shoe size must be 20 or below.'
  })
  .refine(
    (value) => {
      // Check if the number is an integer or an integer plus 0.5
      const isFullSize = Number.isInteger(value);
      const isHalfSize = value - Math.floor(value) === 0.5;
      return isFullSize || isHalfSize;
    },
    {
      message: 'Must be a valid numerical shoe size between 1 and 20. Only half and full sizes are allowed.'
    }
  )
  .transform((val) => Number(val));

export default function OrderForm() {
  const [selectedForms, setSelectedForms] = useState<string[]>([
    'patient-information',
    'billing-information',
    'shipping-information',
    'processing-time',
    'casting-type',
    'product-type',
    'shell-specs',
    'accommodations',
    'forefoot-posting',
    'rearfoot-posting',
    'arch-height',
    'heel-cup',
    'flange-height',
    'heel-raise',
    'first-cutout',
    'fifth-cutout',
    'filler',
    'additions-and-extensions',
    'off-load-met-head',
    'met-pad',
    'met-bar',
    'dancer-pad',
    'toe-crest',
    'cuboid-raise',
    'morton-extension',
    'reverse-morton-extension',
    'order-notes'
  ]);
  const [isMeasurementToolOther, setIsMeasurementToolOther] = useState<boolean>(false);
  const [productTypeDescription, setProductTypeDescription] = useState<string>('');
  const [isDiffSizes, setIsDiffSizes] = useState<boolean>(false);
  const [isLeftMeasurementToolOther, setIsLeftMeasurementToolOther] = useState<boolean>(false);
  const [isRightMeasurementToolOther, setIsRightMeasurementToolOther] = useState<boolean>(false);
  const { visit_id } = useParams() as { visit_id: string };
  const navigate = useNavigate();

  const {
    data: queriedFormData,
    isLoading,
    isError,
    refetch: refetchFormData
  } = useGetInitialFormData<UseOrderFormResponse>(formTitle, visit_id);

  const { data: workflow_info } = useGetWorkflowDashboard(visit_id, {});
  const [fillerModalOpen, fillerModalHandlers] = useDisclosure(false);

  const { mutateAsync: mutateFormPage, isPending: submitFormPageLoading } = useCommitFormData({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: async ({ data }, { pageTitle }) => {
      queryClient.setQueryData(['initial-form-data', formTitle, visit_id], (prev: UseOrderFormResponse) => {
        const prevPageFields = prev[pageTitle as keyof UseOrderFormResponse];
        return {
          ...prev,
          [pageTitle]: {
            ...prevPageFields,
            ...data
          }
        };
      });
    }
  });
  const measurementToolInitialValue = (isOther: boolean, tool?: string) => {
    const includedTool = ['Brannock', 'Ritz Stick', 'Tape Measure'].includes(tool as string);
    if (isOther) {
      if (tool && !includedTool) {
        return tool;
      }
      return '';
    }
    if (!tool || includedTool) {
      return tool;
    }
    return 'Other';
  };

  const { mutate: mutateShipImpressionBox } = useShipImpressionBox({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orderInformation', visit_id as string] });
      refetchFormData();
    }
  });

  const getDescriptionFromProductType = (value?: string) => {
    if (value && value in productTypesAndDescriptions) {
      return productTypesAndDescriptions[value];
    }
    return '';
  };

  const getMeasurementToolValue = (
    useOtherMeasuringTool: boolean,
    measurement_tool_other: string,
    measurement_tool: string
  ) => {
    if (useOtherMeasuringTool) {
      return measurement_tool_other;
    }

    return measurement_tool;
  };

  const isDiabetic = queriedFormData?.patient_information?.order_form_type === 'Diabetic';

  const allForms = {
    'patient-information': [
      {
        field: 'order_form_type',
        type: FormTypes.singleOption,
        label: 'Which type of order form do you want?',
        options: [
          {
            label: 'Functional',
            value: 'functional'
          },
          {
            label: 'Diabetic',
            value: 'fiabetic'
          }
        ],
        initialValue: queriedFormData?.patient_information?.order_form_type,
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'gender',
        type: FormTypes.singleOption,
        label: 'Shoe Gender',
        options: [
          {
            label: 'Male',
            value: 'male'
          },
          {
            label: 'Female',
            value: 'female'
          }
        ],
        initialValue: queriedFormData?.patient_information.gender,
        validate: zodHelpers.requiredText
      },
      {
        field: 'is_diff_size',
        type: FormTypes.singleOption,
        label: 'Does the patient have different size shoes?',
        options: [
          {
            value: 'Yes',
            label: 'Yes'
          },
          {
            value: 'No',
            label: 'No'
          }
        ],
        initialValue: getStringInputFromBooleanForSingleOption(queriedFormData?.patient_information.is_diff_size),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'shoe_size',
        label: 'Shoe Size',
        type: FormTypes.number,
        hidden: isDiffSizes,
        placeholder: 'Enter Shoe Size',
        initialValue: queriedFormData?.patient_information.shoe_size
          ? Number(queriedFormData.patient_information.shoe_size)
          : null,
        validate: validateShoeSize
      },
      {
        field: 'left_shoe_size',
        label: 'Left Shoe Size',
        type: FormTypes.number,
        placeholder: 'Enter Left Shoe Size',
        hidden: !isDiffSizes,
        initialValue: queriedFormData?.patient_information?.sides_shoe_size?.left_shoe_size
          ? Number(queriedFormData.patient_information?.sides_shoe_size?.left_shoe_size)
          : null,
        validate: validateShoeSize
      },
      {
        field: 'right_shoe_size',
        label: 'Right Shoe Size',
        type: FormTypes.number,
        placeholder: 'Enter Right Shoe Size',
        hidden: !isDiffSizes,
        initialValue: queriedFormData?.patient_information?.sides_shoe_size?.right_shoe_size
          ? Number(queriedFormData.patient_information?.sides_shoe_size?.right_shoe_size)
          : null,
        validate: validateShoeSize
      },
      {
        field: 'measurement_tool',
        type: FormTypes.checkbox,
        hidden: isDiffSizes,
        label: 'Tool for measuring shoe size',
        options: [
          {
            value: 'Brannock',
            label: 'Brannock'
          },
          {
            value: 'Ritz Stick',
            label: 'Ritz Stick'
          },
          {
            value: 'Tape Measure',
            label: 'Tape Measure'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ],
        initialValue: measurementToolInitialValue(false, queriedFormData?.patient_information?.measurement_tool),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'measurement_tool_other',
        type: FormTypes.text,
        hidden: !isMeasurementToolOther || isDiffSizes,
        label: 'Please describe the measurement tool',
        validate: zodHelpers.requiredText,
        initialValue: measurementToolInitialValue(true, queriedFormData?.patient_information?.measurement_tool)
      },
      {
        field: 'left_measurement_tool',
        type: FormTypes.checkbox,
        hidden: !isDiffSizes,
        label: 'Tool for measuring left shoe size',
        options: [
          {
            value: 'Brannock',
            label: 'Brannock'
          },
          {
            value: 'Ritz Stick',
            label: 'Ritz Stick'
          },
          {
            value: 'Tape Measure',
            label: 'Tape Measure'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ],
        initialValue: measurementToolInitialValue(
          false,
          queriedFormData?.patient_information?.sides_measurement_tool?.left_measurement_tool
        ),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'left_measurement_tool_other',
        type: FormTypes.text,
        hidden: !isLeftMeasurementToolOther || !isDiffSizes,
        label: 'Please describe the left measurement tool',
        validate: zodHelpers.requiredText,
        initialValue: measurementToolInitialValue(
          true,
          queriedFormData?.patient_information?.sides_measurement_tool?.left_measurement_tool
        )
      },
      {
        field: 'right_measurement_tool',
        type: FormTypes.checkbox,
        hidden: !isDiffSizes,
        label: 'Tool for measuring right shoe size',
        options: [
          {
            value: 'Brannock',
            label: 'Brannock'
          },
          {
            value: 'Ritz Stick',
            label: 'Ritz Stick'
          },
          {
            value: 'Tape Measure',
            label: 'Tape Measure'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ],
        initialValue: measurementToolInitialValue(
          false,
          queriedFormData?.patient_information?.sides_measurement_tool?.right_measurement_tool
        ),
        validate: zodHelpers.requiredCheckbox
      },
      {
        field: 'right_measurement_tool_other',
        type: FormTypes.text,
        hidden: !isRightMeasurementToolOther || !isDiffSizes,
        label: 'Please describe the right measurement tool',
        validate: zodHelpers.requiredText,
        initialValue: measurementToolInitialValue(
          true,
          queriedFormData?.patient_information?.sides_measurement_tool?.right_measurement_tool
        )
      },
      {
        field: 'shoe_width',
        label: 'Shoe Width',
        hidden: isDiffSizes,
        validate: zodHelpers.requiredDropdown,
        required: true,
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Narrow',
            value: 'narrow'
          },
          {
            label: 'Medium',
            value: 'medium'
          },
          {
            label: 'Wide',
            value: 'wide'
          },
          {
            label: 'Extra-Wide',
            value: 'extra_wide'
          },
          {
            label: 'Youth',
            value: 'youth'
          },
          {
            label: 'Toddler',
            value: 'toddler'
          }
        ],
        initialValue: queriedFormData?.patient_information.shoe_width
      },
      {
        field: 'left_shoe_width',
        label: 'Left Shoe Width',
        hidden: !isDiffSizes,
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Narrow',
            value: 'narrow'
          },
          {
            label: 'Medium',
            value: 'medium'
          },
          {
            label: 'Wide',
            value: 'wide'
          },
          {
            label: 'Extra-Wide',
            value: 'extra_wide'
          },
          {
            label: 'Youth',
            value: 'youth'
          },
          {
            label: 'Toddler',
            value: 'toddler'
          }
        ],
        initialValue: queriedFormData?.patient_information?.sides_shoe_width?.left_shoe_width,
        validate: zodHelpers.optionalText
      },
      {
        field: 'right_shoe_width',
        label: 'Right Shoe Width',
        hidden: !isDiffSizes,
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Narrow',
            value: 'narrow'
          },
          {
            label: 'Medium',
            value: 'medium'
          },
          {
            label: 'Wide',
            value: 'wide'
          },
          {
            label: 'Extra-Wide',
            value: 'extra_wide'
          },
          {
            label: 'Youth',
            value: 'youth'
          },
          {
            label: 'Toddler',
            value: 'toddler'
          }
        ],
        initialValue: queriedFormData?.patient_information?.sides_shoe_width?.right_shoe_width,
        validate: zodHelpers.optionalText
      },
      {
        field: 'quantity',
        label: 'Insole Quantity',
        type: FormTypes.numberDropdown,
        options: [
          {
            label: '1',
            value: 1
          },
          {
            label: '2',
            value: 2
          },
          {
            label: '3',
            value: 3
          }
        ],
        initialValue: queriedFormData?.patient_information.quantity,
        validate: zodHelpers.requiredNumberInputGreaterThan0,
        required: true
      },
      {
        field: 'clinic_name',
        label: 'Clinic Name',
        type: FormTypes.autoComplete,
        component: ClinicAutocomplete,
        initialValue: queriedFormData?.patient_information.clinic_name,
        validate: zodHelpers.requiredDropdown,
        required: true
      },
      {
        field: 'order_side',
        label: 'Order Side',
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Left',
            value: 'Left'
          },
          {
            label: 'Right',
            value: 'Right'
          },
          {
            label: 'Both',
            value: 'Both'
          }
        ],
        initialValue: queriedFormData?.patient_information.order_side,
        validate: zodHelpers.requiredDropdown,
        required: true
      }
    ],
    'billing-information': [
      {
        field: 'billing_name',
        label: 'Billing Account Name',
        type: FormTypes.text,
        placeholder: 'Enter Billing Acount Name',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.billing_name ?? queriedFormData?.clinician_name,
        required: true
      },
      {
        field: 'billing_address',
        label: 'Billing Address',
        type: FormTypes.text,
        placeholder: 'Enter Billing Address',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.billing_address,
        required: true
      },
      {
        field: 'billing_city',
        label: 'Billing City',
        type: FormTypes.text,
        placeholder: 'Enter Billing City',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.billing_city,
        required: true
      },
      {
        field: 'billing_state',
        label: 'Billing State',
        type: FormTypes.text,
        placeholder: 'Enter Billing State',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.billing_state,
        required: true
      },
      {
        field: 'billing_zipcode',
        label: 'Billing Zipcode',
        type: FormTypes.text,
        placeholder: 'Enter Billing Zipcode',
        validate: zodHelpers.requiredText,
        initialValue: queriedFormData?.billing_information.billing_zipcode,
        required: true
      },
      {
        field: 'billing_number',
        label: 'Billing Phone Number',
        type: FormTypes.text,
        placeholder: '(XXX) XXX-XXXX',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.billing_number ?? queriedFormData?.company_contact_number,
        required: true
      },
      {
        field: 'shipping_same_as_billing',
        label: 'Shipping Address Same as Billing Address',
        type: FormTypes.checkbox,
        options: [
          {
            label: 'Shipping Address Same as Billing',
            value: 'shipping_address_same_as_billing'
          }
        ],
        validate: zodHelpers.optionalText
      }
    ],
    'shipping-information': [
      {
        field: 'shipping_address',
        label: 'Shipping Address',
        type: FormTypes.text,
        placeholder: 'Enter Shipping Address',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.shipping_address,
        required: true
      },
      {
        field: 'shipping_city',
        label: 'Shipping City',
        type: FormTypes.text,
        placeholder: 'Enter Shipping City',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.shipping_city,
        required: true
      },
      {
        field: 'shipping_state',
        label: 'Shipping State',
        type: FormTypes.text,
        placeholder: 'Enter Shipping State',
        validate: zodHelpers.requiredTextInput,
        initialValue: queriedFormData?.billing_information.shipping_state,
        required: true
      },
      {
        field: 'shipping_zipcode',
        label: 'Shipping Zipcode',
        type: FormTypes.text,
        placeholder: 'Enter Shipping Zipcode',
        initialValue: queriedFormData?.billing_information.shipping_zipcode,
        validate: zodHelpers.requiredText,
        required: true
      }
    ],
    'processing-time': [
      {
        label: 'Production Turnaround',
        field: 'production_turnaround',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'Standard',
            value: 'standard'
          },
          {
            label: 'Rush (3 Days)',
            value: 'rush_3_days'
          }
        ],
        initialValue: queriedFormData?.processing_time.production_turnaround,
        validate: zodHelpers.requiredTextInput,
        required: true
      },
      {
        label: 'Shipping Time',
        field: 'shipping_time',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'Standard',
            value: 'standard'
          },
          {
            label: 'Overnight',
            value: 'overnight'
          }
        ],
        initialValue: queriedFormData?.processing_time.shipping_time,
        validate: zodHelpers.requiredTextInput,
        required: true
      }
    ],
    'casting-type': [
      {
        field: 'casting_method',
        label: 'Casting Method',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'Non-Weight Bearing',
            value: 'non_weight_bearing'
          },
          {
            label: 'Semi-Weight Bearing',
            value: 'semi_weight_bearing'
          },
          {
            label: 'Weight Bearing',
            value: 'weight_bearing'
          }
        ],
        initialValue: queriedFormData?.casting_type.casting_method,
        validate: zodHelpers.requiredTextInput,
        required: true
      },
      {
        field: 'casting_type',
        label: 'Casting Type',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'Hike Scan',
            value: 'hike_scan'
          },
          {
            label: 'Impression Box Scan',
            value: 'impression_box_scan'
          },
          {
            label: 'Structure Sensor Foot Scan',
            value: 'structure_sensor_foot_scan'
          },
          {
            label: 'Use Previous Scan/PO',
            value: 'use_previous_scan_po'
          },
          ...(selectedForms.includes('filler')
            ? [
                {
                  label: 'Impression Box Ship',
                  value: 'Impression Box Ship'
                }
              ]
            : [])
        ],
        initialValue: queriedFormData?.casting_type.casting_type,
        validate: zodHelpers.requiredTextInput,
        required: true
      }
    ],
    'product-type': [
      {
        label: `Product Specification ${isDiabetic ? '(Diabetic)' : '(Functional)'}`,
        field: 'product_type',
        description: productTypeDescription,
        type: FormTypes.singleOption,
        options: isDiabetic ? productOptions.diabetic : productOptions.non_diabetic,
        validate: zodHelpers.requiredCheckbox,
        initialValue: queriedFormData?.product_type.product_type,
        required: true
      }
    ],
    'specialty-styles': [
      {
        label: 'Select a Specialty Style',
        field: 'specialty',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'UCBL',
            value: 'UCBL'
          },
          {
            label: 'Gait Plate',
            value: 'Gait Plate'
          },
          {
            label: 'Whitman',
            value: 'Whitman'
          }
        ],
        initialValue:
          queriedFormData?.specialty_styles.specialty_styles &&
          typeof queriedFormData.specialty_styles.specialty_styles === 'object' &&
          queriedFormData.specialty_styles.specialty_styles.specialty,
        validate: zodHelpers.requiredCheckbox
      },
      {
        label: 'Select Co-Poly Measurement',
        field: 'co_poly',
        type: FormTypes.dropdown,
        options: [
          {
            label: '2mm',
            value: '2mm'
          },
          {
            label: '3mm',
            value: '3mm'
          },
          {
            label: '4mm',
            value: '4mm'
          },
          {
            label: '5mm',
            value: '5mm'
          }
        ],
        initialValue:
          queriedFormData?.specialty_styles.specialty_styles &&
          typeof queriedFormData.specialty_styles.specialty_styles === 'object' &&
          queriedFormData.specialty_styles.specialty_styles.co_poly,
        validate: zodHelpers.requiredDropdown
      }
    ],
    'build-your-own-functional-insert': [
      {
        label: 'Top Cover',
        field: 'top_cover',
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Vinyl',
            value: 'vinyl'
          },
          {
            label: '1/16in P-Cell',
            value: '1/16in P-Cell'
          },
          {
            label: '1/8in P-Cell',
            value: '1/8in P-Cell'
          },
          {
            label: '1/16in Fab-Fit',
            value: '1/16in Fab-Fit'
          },
          {
            label: '1/8in Fab-Fit',
            value: '1/8in Fab-Fit'
          },
          {
            label: 'EVA Blue Storm',
            value: 'EVA Blue Storm'
          },
          {
            label: '1/8in Puff (Blue)',
            value: '1/8in Puff (Blue)'
          },
          {
            label: 'Tri-Lam P-Cell',
            value: 'Tri-Lam P-Cell'
          },
          {
            label: 'Tri-Lam Puff',
            value: 'Tri-Lam Puff'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.top_cover,
        validate: zodHelpers.requiredDropdown,
        required: true
      },
      {
        label: 'Mid Layer',
        field: 'mid_layer',
        type: FormTypes.dropdown,
        options: [
          {
            label: '1/16in Puff',
            value: '1/16in Puff'
          },
          {
            label: '1/8in Puff',
            value: '1/8in Puff'
          },
          {
            label: '1/16in Poron',
            value: '1/16in Poron'
          },
          {
            label: '1/8in Poron',
            value: '1/8in Poron'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.mid_layer,
        validate: zodHelpers.optionalText
      },
      {
        label: 'Base',
        field: 'base',
        type: FormTypes.dropdown,
        options: [
          {
            label: 'Poly-Pro',
            value: 'Poly-Pro'
          },
          {
            label: 'Co-Poly',
            value: 'Co-Poly'
          },
          {
            label: 'Carbon Plast',
            value: 'Carbon Plast'
          },
          {
            label: 'LDPE',
            value: 'LDPE'
          },
          {
            label: 'Cork',
            value: 'Cork'
          },
          {
            label: '35 Durometer EVA',
            value: '35 Durometer EVA'
          },
          {
            label: '45 Durometer EVA',
            value: '45 Durometer EVA'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.base,
        validate: zodHelpers.requiredDropdown,
        required: true
      }
    ],
    'build-your-own-diabetic-insert': [
      {
        label: 'Top Cover',
        field: 'top_cover',
        type: FormTypes.dropdown,
        options: [
          {
            label: '1/16in EVA',
            value: '1/16in EVA'
          },
          {
            label: '1/8in EVA',
            value: '1/8in EVA'
          },
          {
            label: '1/16in P-Cell',
            value: '1/16in P-Cell'
          },
          {
            label: '1/8in P-Cell',
            value: '1/8in P-Cell'
          },
          {
            label: '1/4in P-Cell',
            value: '1/4in P-Cell'
          },
          {
            label: '1/16in Fab-Fit',
            value: '1/16in Fab-Fit'
          },
          {
            label: '1/8in Fab-Fit',
            value: '1/8in Fab-Fit'
          },
          {
            label: '1/16in Blue Storm',
            value: '1/16in Blue Storm'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.top_cover,
        validate: zodHelpers.optionalText
      },
      {
        label: 'Tri Lam',
        field: 'tri_lam',
        type: FormTypes.dropdown,
        options: [
          {
            label: '5/16in Tri Lam, 1/8in P-Cell',
            value: '5/16in Tri Lam, 1/8in P-Cell'
          },
          {
            label: '5/16in Tri Lam, 1/8in Puff',
            value: '5/16in Tri Lam, 1/8in Puff'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.tri_lam,
        validate: zodHelpers.optionalText
      },
      {
        label: 'Mid Layer',
        field: 'mid_layer',
        type: FormTypes.dropdown,
        options: [
          {
            label: '1/16in Poron',
            value: '1/16in Poron'
          },
          {
            label: '1/8in Poron',
            value: '1/8in Poron'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.mid_layer,
        validate: zodHelpers.optionalText
      },
      {
        label: 'Base',
        field: 'base',
        type: FormTypes.dropdown,
        options: [
          {
            label: '35 Durometer EVA',
            value: '35 Durometer EVA'
          },
          {
            label: '45 Durometer EVA',
            value: '45 Durometer EVA'
          },
          {
            label: '55 Durometer Cork',
            value: '55 Durometer Cork'
          }
        ],
        initialValue:
          queriedFormData?.build_your_own_orthosis.build_your_own_orthosis &&
          typeof queriedFormData.build_your_own_orthosis.build_your_own_orthosis === 'object' &&
          queriedFormData.build_your_own_orthosis.build_your_own_orthosis.base,
        validate: zodHelpers.requiredDropdown,
        required: true
      }
    ],
    'shell-specs': [
      {
        label: 'Shell Length',
        field: 'shell_length',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'STD',
            value: 'STD'
          },
          {
            label: 'Sulcus',
            value: 'sulcus'
          },
          {
            label: 'Full',
            value: 'full'
          }
        ],
        initialValue:
          queriedFormData?.shell_specs.shell_specs &&
          typeof queriedFormData.shell_specs.shell_specs === 'object' &&
          queriedFormData.shell_specs.shell_specs.shell_length,
        validate: zodHelpers.optionalText
      },
      {
        label: 'Top Cover Length',
        field: 'top_cover_length',
        type: FormTypes.singleOption,
        options: [
          {
            label: 'None',
            value: 'none'
          },
          {
            label: 'Shell',
            value: 'shell'
          },
          {
            label: 'Sulcus',
            value: 'sulcus'
          },
          {
            label: 'Full',
            value: 'full'
          }
        ],
        initialValue:
          queriedFormData?.shell_specs.shell_specs &&
          typeof queriedFormData.shell_specs.shell_specs === 'object' &&
          queriedFormData.shell_specs.shell_specs.top_cover_length,
        validate: zodHelpers.optionalText
      }
    ],
    accommodations: [
      {
        field: 'accommodations',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Forefoot Posting',
            value: 'forefoot-posting'
          },
          {
            label: 'Rearfoot Posting',
            value: 'rearfoot-posting'
          },
          {
            label: 'Arch Height',
            value: 'arch-height'
          },
          {
            label: 'Heel Cup',
            value: 'heel-cup'
          },
          {
            label: 'Flange Height',
            value: 'flange-height'
          },
          {
            label: 'Heel Raise',
            value: 'heel-raise'
          },
          {
            label: 'First Cutout',
            value: 'first-cutout'
          },
          {
            label: 'Fifth Cutout',
            value: 'fifth-cutout'
          },
          {
            label: 'Filler',
            value: 'filler'
          }
        ],
        initialValue: queriedFormData?.accommodations.accommodations,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'forefoot-posting': {
      initialValue: queriedFormData?.forefoot_post,
      component: ForefootPosting
    },
    'rearfoot-posting': {
      initialValue: queriedFormData?.rearfoot_post,
      component: RearfootPosting
    },
    'arch-height': {
      initialValue: queriedFormData?.arch_height,
      component: ArchHeight
    },
    'heel-cup': {
      initialValue: queriedFormData?.heel_cup,
      component: HeelCup
    },
    'flange-height': {
      initialValue: queriedFormData?.flange_height,
      component: FlangeHeight
    },
    'heel-raise': {
      initialValue: queriedFormData?.heel_raise,
      component: HeelRaise
    },

    'first-cutout': [
      {
        field: 'first_cutout',
        label: 'First Ray Cutout',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left First Ray Cutout',
            value: 'left-first-ray-cutout'
          },
          {
            label: 'Right First Ray Cutout',
            value: 'right-first-ray-cutout'
          }
        ],
        initialValue: queriedFormData?.first_cutout.first_cutout,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'fifth-cutout': [
      {
        field: 'fifth_cutout',
        label: 'Fifth Ray Cutout',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left Fifth Ray Cutout',
            value: 'left-fifth-ray-cutout'
          },
          {
            label: 'Right Fifth Ray Cutout',
            value: 'right-fifth-ray-cutout'
          }
        ],
        initialValue: queriedFormData?.fifth_cutout.fifth_cutout,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    filler: [
      {
        field: 'filler',
        label: 'Filler',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left Partial Toe Filler',
            value: 'left-partial-toe-filler'
          },
          {
            label: 'Right Partial Toe Filler',
            value: 'right-partial-toe-filler'
          },
          {
            label: 'Left Amputee Filler',
            value: 'left-amputee-filler'
          },
          {
            label: 'Right Amputee Filler',
            value: 'right-amputee-filler'
          }
        ],
        initialValue: queriedFormData?.filler.filler,
        validate: zodHelpers.requiredMultiCheckbox
      }
    ],
    'additions-and-extensions': [
      {
        field: 'additions_and_extensions',
        label: 'Additions and Extensions',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Off Load Met Head',
            value: 'off-load-met-head'
          },
          {
            label: 'Met Pad',
            value: 'met-pad'
          },
          {
            label: 'Met Bar',
            value: 'met-bar'
          },
          {
            label: 'Dancer Pad',
            value: 'dancer-pad'
          },
          {
            label: 'Toe Crest',
            value: 'toe-crest'
          },
          {
            label: 'Cuboid Raise',
            value: 'cuboid-raise'
          },
          {
            label: 'Morton Extension',
            value: 'morton-extension'
          },
          {
            label: 'Reverse Morton Extension',
            value: 'reverse-morton-extension'
          }
        ],
        initialValue: queriedFormData?.additions_and_extensions.additions_and_extensions,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'off-load-met-head': {
      initialValue: queriedFormData?.off_load_met_head,
      component: MetHead
    },
    'met-pad': {
      initialValue: queriedFormData?.met_pad,
      component: MetPad
    },
    'met-bar': {
      initialValue: queriedFormData?.met_bar,
      component: MetBar
    },

    'dancer-pad': [
      {
        field: 'dancer_pad',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left Dancer Pad',
            value: 'left-dancer-pad'
          },
          {
            label: 'Right Dancer Pad',
            value: 'right-dancer-pad'
          }
        ],
        initialValue: queriedFormData?.dancer_pad.dancer_pad,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'toe-crest': [
      {
        field: 'toe_crest',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left Toe Crest',
            value: 'left-toe-crest'
          },
          {
            label: 'Right Toe Crest',
            value: 'right-toe-crest'
          }
        ],
        initialValue: queriedFormData?.toe_crest.toe_crest,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'cuboid-raise': [
      {
        field: 'cuboid_raise',
        type: FormTypes.multiSelectBubble,
        options: [
          {
            label: 'Left Cuboid Raise',
            value: 'left-cuboid-raise'
          },
          {
            label: 'Right Cuboid Raise',
            value: 'right-cuboid-raise'
          }
        ],
        initialValue: queriedFormData?.cuboid_raise.cuboid_raise,
        validate: zodHelpers.optionalMultiCheckbox
      }
    ],
    'morton-extension': {
      initialValue: queriedFormData?.morton_extension,
      component: MortonExtension
    },
    'reverse-morton-extension': {
      initialValue: queriedFormData?.reverse_morton_extension,
      component: ReverseMortonExtension
    },

    'order-notes': [
      {
        field: 'additional_order_notes',
        label: 'Additional Order Notes',
        type: FormTypes.textArea,
        initialValue: queriedFormData?.order_notes.additional_order_notes,
        placeholder: 'Enter Additional Order Notes',
        validate: zodHelpers.optionalText
      }
    ]
  };

  const filteredForms = Object.entries(allForms).reduce(
    (acc, [key, value]) => {
      if (selectedForms.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );

  if (isError) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to fetch order form data" mt="xs">
        An error occurred getting initial order form data for visit.
      </Alert>
    );
  }

  return (
    <HikeLoadingOverlay isLoading={isLoading}>
      <Modal
        opened={fillerModalOpen}
        onClose={() => fillerModalHandlers.close()}
        centered
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        title="Ship Impression Box to Solemate"
      >
        <Stack>
          Please ship Impression Box to 741 West Main Street, Peoria IL 61606.
          <Button onClick={() => fillerModalHandlers.close()} size="xs" fullWidth>
            I will ship Impression Box to Solemate
          </Button>
        </Stack>
      </Modal>
      <FormStep
        disabled={workflow_info?.submitted}
        showTitlesAtEachStep
        onPageSubmit={[
          {
            'patient-information': async (values) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'patient_information',
                formChanges: {
                  shoe_size: isDiffSizes
                    ? Math.max(Number(values.left_shoe_size), Number(values.right_shoe_size))
                    : values.shoe_size,
                  gender: values.gender,
                  shoe_width: values.shoe_width,
                  quantity: values.quantity,
                  clinic_name: values.clinic_name,
                  order_side: values.order_side,
                  measurement_tool: getMeasurementToolValue(
                    isMeasurementToolOther,
                    values.measurement_tool_other,
                    values.measurement_tool
                  ),
                  is_diff_size: isDiffSizes,
                  order_form_type: values.order_form_type,
                  sides_shoe_size: { left_shoe_size: values.left_shoe_size, right_shoe_size: values.right_shoe_size },
                  sides_measurement_tool: {
                    left_measurement_tool: getMeasurementToolValue(
                      isLeftMeasurementToolOther,
                      values.left_measurement_tool_other,
                      values.left_measurement_tool
                    ),
                    right_measurement_tool: getMeasurementToolValue(
                      isRightMeasurementToolOther,
                      values.right_measurement_tool_other,
                      values.right_measurement_tool
                    )
                  },
                  sides_shoe_width: {
                    left_shoe_width: values.left_shoe_width,
                    right_shoe_width: values.right_shoe_width
                  }
                }
              });

              await refetchFormData();
            },
            'billing-information': async (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'billing_information',
                formChanges: {
                  billing_name: values.billing_name,
                  billing_address: values.billing_address,
                  billing_city: values.billing_city,
                  billing_state: values.billing_state,
                  billing_zipcode: values.billing_zipcode,
                  billing_number: values.billing_number,
                  ...(values.shipping_same_as_billing && {
                    shipping_address: values.billing_address,
                    shipping_city: values.billing_city,
                    shipping_state: values.billing_state,
                    shipping_zipcode: values.billing_zipcode
                  })
                }
              }),
            'shipping-information': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'billing_information',
                formChanges: {
                  ...values
                }
              }),
            'processing-time': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'processing_time',
                formChanges: {
                  production_turnaround: values.production_turnaround,
                  shipping_time: values.shipping_time
                }
              }),
            'casting-type': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'casting_type',
                formChanges: {
                  casting_method: values.casting_method,
                  casting_type: values.casting_type
                }
              }),
            'product-type': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'product_type',
                formChanges: {
                  product_type: values.product_type
                }
              }),
            'specialty-styles': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'specialty_styles',
                formChanges: {
                  specialty_styles: {
                    ...values
                  }
                }
              }),
            'build-your-own-functional-insert': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'build_your_own_orthosis',
                formChanges: {
                  build_your_own_orthosis: {
                    ...values
                  }
                }
              }),
            'build-your-own-diabetic-insert': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'build_your_own_orthosis',
                formChanges: {
                  build_your_own_orthosis: {
                    ...values
                  }
                }
              }),
            'shell-specs': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'shell_specs',
                formChanges: {
                  shell_specs: {
                    shell_length: values.shell_length,
                    top_cover_length: values.top_cover_length
                  }
                }
              }),
            accommodations: (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'accommodations',
                formChanges: {
                  accommodations: values.accommodations
                }
              }),
            'forefoot-posting': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'forefoot_post',
                formChanges: {
                  forefoot_post: values
                }
              }),
            'rearfoot-posting': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'rearfoot_post',
                formChanges: {
                  rearfoot_post: values
                }
              }),
            'arch-height': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'arch_height',
                formChanges: {
                  arch_height: values
                }
              }),
            'heel-cup': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'heel_cup',
                formChanges: {
                  heel_cup: values
                }
              }),
            'flange-height': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'flange_height',
                formChanges: {
                  flange_height: values
                }
              }),
            'heel-raise': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'heel_raise',
                formChanges: {
                  heel_raise: values
                }
              }),
            'first-cutout': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'first_cutout',
                formChanges: {
                  first_cutout: values.first_cutout
                }
              }),
            'fifth-cutout': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'fifth_cutout',
                formChanges: {
                  fifth_cutout: values.fifth_cutout
                }
              }),
            filler: async (values) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'filler',
                formChanges: {
                  filler: values.filler
                }
              });
              await mutateShipImpressionBox({ visitId: visit_id as string });
              fillerModalHandlers.open();
            },
            'additions-and-extensions': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'additions_and_extensions',
                formChanges: {
                  additions_and_extensions: values.additions_and_extensions
                }
              }),
            'off-load-met-head': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'off_load_met_head',
                formChanges: {
                  off_load_met_head: values
                }
              }),
            'met-pad': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'met_pad',
                formChanges: {
                  met_pad: values
                }
              }),
            'met-bar': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'met_bar',
                formChanges: {
                  met_bar: values
                }
              }),
            'dancer-pad': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'dancer_pad',
                formChanges: {
                  dancer_pad: values.dancer_pad
                }
              }),
            'toe-crest': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'toe_crest',
                formChanges: {
                  toe_crest: values.toe_crest
                }
              }),
            'cuboid-raise': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'cuboid_raise',
                formChanges: {
                  cuboid_raise: values.cuboid_raise
                }
              }),
            'morton-extension': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'morton_extension',
                formChanges: {
                  morton_extension: values
                }
              }),
            'reverse-morton-extension': (values) =>
              mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'reverse_morton_extension',
                formChanges: {
                  reverse_morton_extension: values
                }
              }),
            'order-notes': async (values) => {
              await mutateFormPage({
                visitID: visit_id,
                formTitle,
                pageTitle: 'order_notes',
                formChanges: {
                  additional_order_notes: values.additional_order_notes
                }
              });

              navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
            }
          }
        ]}
        onFormInputChange={[
          {
            'patient-information': async (values) => {
              setIsDiffSizes(values.is_diff_size === 'Yes');
              setIsMeasurementToolOther(values.measurement_tool === 'Other');
              setIsLeftMeasurementToolOther(values.left_measurement_tool === 'Other');
              setIsRightMeasurementToolOther(values.right_measurement_tool === 'Other');
            },
            'billing-information': async (values) => {
              if (values.shipping_same_as_billing === 'Shipping Address Same as Billing') {
                setSelectedForms((prev) => prev.filter((form) => form !== 'shipping-information'));
              } else {
                setSelectedForms((prev) => [...prev, 'shipping-information']);
              }
            },

            'product-type': async (values) => {
              setProductTypeDescription(getDescriptionFromProductType(values.product_type));
              if (values.product_type === 'SM Specialty Styles') {
                setSelectedForms((prev) => [...prev, 'specialty-styles']);
              } else {
                setSelectedForms((prev) => prev.filter((form) => form !== 'specialty-styles'));
              }
              if (values.product_type === 'Build Your Own Functional Insert') {
                setSelectedForms((prev) => [...prev, 'build-your-own-functional-insert']);
              } else {
                setSelectedForms((prev) => prev.filter((form) => form !== 'build-your-own-functional-insert'));
              }
              if (values.product_type === 'Build Your Own Diabetic Insert') {
                setSelectedForms((prev) => [...prev, 'build-your-own-diabetic-insert']);
              } else {
                setSelectedForms((prev) => prev.filter((form) => form !== 'build-your-own-diabetic-insert'));
              }
            },

            accommodations: async (values: { accommodations: string[] }) => {
              setSelectedForms((prev: string[]) => {
                const finalForms = [
                  ...prev.filter((item) => !ACCOMMODATION_LIST.includes(kebabCase(item))),
                  ...values.accommodations.map((accomodation) => kebabCase(accomodation))
                ];
                return finalForms;
              });
            },

            'additions-and-extensions': async (values: { additions_and_extensions: string[] }) => {
              setSelectedForms((prev: string[]) => {
                const finalForms = [
                  ...prev.filter((item) => !EXTENSIONS_LIST.includes(kebabCase(item))),
                  ...values.additions_and_extensions.map((extension) => kebabCase(extension))
                ];
                return finalForms;
              });
            }
          }
        ]}
        title="Order Form"
        fields={filteredForms}
        buttonProps={{
          loading: submitFormPageLoading
        }}
      />
    </HikeLoadingOverlay>
  );
}
