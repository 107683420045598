import { ActionIcon, Group, Text } from '@mantine/core';
import { IconThumbUp, IconX } from '@tabler/icons-react';

interface PreCheckQuestionProps {
  title: string;
  onNoClick: () => void;
  onYesClick: () => void;
}

export default function PreCheckQuestion({ title, onNoClick, onYesClick }: PreCheckQuestionProps) {
  return (
    <>
      <Text size="xl" fw="bold">
        {title}
      </Text>
      <Group justify="space-between">
        <ActionIcon onClick={onNoClick} color="red" variant="filled" radius="xl" size="xl">
          <IconX />
        </ActionIcon>
        <ActionIcon onClick={onYesClick} color="hikeGreen" variant="filled" radius="xl" size="xl">
          <IconThumbUp />
        </ActionIcon>
      </Group>
    </>
  );
}
