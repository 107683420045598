import { FeetDirections } from '@/pages/HikeScan/HikeScan';
import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { MULTI_PART_CONSTANTS } from '@/utils/constants';
import { startMultipartUpload } from '@/utils/multiUploader';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

interface MultiPartPostInput {
  previewName: string;
  videoBlob: Blob;
  visitId: string;
}
interface MultiPartUploadPostInput {
  previewName: string;
  videoFile: File | null;
  visitId: string;
  isMovFile: boolean;
}
interface ScanImpressionBoxPostInput {
  visitId: string;
}

interface UploadScansPostInput {
  left_preview_name?: string;
  right_preview_name?: string;
  is_left_mov?: boolean;
  is_right_mov?: boolean;
  visit_id: string;
}

interface UploadScansPostResponse {
  message: string;
}

export const useUploadPreviewToS3MultiPart = (
  options?: Omit<UseMutationOptions<void, ApiError, MultiPartPostInput>, 'mutationFn'>
) =>
  useMutation<void, ApiError, MultiPartPostInput>({
    mutationFn: async ({ previewName, videoBlob, visitId }: MultiPartPostInput) => {
      const videoFile = new File([videoBlob], 'uploaded_video.mp4', {
        type: 'video/mp4'
      });

      const keyName = `${MULTI_PART_CONSTANTS.MULTIPART_UPLOAD_PREFIX}/${previewName}.mp4`;
      return startMultipartUpload({ fileName: keyName, file: videoFile, visitId });
    },
    ...options
  });

interface OrderInfo {
  added_on_at: Date | null;
  apt_number: string | null;
  authorized_at: Date | null;
  authorized_by: string | null;
  city: string;
  clinical_order_id: number;
  deduction_date: Date | null;
  finished_at: Date | null;
  gender: 'male' | 'female' | 'other';
  glued_at: Date | null;
  grinded_at: Date | null;
  height: number | null;
  in_deduction: boolean | null;
  invoice_number: string | null;
  invoice_paid: boolean | null;
  invoice_pdf: string | null;
  is_diabetic: boolean;
  left_pain_map: string | null;
  left_scan: string | null;
  left_scan_path: string | null;
  left_status: string | null;
  order_date: string; // or Date
  order_id: number;
  order_retries: number;
  order_status: string;
  order_submitted: Date | null;
  ponumber: string | null;
  profile: string;
  quantity: string;
  render_type: number | null;
  right_pain_map: string | null;
  right_scan: string | null;
  right_scan_path: string | null;
  right_status: string | null;
  shipped_at: Date | null;
  shipstation_id: number | null;
  shoe_brand: string | null;
  shoe_size: string;
  shoe_width: string | null;
  state: string;
  street_address: string;
  stripe: boolean;
  taika_id: number | null;
  total_billed: number;
  user_id: number;
  version: number;
  weight: number;
  zipcode: string;
}

interface OrderJSON {
  order_info: OrderInfo;
  status: string;
}

export const useGetOrderInformation = (
  visitId: string | number,
  options?: Omit<UseQueryOptions<OrderJSON, ApiError>, 'queryFn' | 'queryKey'>
) =>
  useQuery<OrderJSON, ApiError>({
    queryKey: ['orderInformation', visitId],
    queryFn: async () => {
      const { data } = await hikeApi.get<OrderJSON>(`/v2/get_order_information/${visitId}`, {
        method: 'GET'
      });
      return data;
    },
    ...options
  });

interface MultiPartVideoSendInput {
  visitId: string | number;
  preview: string;
  foot: FeetDirections;
  rescan: string;
}

interface MultiPartVideoSendResponse {
  message: string;
}

export const useMultiPartVideoSend = (
  options?: Omit<UseMutationOptions<MultiPartVideoSendResponse, ApiError, MultiPartVideoSendInput>, 'mutationFn'>
) =>
  useMutation<MultiPartVideoSendResponse, ApiError, MultiPartVideoSendInput>({
    mutationFn: async ({ visitId, preview, foot, rescan }: MultiPartVideoSendInput) => {
      const now = Date.now();
      const key = `${MULTI_PART_CONSTANTS.MULTIPART_UPLOAD_PREFIX}/${preview.split('/').pop()}.mp4`;
      const isAndroid = /Android/i.test(navigator.userAgent);

      const { data } = await hikeApi.post<MultiPartVideoSendResponse>(`/v2/multipart_video_send/${visitId}`, {
        bucket: MULTI_PART_CONSTANTS.BUCKET_NAME,
        key,
        foot,
        rescan,
        isAndroid
      });
      console.log('multipart latency in seconds ', (Date.now() - now) / 1000);
      return data;
    },
    ...options
  });

export const useScanImpressionBox = (
  options?: Omit<UseMutationOptions<MultiPartVideoSendResponse, ApiError, ScanImpressionBoxPostInput>, 'mutationFn'>
) =>
  useMutation<MultiPartVideoSendResponse, ApiError, ScanImpressionBoxPostInput>({
    mutationFn: async ({ visitId }: ScanImpressionBoxPostInput) => {
      const { data } = await hikeApi.post<MultiPartVideoSendResponse>('/v2/skip_scanning_process', {
        visit_id: visitId
      });
      return data;
    },
    ...options
  });

export const useUploadScans = (
  options?: Omit<UseMutationOptions<UploadScansPostResponse, ApiError, UploadScansPostInput>, 'mutationFn'>
) =>
  useMutation<UploadScansPostResponse, ApiError, UploadScansPostInput>({
    mutationFn: async ({
      left_preview_name,
      right_preview_name,
      is_left_mov,
      is_right_mov,
      visit_id
    }: UploadScansPostInput) => {
      const { data } = await hikeApi.post<UploadScansPostResponse>(`v2/upload_video/${visit_id}`, {
        left_preview_name,
        right_preview_name,
        is_left_mov,
        is_right_mov
      });
      return data;
    },
    ...options
  });

export const useUploadScansToS3MultiPart = (
  options?: Omit<UseMutationOptions<void, ApiError, MultiPartUploadPostInput>, 'mutationFn'>
) =>
  useMutation<void, ApiError, MultiPartUploadPostInput>({
    mutationFn: async ({ previewName, videoFile, visitId, isMovFile }: MultiPartUploadPostInput) => {
      let keyName = '';
      if (isMovFile) {
        keyName = `${MULTI_PART_CONSTANTS.MULTIPART_UPLOAD_PREFIX}/${previewName}.MOV`;
      } else {
        keyName = `${MULTI_PART_CONSTANTS.MULTIPART_UPLOAD_PREFIX}/${previewName}.mp4`;
      }
      return startMultipartUpload({ fileName: keyName, file: videoFile, visitId });
    },
    ...options
  });
