import css from '@/pages/HikeScan/FootEval.module.css';
import { FootEvalContext, PRECHECK_VIEWS } from '@/pages/HikeScan/HikeScan';
import PreCheck from '@/pages/HikeScan/PreCheck/PreCheck';
import { Badge, Box, Button, Group, Stack, Text, Title } from '@mantine/core';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';

export default function PreCheckReport() {
  const {
    precheckView,
    precheckConditions,
    shouldRescanPatient,
    setResults,
    footSelected,
    results,
    resetScan,
    setPrecheckView
  } = useContext(FootEvalContext);

  const rescanHandler = () => {
    resetScan();
    setPrecheckView(PRECHECK_VIEWS.FOOT_SELECTOR);
  };

  /**
   * If user was scanning a foot but the pre-check failed,
   * set the recording status of selected foot to false
   */
  useEffect(() => {
    if (shouldRescanPatient) {
      if (footSelected === 'left') {
        setResults({
          leftFootScanRecording: false
        });
      } else if (footSelected === 'right') {
        setResults({
          rightFootScanRecording: false
        });
      }
    }
  }, [shouldRescanPatient]);

  const isRecording = results.leftFootScanRecording || results.rightFootScanRecording;
  const isPreviewing = results.leftFootScanPreviewing || results.rightFootScanPreviewing;

  if (isRecording || !isPreviewing) {
    return null;
  }

  return (
    <Box className={css.overlay}>
      <Box
        py="xl"
        className={clsx({
          [css.overlayContainer as string]: true,
          [css.videoProcessingContainer as string]: precheckView === PRECHECK_VIEWS.REPORT && !shouldRescanPatient
        })}
      >
        <Stack align="center">
          <PreCheck />
          {precheckView === PRECHECK_VIEWS.REPORT && (
            <>
              {shouldRescanPatient && (
                <Stack ta="center">
                  <Title order={3}>Please rescan the patient</Title>
                  <Text>Make sure to check for</Text>
                  <Group justify="center">
                    {Object.entries(precheckConditions).map(([condition, isMet]) => {
                      if (isMet) return null;
                      return (
                        <Badge size="lg" fw="normal" key={condition} color="dark">
                          {condition}
                        </Badge>
                      );
                    })}
                  </Group>

                  <Button mt="lg" size="xl" fullWidth onClick={rescanHandler}>
                    Rescan
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
