import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import { useGetClinicalMaps } from '@/api/painMap';
import SelectedFootView from '@/pages/PainMap/SelectedFootView';
import { ROUTES } from '@/Router';
import { Alert, Button, Chip, Group, Image, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import { useMemo } from 'react';
import { generatePath, Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export type PainMapFoot = 'left-symptom-map' | 'right-symptom-map';

export default function PainMap() {
  const theme = useMantineTheme();
  const location = useLocation();
  const { direction, visit_id } = useParams() as { direction?: PainMapFoot; visit_id: string };
  const navigate = useNavigate();
  const [activeFoot] = direction?.split('-') || [];
  const [searchParams] = useSearchParams();
  const selectedIssue = searchParams.get('issue');

  const { data: workflow_info } = useGetWorkflowDashboard(visit_id, {});

  const selectFoot = (selectedFootDirection: PainMapFoot) => {
    if (direction) {
      const pathname = location.pathname.replace(direction, selectedFootDirection);
      navigate(pathname);
    } else {
      navigate(`${location.pathname.replace(/\/+$/, '')}/${selectedFootDirection}`);
    }
  };

  const { data: clinicalMaps, isError: isErrorClinicalMaps } = useGetClinicalMaps(visit_id!);

  const backButton = () => {
    if (selectedIssue) {
      return null;
    }

    if (activeFoot) {
      return (
        <Button
          aria-label="Back to Foot Selector"
          onClick={() => navigate(location.pathname.replace(direction!, ''), { replace: true })}
          variant="light"
        >
          Back to foot selector
        </Button>
      );
    }

    return (
      <Button
        aria-label="Back to Dashboard"
        component={Link}
        to={generatePath(ROUTES.CLINICAL_WORKFLOW)}
        variant="light"
      >
        Back to Dashboard
      </Button>
    );
  };

  const renderCheckMark = (selectedFootDirection: PainMapFoot) => {
    const isSkipped =
      selectedFootDirection === 'left-symptom-map' ? clinicalMaps?.left_map_skip : clinicalMaps?.right_map_skip;
    const hasPainMap = selectedFootDirection === 'left-symptom-map' ? hasLeftFootPainMap : hasRightFootPainMap;

    if (isSkipped) {
      return (
        <Chip defaultChecked color="green">
          Skipped
        </Chip>
      );
    }
    if (hasPainMap) {
      return <IconCircleCheck size={24} color={theme.colors.green[8]} />;
    }

    return <></>;
  };
  const hasLeftFootPainMap = useMemo(() => {
    if (!clinicalMaps) {
      return false;
    }

    return clinicalMaps.left_map_complete;
  }, [clinicalMaps]);

  const hasRightFootPainMap = useMemo(() => {
    if (!clinicalMaps) {
      return false;
    }

    return clinicalMaps.right_map_complete;
  }, [clinicalMaps]);

  if (isErrorClinicalMaps) {
    return (
      <Stack>
        {backButton()}
        <Alert color="red">Error loading clinical maps</Alert>
      </Stack>
    );
  }

  if (activeFoot) {
    return (
      <>
        {backButton()}
        <SelectedFootView disabled={workflow_info?.submitted} />
      </>
    );
  }

  return (
    <>
      {backButton()}
      <Stack mt="lg">
        <Button
          h={200}
          variant="outline"
          c="dark"
          justify="space-between"
          px="lg"
          onClick={() => selectFoot('left-symptom-map')}
          leftSection={<Image src="/left-foot.svg" w={rem(80)} alt="Left Foot" />}
        >
          <Group>
            <Text size="lg">Left Foot</Text>
            {renderCheckMark('left-symptom-map')}
          </Group>
        </Button>
        <Button
          h={200}
          variant="outline"
          c="dark"
          justify="space-between"
          px="lg"
          onClick={() => selectFoot('right-symptom-map')}
          rightSection={<Image src="/right-foot.svg" w={rem(80)} alt="Right Foot" />}
        >
          <Group>
            <Text size="lg">Right Foot</Text>
            {renderCheckMark('right-symptom-map')}
          </Group>
        </Button>
      </Stack>
    </>
  );
}
