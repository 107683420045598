import { OrderStatuses } from '@/types/order-status';
import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export interface UseEvaluationResponse {
  clinician_evaluations: Evaluation[];
  other_evaluations: Evaluation[];
}

export interface EvaluationCountResponse {
  incomplete_evaluation_count: number;
  complete_evaluation_count: number;
}

export interface Evaluation {
  user_id: number;
  patient_id: number;
  name: string;
  is_diabetic: boolean;
  po_number: string;
  order_status: OrderStatuses;
  start_time: Date;
  order_date: Date;
  end_time: Date;
  order_submitted: Date;
  visit_id: number;
  attach_scans: string;
  is_v1: boolean;
  clinician_name: string;
}
interface useResetPatientInput {
  visit_id: string;
}
interface useImportSTLFilesInput {
  left_stl: File | null;
  right_stl: File | null;
  order_id: string;
}

interface useImportSTLFilesResponse {
  message: string;
}
interface useStartEvalutionInput {
  visitId: string;
}

interface useMigratePatientInput {
  user_id: string;
}

interface useStartEvalutionResponse {
  message: string;
}

interface useManuallyStartVisitInput {
  patientName: string;
  patient_ID: string;
  date_of_birth: Date;
}

interface useManuallyStartVisitResponse {
  message: string;
  visit_id: number;
}

interface useMigratePatientResponse {
  message: string;
  visit_id: number;
}

export const useGetCompletedEvaluationsForClinician = (
  options?: Omit<UseQueryOptions<UseEvaluationResponse, ApiError>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['completed-evaluations-for-clinician'],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseEvaluationResponse>('/v2/evaluations/clinician', {
        params: { status: 'completed' }
      });
      return data;
    },
    ...options
  });

export const useGetIncompleteEvaluationsForClinician = (
  options?: Omit<UseQueryOptions<UseEvaluationResponse, ApiError>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['incomplete-evaluations-for-clinician'],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseEvaluationResponse>('/v2/evaluations/clinician', {
        params: { status: 'incomplete' }
      });
      return data;
    },
    ...options
  });

export const useGetEvaluationsForClinicianBySearch = (
  searchBy: string | null,
  searchQuery: string | number,
  filter: string | null,
  options?: Omit<UseQueryOptions<UseEvaluationResponse, Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['search-evaluations-for-clinician', searchBy, searchQuery, filter],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseEvaluationResponse>('/v2/evaluations/clinician', {
        params: { searchBy, searchQuery, searchAll: true, status: filter }
      });

      return data;
    },
    ...options
  });

export const useGetEvaluationCountOnSearch = (
  searchBy: string | null,
  searchQuery: string | number,
  options?: Omit<UseQueryOptions<EvaluationCountResponse, Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['search-evaluations-for-clinician-count', searchBy, searchQuery],
    queryFn: async () => {
      const { data } = await hikeApi.get<EvaluationCountResponse>('/v2/evaluations/clinician/count', {
        params: { searchBy, searchQuery }
      });

      return data;
    },
    ...options
  });

export const useGetEvaluationsBySearch = (
  name: string,
  patientID: number | string,
  options?: Omit<UseQueryOptions<Evaluation[], Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['search-evaluations', name, patientID],
    queryFn: async () => {
      const { data } = await hikeApi.get<Evaluation[]>('/v2/evaluations/new', {
        params: { name, patientID }
      });
      return data;
    },
    ...options
  });

export const useStartEvaluation = (
  options?: Omit<UseMutationOptions<useStartEvalutionResponse, ApiError, useStartEvalutionInput>, 'mutationFn'>
) =>
  useMutation<useStartEvalutionResponse, ApiError, useStartEvalutionInput>({
    mutationFn: async ({ visitId }: useStartEvalutionInput) => {
      const { data } = await hikeApi.post<useStartEvalutionResponse>('/v2/start_clinical_visit', {
        visit_id: visitId
      });
      return data;
    },
    ...options
  });

export const useManuallyStartVisit = (
  options?: Omit<UseMutationOptions<useManuallyStartVisitResponse, ApiError, useManuallyStartVisitInput>, 'mutationFn'>
) =>
  useMutation<useManuallyStartVisitResponse, ApiError, useManuallyStartVisitInput>({
    mutationFn: async ({ patientName, patient_ID, date_of_birth }: useManuallyStartVisitInput) => {
      const { data } = await hikeApi.post<useManuallyStartVisitResponse>('/v2/add_clinical_patient', {
        name: patientName,
        patient_id: patient_ID,
        date_of_birth
      });
      return data;
    },
    ...options
  });

export const useMigratePatient = (
  options?: Omit<UseMutationOptions<useMigratePatientResponse, ApiError, useMigratePatientInput>, 'mutationFn'>
) =>
  useMutation<useMigratePatientResponse, ApiError, useMigratePatientInput>({
    mutationFn: async ({ user_id }: useMigratePatientInput) => {
      const { data } = await hikeApi.post<useMigratePatientResponse>('/v2/migrate_patient', {
        user_id
      });
      return data;
    },
    ...options
  });

export const useImportSTLFiles = (
  options?: Omit<UseMutationOptions<useImportSTLFilesResponse, ApiError, useImportSTLFilesInput>, 'mutationFn'>
) =>
  useMutation<useImportSTLFilesResponse, ApiError, useImportSTLFilesInput>({
    mutationFn: async ({ left_stl, right_stl, order_id }: useImportSTLFilesInput) => {
      const formData = new FormData();
      if (left_stl) {
        formData.append('left_stl', left_stl);
      }
      if (right_stl) {
        formData.append('right_stl', right_stl);
      }
      formData.append('order_id', order_id);
      const { data } = await hikeApi.post<useImportSTLFilesResponse>('import_stl_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    },
    ...options
  });

export const useResetDemoPatient = (mutationOptions?: UseMutationOptions<void, ApiError, useResetPatientInput>) =>
  useMutation({
    mutationFn: async ({ visit_id }: useResetPatientInput) => {
      const { data } = await hikeApi.post<void>('v2/demo/reset_patient', {
        visit_id
      });
      return data;
    },
    ...mutationOptions
  });
