import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface ClinicalMapResponse {
  [key: string]: string;
}
export const useGetClinicalMaps = (
  visitID: number | string,
  options?: Omit<UseQueryOptions<ClinicalMapResponse>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['clinical-maps', visitID],
    queryFn: async () => {
      const { data } = await hikeApi.get<ClinicalMapResponse>(`/v2/get_all_clinical_maps/${visitID}`);
      return data;
    },
    ...options
  });

interface ClinicalMapInput {
  clinicalMapData: string;
  visitId: string;
  clinicalMapAttribute: string;
}

interface ClinicalMapSkipInput {
  foot: string;
  visitId: string;
}

export const useSubmitClinicalMap = (
  options?: Omit<UseMutationOptions<ClinicalMapResponse, ApiError, ClinicalMapInput>, 'mutationFn'>
) =>
  useMutation<ClinicalMapResponse, ApiError, ClinicalMapInput>({
    mutationFn: async ({ clinicalMapData, visitId, clinicalMapAttribute }: ClinicalMapInput) => {
      const { data } = await hikeApi.post<ClinicalMapResponse>(
        `/v2/submit_clinical_map/${visitId}/${clinicalMapAttribute}`,
        { clinical_map: clinicalMapData }
      );
      return data;
    },
    ...options
  });

export const useSkipClinicalMap = (
  options?: Omit<UseMutationOptions<ClinicalMapResponse, ApiError, ClinicalMapSkipInput>, 'mutationFn'>
) =>
  useMutation<ClinicalMapResponse, ApiError, ClinicalMapSkipInput>({
    mutationFn: async ({ foot, visitId }: ClinicalMapSkipInput) => {
      const { data } = await hikeApi.post<ClinicalMapResponse>(`/v2/skip_clinical_map/${visitId}`, {
        foot
      });
      return data;
    },
    ...options
  });
