import { ROUTES } from '@/Router';
import { useImportSTLFiles, useMigratePatient, useResetDemoPatient, useStartEvaluation } from '@/api/evaluations';
import { ClinicalPortalTabs } from '@/pages/ClinicianPortal/ClinicianPortal';
import { OrderStatuses, getGenericStatusFromOrderStatus } from '@/types/order-status';
import { queryClient } from '@/utils/queryClient';
import { Alert, Button, Divider, FileInput, Group, Modal, Paper, Pill, Stack, Text } from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash';
import { generatePath, useNavigate } from 'react-router-dom';
import css from './FormItem.module.css';

export interface FormItemProps {
  type: 'COMPLETED' | 'INCOMPLETE' | 'NOT STARTED';
  user_id: number;
  patientID?: number;
  patientName: string;
  isDiabetic: boolean;
  poNumber?: string | number;
  orderStatus?: OrderStatuses;
  completedDate?: Date | string;
  startedDate?: Date | string;
  visit_id: number;
  attach_scans: string;
  isV1: boolean;
  allowEdit: boolean;
  clinician_name: string;
  openNewClinicalRedirectModal?: () => void;
}

export default function FormItem({
  type,
  patientID,
  patientName,
  isDiabetic,
  poNumber,
  orderStatus,
  completedDate,
  startedDate,
  visit_id,
  user_id,
  attach_scans,
  isV1,
  allowEdit,
  clinician_name,
  openNewClinicalRedirectModal
}: FormItemProps) {
  const navigate = useNavigate();
  const [leftFile, setLeftFile] = useInputState<File | null>(null);
  const [rightFile, setRightFile] = useInputState<File | null>(null);
  const handleVisitRouting = (visitId?: string | number) => {
    if (visitId) {
      sessionStorage.setItem('visitId', visitId.toString());
      navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
      return;
    }

    sessionStorage.setItem('visitId', visit_id.toString());
    navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
  };
  const [attachScansOpen, attachScansHandlers] = useDisclosure(false);
  const {
    mutate: mutateStartEvaluation,
    isPending: startEvalLoading,
    error: startEvalError
  } = useStartEvaluation({
    onSuccess: (_data, variables) => {
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      handleVisitRouting(variables.visitId);
    },
    onError: (data) => {
      if (data.data.sunset_order_creation) {
        openNewClinicalRedirectModal && openNewClinicalRedirectModal();
      }
    }
  });

  const { mutate: mutateResetDemoPatient, isPending: resetDemoPatientLoading } = useResetDemoPatient({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      queryClient.resetQueries({ queryKey: ['initial-form-data', 'patient-health', visit_id.toString()] });
      queryClient.resetQueries({ queryKey: ['initial-form-data', 'evaluation', visit_id.toString()] });
      queryClient.resetQueries({ queryKey: ['initial-form-data', 'hike-scan', visit_id.toString()] });
      queryClient.resetQueries({ queryKey: ['initial-form-data', 'pain-map', visit_id.toString()] });
      queryClient.resetQueries({ queryKey: ['initial-form-data', 'order-form', visit_id.toString()] });
      queryClient.resetQueries({ queryKey: ['workflow-dashboard', visit_id.toString()] });

      notifications.show({
        title: 'Complete',
        message: 'This Demo Patient was successfully reset',
        color: 'green'
      });
      navigate(
        generatePath(ROUTES.PORTAL, {
          tabValue: ClinicalPortalTabs.Incomplete
        })
      );
    }
  });

  const { mutate: mutateImportStlFile, isPending: importStlFileLoading } = useImportSTLFiles({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['completed-evaluations-for-clinician'] });
      queryClient.refetchQueries({ queryKey: ['incomplete-evaluations-for-clinician'] });
      attachScansHandlers.close();
      notifications.show({
        title: 'Complete',
        message: 'Your Impression Box Scan was successfully uploaded',
        color: 'green'
      });
    }
  });

  const {
    mutate: mutateMigratePatient,
    isPending: migratePatientLoading,
    error: migratePatientError
  } = useMigratePatient({
    onSuccess: ({ visit_id: visitID }) => {
      mutateStartEvaluation({ visitId: visitID.toString() });
    }
  });

  const handleStartVisit = () => {
    if (visit_id) {
      mutateStartEvaluation({ visitId: visit_id.toString() });
    } else {
      mutateMigratePatient({ user_id: user_id.toString() });
    }
  };

  if (type === 'COMPLETED') {
    return (
      <Paper data-automation-id="completed" className={css.ticket} shadow="none" bg="hike.1">
        <Group w="100%" justify="space-between">
          <Modal
            opened={attachScansOpen}
            onClose={() => attachScansHandlers.close()}
            centered
            title="Attach Your Impression Box Scans Here"
          >
            <Stack>
              <FileInput
                accept=".stl, .obj, .ply"
                autoFocus
                label="Attach Left Impression Box Scan"
                clearable
                placeholder="Upload Left File"
                onChange={setLeftFile}
              />
              <FileInput
                accept=".stl, .obj, .ply"
                label="Attach Right Impression Box Scan"
                clearable
                placeholder="Upload Right File"
                onChange={setRightFile}
              />

              <Button
                fullWidth
                disabled={!leftFile && !rightFile}
                loading={importStlFileLoading}
                onClick={() =>
                  mutateImportStlFile({
                    left_stl: leftFile,
                    right_stl: rightFile,
                    order_id: attach_scans
                  })
                }
              >
                Submit Impression Box Scans
              </Button>
            </Stack>
          </Modal>
          <Stack gap="1">
            {isV1 && (
              <Pill size="sm" w="max-content" bg="#fae37f" c="black">
                OLD PLATFORM
              </Pill>
            )}
            {!!attach_scans && <IconExclamationCircle color="red" />}
            <Text size="lg" fw={500} truncate="end" lineClamp={1}>
              {patientName || '-'}
            </Text>
            <Text size="sm">Patient ID: {patientID || '-'}</Text>
            <Text size="sm">PO: {poNumber || '-'}</Text>
            <Text size="sm">Clinician: {clinician_name || '-'}</Text>
            <Text size="sm">{isDiabetic ? 'Diabetic' : 'Not Diabetic'}</Text>
          </Stack>
          <Group>
            {!!attach_scans && (
              <Button
                data-automation-id="attach-scans-button"
                variant="light"
                color="red"
                size="sm"
                onClick={() => attachScansHandlers.open()}
              >
                Attach Impression Box Scans
              </Button>
            )}
            {!isV1 && (
              <Button
                data-automation-id="view-button"
                variant="filled"
                bg="hikeGreen.2"
                c="dark"
                onClick={() => handleVisitRouting()}
              >
                View
              </Button>
            )}
            {orderStatus === 'Demo Submission' && (
              <Button
                data-automation-id="view-button"
                variant="filled"
                color="#cc99ff"
                c="dark"
                loading={resetDemoPatientLoading}
                onClick={() => mutateResetDemoPatient({ visit_id: visit_id.toString() })}
              >
                Reset Patient
              </Button>
            )}
          </Group>
        </Group>
        <Group mt="sm" gap="xs">
          {!attach_scans && orderStatus && (
            <Pill
              data-automation-id={`status-${kebabCase(getGenericStatusFromOrderStatus(orderStatus))}`}
              size="md"
              bg="hike.4"
              c="white"
            >
              Status: {getGenericStatusFromOrderStatus(orderStatus)}
            </Pill>
          )}
        </Group>
        <Divider my="sm" color="hike.2" variant="solid" />
        <Text size="sm" fw="bold" c="dark">
          Completed on: {typeof completedDate !== 'undefined' ? dayjs(completedDate).format('MM/DD/YYYY') : '-'}
        </Text>
      </Paper>
    );
  }

  if (type === 'INCOMPLETE') {
    return (
      <Paper data-automation-id="incomplete" className={css.ticket} shadow="none" bg="#124C74" c="white">
        <Group w="100%" justify="space-between">
          <Stack gap="1">
            <Text size="lg" fw={500} truncate="end" lineClamp={1}>
              {patientName || '-'}
            </Text>
            <Text size="sm">Patient ID: {patientID || '-'}</Text>
            <Text size="sm">Clinician: {clinician_name || '-'}</Text>
            <Text size="sm">{isDiabetic ? 'Diabetic' : 'Not Diabetic'}</Text>
          </Stack>
          <Button
            data-automation-id="resume-button"
            variant="filled"
            bg="hikeGreen.2"
            c="dark"
            onClick={() => handleVisitRouting()}
            loading={migratePatientLoading || startEvalLoading}
            disabled={!allowEdit}
          >
            {allowEdit ? 'Resume' : 'Coming Soon...'}
          </Button>
        </Group>
        <Divider my="sm" color="dark.9" variant="solid" />
        <Text size="sm" fw="bold" c="white">
          Started on: {startedDate ? dayjs(startedDate).format('MM/DD/YYYY') : '-'}
        </Text>
      </Paper>
    );
  }

  const migrateOrStartEvalError = migratePatientError || startEvalError;
  const isMigrateOrStartEvalLoading = migratePatientLoading || startEvalLoading;

  return (
    <Paper
      data-automation-id="new-evaluation"
      className={`${css.newevalticket} ${css.failedtostart}`}
      bg="#124C74"
      c="white"
      styles={{
        root: {
          border: migrateOrStartEvalError ? '1.5px solid #BA1A1A' : 'transparent'
        }
      }}
    >
      <Stack>
        <Group w="100%" justify="space-between">
          <Stack gap="1">
            <Text size="lg" fw={500} truncate="end" lineClamp={1}>
              {patientName || '-'}
            </Text>
            <Text size="md" fw={500} truncate="end" lineClamp={1}>
              Patient ID: {patientID || '-'}
            </Text>
            <Text size="sm">{isDiabetic ? 'Diabetic' : 'Not Diabetic'}</Text>
          </Stack>
          <Button
            variant="filled"
            bg="hikeGreen.2"
            c="dark"
            onClick={handleStartVisit}
            loading={isMigrateOrStartEvalLoading}
            loaderProps={{ color: '#001D36' }}
          >
            Start
          </Button>
        </Group>
        {!!migrateOrStartEvalError && (
          <Alert
            variant="filled"
            color="rgba(186, 26, 26, 0.7)"
            styles={{
              title: {
                color: 'white'
              },
              message: {
                color: 'white'
              }
            }}
            title="Failed to Start Evaluation"
            icon={<IconExclamationCircle />}
            mt="xs"
            mb="xs"
          >
            An error occurred: {migrateOrStartEvalError.error}
          </Alert>
        )}
      </Stack>
    </Paper>
  );
}
