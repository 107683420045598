import FootCanvas from '@/pages/PainMap/Canvas';
import FootIssueSelector, { FootIssues } from '@/pages/PainMap/FootIssueSelector';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PainMapProps } from './painmap.type';

export default function SelectedFootView({ disabled }: PainMapProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIssue = searchParams.get('issue');

  useEffect(() => {
    // if selectedIssue does not equal any of the issues, then redirect to the pain map
    if (selectedIssue && !FootIssues.includes(selectedIssue)) {
      setSearchParams({ ...searchParams, issue: FootIssues[0] });
    }
  }, [selectedIssue]);

  if (searchParams.get('issue')) {
    return <FootCanvas disabled={disabled} />;
  }

  return (
    <>
      <FootIssueSelector disabled={disabled} />
    </>
  );
}
