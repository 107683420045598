import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function MetPad({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_met_pad_size`]: [
          {
            field: `${footDirection}_met_pad_size`,
            label: `${startCase(footDirection)} Met Pad Size`,
            type: FormTypes.checkbox,
            initialValue: initialValues.met_pad?.[`${footDirection}_met_pad_size`],
            options: [
              {
                label: 'Small',
                value: 'small'
              },
              {
                label: 'Medium',
                value: 'medium'
              },
              {
                label: 'Large',
                value: 'large'
              }
            ],
            validate: zodHelpers.optionalText
          },
          {
            field: `${footDirection}_met_pad_thickness`,
            label: `${startCase(footDirection)} Met Pad Thickness`,
            type: FormTypes.checkbox,
            initialValue: initialValues.met_pad?.[`${footDirection}_met_pad_thickness`],
            options: [
              {
                label: 'Low (1/8in)',
                value: 'low'
              },
              {
                label: 'Medium (3/16in)',
                value: 'medium'
              },
              {
                label: 'High (1/4in)',
                value: 'high'
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
