import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

interface MedicareInputFile {
  fileInput: File[];
}

interface MedicareFileResponse {
  file_ids: string[];
}

interface MedicareStartThreadInput {
  file_ids: string[];
  input: string;
}

interface MedicareStartThreadResponse {
  thread_id: string;
  last_message: string;
}

interface MedicareChatInput {
  thread_id: string;
  file_ids: string[];
  input: string;
}
interface MedicareChatResponse {
  response: string;
}

export const useMedicareFileInput = (
  options?: Omit<UseMutationOptions<MedicareFileResponse, ApiError, MedicareInputFile>, 'mutationFn'>
) =>
  useMutation<MedicareFileResponse, ApiError, MedicareInputFile>({
    mutationFn: async ({ fileInput }: MedicareInputFile) => {
      const formData = new FormData();

      fileInput.forEach((file) => {
        formData.append('file_input', file);
      });
      const { data } = await hikeApi.post<MedicareFileResponse>('medicare/upload_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    },
    ...options
  });

export const useMedicareThreadStartInput = (
  mutationOptions?: UseMutationOptions<MedicareStartThreadResponse, ApiError, MedicareStartThreadInput>
) =>
  useMutation({
    mutationFn: async ({ file_ids, input }: MedicareStartThreadInput) => {
      const { data } = await hikeApi.post<MedicareStartThreadResponse>('medicare/start_thread', {
        file_ids,
        input
      });
      return data;
    },
    ...mutationOptions
  });

export const useMedicareThreadContinueChatInput = (
  mutationOptions?: UseMutationOptions<MedicareChatResponse, ApiError, MedicareChatInput>
) =>
  useMutation({
    mutationFn: async ({ thread_id, file_ids, input }: MedicareChatInput) => {
      const { data } = await hikeApi.post<MedicareChatResponse>('medicare/chat', {
        input,
        thread_id,
        file_ids
      });
      return data;
    },
    ...mutationOptions
  });
