import { ROUTES } from '@/Router';
import IOSPermissionsSetup from '@/pages/HikeScan/PermissionsAndErrors/iOSPermissionsSetup';
import { Button, Center, Flex, Group, Modal, Overlay, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconExclamationCircle } from '@tabler/icons-react';
import { Link, generatePath } from 'react-router-dom';

export default function NotAllowedError() {
  const theme = useMantineTheme();

  const [permissionsSetupModalOpen, { open: openPermissionsSetupModal, close: closePermissionsSetupModal }] =
    useDisclosure(false);

  return (
    <>
      <Overlay bg={theme.colors.hike?.[7]}>
        <Center pos="absolute" top={0} pt="xl" w="100%">
          <Button
            data-automation="back-to-workflow-button"
            color="red"
            component={Link}
            to={generatePath(ROUTES.CLINICAL_WORKFLOW)}
          >
            Back to workflow
          </Button>
        </Center>
        <Flex align="center" justify="center" h="100%">
          <Stack align="center" gap="sm" w="80%">
            <IconExclamationCircle size={80} color="#FFFFFF70" />
            <Title ta="center" order={2}>
              Please allow camera access
            </Title>
            <Text size="md" ta="center">
              We need to access your camera to capture the patient&apos;s foot evaluation
            </Text>
            <Group gap="md" mt="md">
              <Button onClick={openPermissionsSetupModal}>View Instructions</Button>
            </Group>
          </Stack>
        </Flex>
      </Overlay>

      <Modal
        size="xl"
        opened={permissionsSetupModalOpen}
        onClose={closePermissionsSetupModal}
        title="Camera Permissions Setup"
      >
        <IOSPermissionsSetup />
      </Modal>
    </>
  );
}
