import { useGetEvaluationCountOnSearch, useGetEvaluationsForClinicianBySearch } from '@/api/evaluations';
import TicketList from '@/components/TicketList/TicketList';
import { formatConstant } from '@hike/sdk';

import {
  ActionIcon,
  Box,
  Chip,
  Group,
  NativeSelect,
  NumberInput,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Title
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconArrowLeft, IconChevronDown, IconHash, IconSearch } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

type SearchOption = {
  value: string;
  label: string;
};

export enum ClinicalSearchTabs {
  Completed = 'completed',
  Incomplete = 'incomplete',
  All = 'all'
}

const searchOptions: SearchOption[] = [
  {
    value: 'patient_name',
    label: 'Patient Name'
  },
  {
    value: 'patient_id',
    label: 'Patient ID'
  },
  {
    value: 'po_number',
    label: 'PO Number'
  }
];

export default function SearchEvaluations() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const searchBy = searchParams.get('searchBy');
  const query = searchParams.get('query') || '';
  const [deferredQuery] = useDebouncedValue(query, 500);

  const {
    data: queriedEvals,
    isLoading: isQueriedEvalsLoading,
    isError: isQueriedEvalsError
  } = useGetEvaluationsForClinicianBySearch(searchBy, deferredQuery, filter, {
    enabled: !!deferredQuery
  });

  const { data: queriedCounts } = useGetEvaluationCountOnSearch(searchBy, deferredQuery, {
    enabled: !!deferredQuery
  });

  useEffect(() => {
    if (!filter || !searchBy) {
      searchParams.set('filter', ClinicalSearchTabs.Incomplete);
      searchParams.set('searchBy', searchOptions[0]!.value);
      setSearchParams(searchParams);
    }
  }, []);

  const handleSearchByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set('query', '');
    searchParams.set('searchBy', event.target.value);
    if (event.target.value === 'patient_id' || event.target.value === 'po_number') {
      searchParams.set('filter', ClinicalSearchTabs.All);
    }
    setSearchParams(searchParams);
  };

  const handleQueryChange = (value: string | number) => {
    searchParams.set('query', value.toString());
    setSearchParams(searchParams);
  };

  const getChipCount = (chipFilter: ClinicalSearchTabs) => {
    if (chipFilter === ClinicalSearchTabs.Incomplete) {
      return queriedCounts?.incomplete_evaluation_count;
    }
    if (chipFilter === ClinicalSearchTabs.Completed) {
      return queriedCounts?.complete_evaluation_count;
    }
    return (queriedCounts?.incomplete_evaluation_count ?? 0) + (queriedCounts?.complete_evaluation_count ?? 0);
  };

  const getChipFilter = (chipFilter: ClinicalSearchTabs) => (
    <Chip
      checked={filter === chipFilter}
      onClick={() => {
        searchParams.set('filter', chipFilter);
        setSearchParams(searchParams);
      }}
      size="md"
    >
      {formatConstant(chipFilter)} ({getChipCount(chipFilter)})
    </Chip>
  );

  const getSearchInputElement = () => {
    if (searchBy === 'patient_name') {
      return (
        <TextInput
          data-automation-id="search-by-patient-name-input"
          leftSection={<IconSearch size={20} />}
          placeholder="Start typing their name..."
          style={{
            input: {
              fontSize: 400
            }
          }}
          value={query}
          onChange={(e) => {
            handleQueryChange(e.target.value);
          }}
        />
      );
    }

    if (searchBy === 'patient_id') {
      return (
        <NumberInput
          data-automation-id="search-by-patient-id-input"
          inputMode="numeric"
          allowDecimal={false}
          leftSection={<IconHash size={20} />}
          placeholder="Enter Patient ID"
          value={query}
          onChange={handleQueryChange}
          hideControls
        />
      );
    }

    if (searchBy === 'po_number') {
      return (
        <NumberInput
          data-automation-id="search-by-po-number-input"
          inputMode="numeric"
          allowDecimal={false}
          leftSection={<IconHash size={20} />}
          hideControls
          placeholder="Enter PO Number"
          value={query}
          onChange={handleQueryChange}
        />
      );
    }

    return (
      <Group style={{ justifyContent: 'center' }}>
        <Text>Please select a valid option to search by, or contact the Hike Team if this is unexpected.</Text>
      </Group>
    );
  };

  return (
    <Box p="lg">
      <Group>
        <ActionIcon
          data-automation-id="back-to-dashboard-button"
          component={Link}
          to="/"
          variant="outline"
          color="gray"
          radius="xl"
          size="lg"
        >
          <IconArrowLeft />
        </ActionIcon>
        <Title order={3} component="h1">
          Search All Evaluations
        </Title>
      </Group>
      <Stack mt="sm" gap="lg">
        <NativeSelect
          data-automation-id="search-by-input"
          label="Search Patients By..."
          styles={{
            label: {
              fontWeight: 700,
              paddingBottom: 8
            }
          }}
          rightSection={<IconChevronDown size={18} stroke={1.5} />}
          data={searchOptions}
          onChange={handleSearchByChange}
        />
        {getSearchInputElement()}
        {deferredQuery && (
          <>
            {filter && <Title order={5}>Viewing {formatConstant(filter)} Evaluations</Title>}
            <ScrollArea w="100%">
              <Chip.Group>
                <Group wrap="nowrap" gap="xs">
                  {getChipFilter(ClinicalSearchTabs.All)}
                  {getChipFilter(ClinicalSearchTabs.Incomplete)}
                  {getChipFilter(ClinicalSearchTabs.Completed)}
                </Group>
              </Chip.Group>
            </ScrollArea>
            <Title order={3}>Your Patients</Title>
            <TicketList
              isError={isQueriedEvalsError}
              isLoading={isQueriedEvalsLoading}
              evaluations={queriedEvals?.clinician_evaluations}
              allowEdit
            />

            <Title order={3} mt="md">
              Other Patients
            </Title>
            <TicketList
              isError={isQueriedEvalsError}
              isLoading={isQueriedEvalsLoading}
              evaluations={queriedEvals?.other_evaluations}
              allowEdit={false}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
