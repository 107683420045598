import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';

export default function Neuropathy({ form, initialValue, isOther, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_neuropathy`]: [
          {
            field: `${footDirection}_neuropathy`,
            type: FormTypes.multiCheckbox,
            initialValue: initialValues.neuropathy?.[`${footDirection}_neuropathy`],
            options: [
              {
                label: 'None',
                value: 'None'
              },
              {
                label: 'Calf',
                value: 'Calf'
              },
              {
                label: 'Thigh',
                value: 'Thigh'
              },
              {
                label: 'Finger',
                value: 'Finger'
              },
              {
                label: 'Hands',
                value: 'Hands'
              },
              {
                label: 'Forearm',
                value: 'Forearm'
              },
              {
                label: 'Foot',
                value: 'Foot'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            validate: zodHelpers.optionalMultiCheckbox
          },
          {
            field: `${footDirection}_neuropathy_other`,
            type: FormTypes.text,
            hidden: !isOther[footDirection],
            label: `Please describe the ${footDirection !== 'bilateral' ? `${footDirection} foot` : ''} neuropathy:`,
            initialValue: initialValues.neuropathy?.[`${footDirection}_neuropathy_other`],
            validate: zodHelpers.requiredText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
