import { FeetDirections, FootEvalContext, PRECHECK_VIEWS } from '@/pages/HikeScan/HikeScan';
import { ActionIcon, Badge, Button, Center, Group, Image, Overlay, rem, Stack, Text } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { useContext } from 'react';

export default function Previewing() {
  const { results, precheckView, footSelected, startPreviewing, resetScan, showRecordScren, setPrecheckView } =
    useContext(FootEvalContext);

  const isPreviewing = results.leftFootScanPreviewing || results.rightFootScanPreviewing;
  const isRecording = results.leftFootScanRecording || results.rightFootScanRecording;

  const hasScreenshotData = results.leftFootScanScreenshotData || results.rightFootScanScreenshotData;

  const onRestartHandler = () => {
    resetScan();
    showRecordScren(footSelected as FeetDirections);
  };

  const onBackHandler = () => {
    resetScan();
    setPrecheckView(PRECHECK_VIEWS.FOOT_SELECTOR);
  };

  if (hasScreenshotData && !isPreviewing) {
    return (
      <Overlay bg="transparent">
        <Center pos="absolute" left={0} right={0} w="100%" h="100%">
          <Stack>
            <ActionIcon onClick={startPreviewing} size={rem(120)} color="blue" variant="filled" radius="100%">
              <IconPlayerPlayFilled
                style={{
                  width: rem(80),
                  height: rem(80)
                }}
              />
            </ActionIcon>
            <Text size="lg" fw={500} ta="center">
              Preview
            </Text>
          </Stack>
        </Center>

        <Image
          width="100%"
          height="100%"
          src={footSelected === 'left' ? results.leftFootScanScreenshotData : results.rightFootScanScreenshotData}
        />

        <Group pos="absolute" bottom={25} pt="xl" justify="center" w="100%">
          <Button color="red" onClick={onRestartHandler}>
            Redo scan
          </Button>
        </Group>

        <Group pos="absolute" top={10} pt="xl" justify="center" w="100%">
          <Button color="white" variant="light" onClick={onBackHandler}>
            Back to foot selector
          </Button>
        </Group>
      </Overlay>
    );
  }

  if (isRecording || !isPreviewing || precheckView === PRECHECK_VIEWS.REPORT) {
    return null;
  }

  return (
    <Group
      px="sm"
      pos="absolute"
      top={0}
      pt="xl"
      justify="space-between"
      w="100%"
      style={{
        zIndex: 2
      }}
    >
      <Button onClick={onBackHandler} color="red" ta="center" variant="transparent">
        Cancel
      </Button>
      <Badge size="lg" c="primary" ta="center">
        Previewing
        {footSelected === 'left' ? ' Left' : ' Right'} Foot Scan
      </Badge>
    </Group>
  );
}
