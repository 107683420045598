import { z } from 'zod';

export const zodHelpers = {
  requiredText: z.string().min(1, {
    message: 'Please fill out this field'
  }),
  requiredNonNegativeNumber: z.number().nonnegative({
    message: 'Please enter a non-negative number'
  }),
  optionalNonNegativeNumber: z.coerce.number().nonnegative().nullable().optional(),
  optionalDate: z.date().nullable().optional(),
  requiredDate: z.date({
    required_error: 'Please select the month and year',
    invalid_type_error: 'Please select the month and year'
  }),
  optionalText: z.string().trim().min(0).optional(),
  requiredCheckbox: z.string().min(1, {
    message: 'Please select an option'
  }),
  requiredDropdown: z.string().min(1, {
    message: 'Please select an option'
  }),
  requiredMultiCheckbox: z.array(z.string()).min(1, {
    message: 'Please select at least one option'
  }),
  optionalMultiCheckbox: z.array(z.string()).min(0).optional(),
  requiredTextInput: z.string().trim().min(1, {
    message: 'Please fill out this field'
  }),
  requiredNumberInputGreaterThan0: z
    .number()
    .gt(0, {
      message: 'Number must be greater than 0'
    })
    .transform((val) => Number(val))
};
