import { useGetClinicalMaps, useSubmitClinicalMap } from '@/api/painMap';
import AdditionalNotes from '@/components/Notes/AdditionalNotes';
import { PainMapFoot } from '@/pages/PainMap/PainMap';
import { queryClient } from '@/utils/queryClient';
import { useSavedChangesText } from '@/utils/useSavedChangesText';
import {
  Box,
  Button,
  Center,
  ColorSwatch,
  Combobox,
  Group,
  Input,
  InputBase,
  Loader,
  Overlay,
  Stack,
  Text,
  rem,
  useCombobox
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDeviceFloppy, IconExclamationCircle } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { useParams, useSearchParams } from 'react-router-dom';
import { PainMapProps } from './painmap.type';

type Option = {
  value: string | number;
  color: string;
};

const options = {
  Pain: [
    { value: '1', color: '#E9F1FF' },
    { value: '2', color: '#9ECAFF' },
    { value: '3', color: '#62B0FF' },
    { value: '4', color: '#2A95ED' },
    { value: '5', color: '#007BCB' },
    { value: '6', color: '#0061A3' },
    { value: '7', color: '#00497C' },
    { value: '8', color: '#003258' },
    { value: '9', color: '#001D36' },
    { value: '10', color: '#000000' }
  ],
  Callus: [
    { value: 'Wet', color: '#E5D0FE' },
    { value: 'Dry', color: '#C29DDE' },
    { value: 'Cut open', color: '#AA78E5' },
    { value: 'Exposed', color: '#7F3AD1' },
    { value: 'Large wound', color: '#5300B6' }
  ],
  Neuroma: [{ value: 'Neuroma', color: '#008B03' }],
  Ulcer: [
    { value: 'Intact skin', color: '#FFB4AB' },
    { value: 'Superficial', color: '#FF897D' },
    { value: 'Tendon or bone depth', color: '#FF5449' },
    { value: 'Abscess or Osteomyelitis', color: '#DE3730' },
    { value: 'Forefoot Gangrene', color: '#93000A' },
    { value: 'Foot Gangrene', color: '#410002' }
  ]
} as Record<string, Option[]>;

export default function FootCanvas({ disabled }: PainMapProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIssue = searchParams.get('issue') || 'Pain';
  const { direction, visit_id } = useParams<{ direction?: PainMapFoot; visit_id: string }>();
  const [activeFoot] = direction?.split('-') || [];
  const canvasRef = useRef<CanvasDraw>(null);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const getActiveFootSrc = () => {
    if (activeFoot === 'left') {
      return '/left-foot.svg';
    }

    if (activeFoot === 'right') {
      return '/right-foot.svg';
    }

    return '/right-foot.svg';
  };

  const transformMapAttribute = (attribute: string) => `${activeFoot?.toLowerCase()}_${attribute.toLowerCase()}_map`;

  const transformMapAttributeNotes = (attribute: string) =>
    `${activeFoot?.toLowerCase()}_${attribute.toLowerCase()}_notes`;

  const {
    data: clinicalMaps,
    isLoading: clinicalMapsLoading,
    isError: clinicalMapsIsError,
    refetch: refetchInitialFormData
  } = useGetClinicalMaps(visit_id!);

  useEffect(() => {
    if (clinicalMaps) {
      const mapAttribute = transformMapAttribute(selectedIssue);
      const mapData = clinicalMaps[mapAttribute];

      if (mapData) {
        canvasRef.current?.loadSaveData(mapData);
      }
    }
  }, [clinicalMaps]);

  const {
    mutate: submitClinicalMap,
    isPending: submitClinicalMapLoading,
    isIdle: submitClinicalMapIsIdle,
    isSuccess: submitClinicalMapIsSuccess,
    isError: submitClinicalMapIsError
  } = useSubmitClinicalMap({
    onSuccess: (data) => {
      queryClient.setQueryData(['clinical-maps', visit_id!], data);
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'There was an error saving the clinical map',
        color: 'red'
      });
    }
  });

  const shouldShowChangesSavedText = useSavedChangesText({
    isError: submitClinicalMapIsError,
    isLoading: submitClinicalMapLoading,
    isIdle: submitClinicalMapIsIdle,
    isSuccess: submitClinicalMapIsSuccess
  });

  useEffect(() => {
    if (!searchParams.get('option')) {
      setSearchParams({
        option: options[selectedIssue!]![0]!.value,
        issue: selectedIssue
      } as {
        option: string;
        issue: string;
      });
    }
  }, [selectedIssue]);
  const saveMap = () => {
    const currentDrawing = canvasRef.current?.getSaveData();
    if (currentDrawing) {
      submitClinicalMap({
        visitId: visit_id!,
        clinicalMapAttribute: transformMapAttribute(selectedIssue),
        clinicalMapData: currentDrawing
      });
    }
  };

  const shouldShowOverlay = clinicalMapsLoading || clinicalMapsIsError;

  return (
    <>
      <Group justify="space-between">
        <Button aria-label="Back to Foot Selector" onClick={() => setSearchParams({})} variant="light">
          Back
        </Button>
        <Group>
          {shouldShowChangesSavedText && (
            <Text c="green" size="sm">
              Changes saved
            </Text>
          )}
          <Button
            loading={submitClinicalMapLoading}
            disabled={disabled}
            onClick={saveMap}
            rightSection={<IconDeviceFloppy />}
            aria-label="Save changes"
            color="hikeGreen"
          >
            Save
          </Button>
        </Group>
      </Group>
      <Stack>
        <Text size="xl" fw={700} ta="center">
          {selectedIssue} on {activeFoot} foot
        </Text>

        <Combobox
          store={combobox}
          disabled={disabled}
          onOptionSubmit={(val) => {
            setSearchParams({
              option: val,
              issue: selectedIssue
            } as {
              option: string;
              issue: string;
            });
            combobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <InputBase
              radius="md"
              disabled={disabled}
              component="button"
              type="button"
              pointer
              leftSection={
                selectedIssue ? (
                  <ColorSwatch
                    color={
                      (options[selectedIssue] as Option[]).find((option) => option.value === searchParams.get('option'))
                        ?.color || 'transparent'
                    }
                    size={20}
                  />
                ) : null
              }
              rightSection={<Combobox.Chevron />}
              rightSectionPointerEvents="none"
              onClick={() => combobox.toggleDropdown()}
            >
              {searchParams.get('option') || <Input.Placeholder>Pick a {selectedIssue} option</Input.Placeholder>}
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>
              {selectedIssue &&
                (options[selectedIssue] as Option[]).map((colorOption) => (
                  <Combobox.Option value={String(colorOption.value)} key={colorOption.value}>
                    <Group>
                      <ColorSwatch color={colorOption.color} size={20} />
                      {colorOption.value}
                    </Group>
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>

        <Text size="sm" ta="center">
          Draw on the foot where the {selectedIssue} is located
        </Text>
        <Box mt="lg" pos="relative">
          {shouldShowOverlay && (
            <Overlay radius="md" backgroundOpacity={0.9}>
              <Center h="100%">
                {clinicalMapsLoading && <Loader color="white" />}
                {clinicalMapsIsError && (
                  <Stack align="center">
                    <IconExclamationCircle
                      color="red"
                      style={{
                        width: rem(80),
                        height: rem(80)
                      }}
                    />
                    <Text c="red">Error loading clinical maps</Text>
                  </Stack>
                )}
              </Center>
            </Overlay>
          )}
          {clinicalMaps && (
            <AdditionalNotes
              initialNotes={clinicalMaps[transformMapAttributeNotes(selectedIssue)] || ''}
              path="pain-map-notes"
              disabled={disabled}
              loading={submitClinicalMapLoading}
              onSave={async (notes, callback) => {
                await submitClinicalMap({
                  visitId: visit_id!,
                  clinicalMapAttribute: transformMapAttributeNotes(selectedIssue),
                  clinicalMapData: notes
                });

                await refetchInitialFormData();

                callback();
              }}
            />
          )}
          <CanvasDraw
            ref={canvasRef}
            disabled={disabled}
            enablePanAndZoom
            imgSrc={getActiveFootSrc()}
            canvasWidth={230}
            brushRadius={3}
            brushColor={
              (options[selectedIssue] as Option[]).find((option) => option.value === searchParams.get('option'))
                ?.color || 'transparent'
            }
            canvasHeight={504}
            style={{
              margin: '0 auto'
            }}
          />
        </Box>
        <Group mt="xl" justify="space-between">
          <Button
            disabled={disabled}
            onClick={() => {
              canvasRef.current?.undo();
            }}
            fullWidth
            variant="outline"
          >
            Undo
          </Button>
          <Button
            disabled={disabled}
            onClick={() => {
              canvasRef.current?.clear();
            }}
            fullWidth
            variant="outline"
            color="red"
          >
            Clear
          </Button>
        </Group>
      </Stack>
    </>
  );
}
