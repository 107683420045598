import { ROUTES } from '@/Router';
import FormBuilder, {
  Builder,
  FormBuilderFnProps,
  FormBuilderUIProps,
  IsOtherFormFootSelect,
  setDefaultFieldValues
} from '@/components/FormPage/FormBuilder';
import { ActionIcon, Alert, Button, Group, Title } from '@mantine/core';
import { createFormActions } from '@mantine/form';
import { useDocumentTitle, usePrevious, useShallowEffect } from '@mantine/hooks';
import { IconChevronLeft } from '@tabler/icons-react';
import { camelCase } from 'lodash';
import startCase from 'lodash/startCase';
import React, { JSX, useEffect, useState } from 'react';
import { Link, generatePath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import css from './FormStep.module.css';

export type FormBuilderType =
  | Builder[]
  | ({
      initialValue: any;
      isOther?: IsOtherFormFootSelect;
      component: React.ReactNode | JSX.Element | Function;
    } & Pick<Builder, 'validate'>);

export interface FormStepProps extends FormBuilderUIProps {
  title?: string;
  onPageSubmit?: { [formKey: string]: (formValues: any) => Promise<unknown> | unknown }[];
  onFormInputChange?: {
    [formKey: string]: (formValues: any, form?: FormBuilderFnProps['form']) => Promise<void> | void;
  }[];
  fields: {
    [formKey: string]: FormBuilderType;
  };
  showTitlesAtEachStep?: boolean;
  notes?: React.ReactNode;
  as?: React.ElementType;
  form?: FormBuilderFnProps['form'];
  disabled?: boolean;
}

type FormStep = {
  title: string;
  formResults: unknown;
};

export default function FormStep({
  title,
  fields,
  buttonProps,
  onPageSubmit,
  onFormInputChange,
  showTitlesAtEachStep,
  notes,
  disabled,
  as = 'form',
  form
}: FormStepProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { stepName, visit_id } = useParams();
  const [searchParams] = useSearchParams();
  const onSaveRouteTo = searchParams.get('onSaveRouteTo');
  const [formSteps, setFormSteps] = useState<FormStep[]>(
    Object.entries(fields).map(([formTitle, formBuilder]) => {
      if (!Array.isArray(formBuilder)) {
        return {
          title: formTitle,
          formResults: null
        };
      }

      return {
        title: formTitle,
        formResults: formBuilder.map((build) => build.initialValue || null)
      };
    })
  );

  const [activeStep, setActiveStep] = useState(stepName ? Object.keys(fields).indexOf(stepName) : 0);

  const activeForm = formSteps[activeStep] ?? formSteps[0];

  useDocumentTitle(startCase(activeForm?.title.replaceAll(/left|right/gi, '')) || 'Hike Clinical');

  const setFormData = (formName: string, values: Record<string, unknown>) => {
    setFormSteps((prev) => {
      const formMatch = prev.find((existingForm) => existingForm.title === formName);
      if (formMatch) {
        formMatch.formResults = values;
      }
      return prev;
    });
  };

  const previousForm = usePrevious<FormStep | undefined>(activeForm);
  const previousFields = usePrevious(fields);
  const previousLocation = usePrevious(location);
  const formAction = createFormActions(camelCase(activeForm?.title));

  if (!visit_id) {
    return (
      <Alert variant="filled" color="#BA1A1A" title="Failed to get visit ID from session storage" mt="xs">
        An error occurred getting the visit ID.
      </Alert>
    );
  }

  useShallowEffect(() => {
    if (activeForm) {
      if (previousForm?.title !== activeForm.title) {
        const newInitialValues = Object.entries(fields[activeForm.title] || {}).reduce(
          (acc: Record<string, unknown>, [, value]) => {
            setDefaultFieldValues(value.field, acc, value);
            return acc;
          },
          {}
        );
        formAction.setInitialValues(newInitialValues);
        formAction.setValues(newInitialValues);
      }
    }
  }, [activeForm?.title]);

  useEffect(() => {
    if (previousFields !== fields) {
      const updatedFormSteps = Object.entries(fields).map(([formTitle, formBuilder]) => {
        if (!Array.isArray(formBuilder)) {
          return {
            title: formTitle,
            formResults: null
          };
        }

        return {
          title: formTitle,
          formResults: formBuilder.map((build) => build.initialValue)
        };
      });

      setFormSteps(updatedFormSteps);
    }
  }, [fields, previousFields, activeForm]);

  const navigateToStep = (step: number, replace = false) => {
    if (as !== 'form') {
      return;
    }

    const commonNavState = {
      replace,
      state: {
        previousStep: previousLocation?.pathname
      }
    };

    const nextStepRoute = `${location.pathname.split('/').slice(0, -1).join('/')}/${formSteps[step]?.title}`;

    if (nextStepRoute) {
      navigate(
        {
          pathname: nextStepRoute,
          search: searchParams.toString()
        },
        commonNavState
      );
    }
  };

  const internalCheckSteps = () => {
    const stepIndex = formSteps.findIndex((formStepsMatch) => formStepsMatch.title === stepName);

    if (stepIndex !== -1) {
      setActiveStep(stepIndex);
    }
  };

  const callHandler = async () => {
    const handlerMatch = onPageSubmit?.find((handler) => {
      if (activeForm?.title) {
        return handler[activeForm.title];
      }

      return null;
    });

    if (activeForm && handlerMatch && activeForm === formSteps[activeStep]) {
      await handlerMatch[activeForm.title]?.(activeForm.formResults);
    }
  };

  const goToNextStep = async () => {
    if (activeStep < formSteps.length - 1) {
      const nextStep = activeStep + 1;
      navigateToStep(nextStep);
    }
  };

  useEffect(() => {
    internalCheckSteps();
  }, [formSteps]);

  const goToPreviousStep = () => {
    if (activeStep > 0) {
      const previousStep = activeStep - 1;
      navigateToStep(previousStep);
    }
  };

  const isFirstStep = activeStep === 0;

  return (
    <>
      {!!title && (
        <Group mb="lg" justify="space-between">
          <ActionIcon
            data-automation-id="previous-step-button"
            aria-label="Previous Step"
            data-disabled={buttonProps?.loading}
            onClick={goToPreviousStep}
            pr="2"
            disabled={activeStep === 0}
            style={{
              visibility: isFirstStep ? 'hidden' : 'visible'
            }}
          >
            <IconChevronLeft />
          </ActionIcon>
          <Button
            data-automation-id="back-to-workflow-button"
            aria-label="Back to Dashboard"
            component={Link}
            to={generatePath(ROUTES.CLINICAL_WORKFLOW)}
            variant="light"
            size="compact-sm"
          >
            Back to Workflow
          </Button>
        </Group>
      )}
      {!!title && isFirstStep && (
        <Title aria-label={title} order={2} mb="lg" className={css.title}>
          {title}
        </Title>
      )}
      {showTitlesAtEachStep && !isFirstStep && (
        <Title aria-label={activeForm?.title} order={3} mb="lg" className={css.title}>
          {startCase(activeForm?.title)}
        </Title>
      )}
      {notes}
      {typeof fields !== 'undefined' &&
        Object.entries(fields).map(([formName, builder]) => (
          <FormBuilder
            key={formName}
            buttonProps={buttonProps}
            formSteps={formSteps}
            activeStep={activeStep}
            formName={formName}
            form={form}
            as={as}
            disabled={disabled}
            onChangeHandler={onFormInputChange}
            setFormData={setFormData}
            callHandler={callHandler}
            goToNextStep={goToNextStep}
            onSaveRouteTo={onSaveRouteTo}
            builder={builder}
          />
        ))}
    </>
  );
}
