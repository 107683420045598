import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function HeelRaise({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_heel_raise`]: [
          {
            field: `${footDirection}_heel_raise`,
            label: `${startCase(footDirection)} Heel Raise`,
            type: FormTypes.checkbox,
            initialValue: initialValues.heel_raise?.[`${footDirection}_heel_raise`],
            options: [
              {
                label: '1/16 in',
                value: '1/16 in'
              },
              {
                label: '1/8 in',
                value: '1/8 in'
              },
              {
                label: '1/4 in',
                value: '1/4 in'
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
