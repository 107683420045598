import { UseCriticalInfoResponse } from '@/types/critical-info.form.type';
import {
  ClinicalFormPageMutationInput,
  ClinicalFormPageMutationOutput,
  ReviewFormMutationInput,
  ReviewFormMutationOutput,
  ShipImpressionBoxPostInput,
  ShipImpressionBoxResponse
} from '@/types/forms.type';
import { ApiError } from '@/utils/ApiError';
import { hikeApi } from '@/utils/axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export const useGetInitialFormData = <T>(
  workflowTitle: string,
  visitID: string,
  options?: Omit<UseQueryOptions<T, ApiError>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['initial-form-data', workflowTitle, visitID],
    queryFn: async () => {
      const { data } = await hikeApi.get<T>(`/v2/clinical/forms/${visitID}/${workflowTitle}`);
      return data;
    },
    ...options
  });

export const useGetInitialCriticalReviewData = (
  visitID: string,
  options?: Omit<UseQueryOptions<UseCriticalInfoResponse, ApiError>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['critical-form-data', visitID],
    queryFn: async () => {
      const { data } = await hikeApi.get<UseCriticalInfoResponse>(`/v2/clinical/critical-forms/${visitID}`);
      return data;
    },
    ...options
  });

export const useCommitFormData = (
  options?: Omit<
    UseMutationOptions<ClinicalFormPageMutationOutput, ApiError, ClinicalFormPageMutationInput>,
    'mutationFn'
  >
) =>
  useMutation<ClinicalFormPageMutationOutput, ApiError, ClinicalFormPageMutationInput>({
    mutationFn: async ({ visitID, formTitle, pageTitle, formChanges }: ClinicalFormPageMutationInput) => {
      const { data } = await hikeApi.patch<ClinicalFormPageMutationOutput>(
        `v2/clinical/forms/${visitID}/${formTitle}`,
        formChanges,
        {
          params: { pageTitle }
        }
      );

      return data;
    },
    ...options
  });

export const useCommitReviewForm = (
  options?: Omit<UseMutationOptions<ReviewFormMutationOutput, ApiError, ReviewFormMutationInput>, 'mutationFn'>
) =>
  useMutation<ReviewFormMutationOutput, ApiError, ReviewFormMutationInput>({
    mutationFn: async ({ visitID, formChanges }: ReviewFormMutationInput) => {
      const { data } = await hikeApi.patch<ReviewFormMutationOutput>(
        `/v2/clinical/review-form/${visitID}`,
        formChanges
      );

      return data;
    },
    ...options
  });

export const useShipImpressionBox = (
  options?: Omit<UseMutationOptions<ShipImpressionBoxResponse, ApiError, ShipImpressionBoxPostInput>, 'mutationFn'>
) =>
  useMutation<ShipImpressionBoxResponse, ApiError, ShipImpressionBoxPostInput>({
    mutationFn: async ({ visitId }: ShipImpressionBoxPostInput) => {
      const { data } = await hikeApi.post<ShipImpressionBoxResponse>('/v2/ship_impression_box_toe_filler', {
        visit_id: visitId
      });
      return data;
    },
    ...options
  });
