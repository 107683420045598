import { FormBuilderFnProps, FormTypes } from '@/components/FormPage/FormBuilder';
import FormFootSelect from '@/components/FormPage/FormFootSelector/FormFootSelector';
import FormStep from '@/components/FormPage/FormStep';
import { zodHelpers } from '@/utils/zodHelpers';
import { startCase } from 'lodash';

export default function ArchHeight({ form, initialValue, disabled }: FormBuilderFnProps) {
  const initialValues = initialValue as Record<string, any>;

  const renderForm = (footDirection: 'bilateral' | 'left' | 'right') => (
    <FormStep
      form={form}
      disabled={disabled}
      as="div"
      fields={{
        [`${footDirection}_arch_height`]: [
          {
            field: `${footDirection}_arch_height`,
            label: `${startCase(footDirection)} Arch Height`,
            type: FormTypes.checkbox,
            initialValue: initialValues.arch_height?.[`${footDirection}_arch_height`],
            options: [
              {
                label: 'Lower',
                value: 'lower'
              },
              {
                label: 'Same as Impression',
                value: 'same-as-impression'
              },
              {
                label: 'Higher',
                value: 'higher'
              }
            ],
            validate: zodHelpers.optionalText
          },
          {
            field: `${footDirection}_arch_height_modification`,
            label: `${startCase(footDirection)} Arch Height Modification`,
            type: FormTypes.checkbox,
            initialValue: initialValues.arch_height?.[`${footDirection}_arch_height_modification`],
            options: [
              {
                label: '1/16 in',
                value: '1/16 in'
              },
              {
                label: '1/8 in',
                value: '1/8 in'
              },
              {
                label: '1/4 in',
                value: '1/4 in'
              }
            ],
            validate: zodHelpers.optionalText
          }
        ]
      }}
    />
  );

  return (
    <FormFootSelect
      errors={form.errors}
      bilateralNode={renderForm('bilateral')}
      leftNode={renderForm('left')}
      rightNode={renderForm('right')}
    />
  );
}
