import {
  Box,
  Button,
  Center,
  FileInput,
  Image,
  LoadingOverlay,
  Modal,
  Overlay,
  Stack,
  Text,
  UnstyledButton,
  rem
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import css from './FootSelector.module.css';

import { ROUTES } from '@/Router';
import { useGetWorkflowDashboard } from '@/api/clincalWorkflow';
import {
  useGetOrderInformation,
  useScanImpressionBox,
  useUploadScans,
  useUploadScansToS3MultiPart
} from '@/api/footScan';
import { FootEvalContext } from '@/pages/HikeScan/HikeScan';
import { queryClient } from '@/utils/queryClient';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { IconShoe } from '@tabler/icons-react';
import clsx from 'clsx';
import { useContext, useRef } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetInitialFormData } from '@/api/forms';
import { UseOrderFormResponse } from '@/types/order.form.type';

export default function FootSelector() {
  const { resetPrecheck, results, showRecordScren, setResults } = useContext(FootEvalContext);
  const { visit_id } = useParams();
  const navigate = useNavigate();
  const [attachScansOpen, attachScansHandlers] = useDisclosure(false);
  const [leftFile, setLeftFile] = useInputState<File | null>(null);
  const [rightFile, setRightFile] = useInputState<File | null>(null);
  const leftFileInputRef = useRef<HTMLButtonElement>(null);
  const rightFileInputRef = useRef<HTMLButtonElement>(null);

  const {
    data: orderInformation,
    isPending: orderInformationIsPending,
    isError: orderInformationIsError,
    isFetched: orderInformationFetchingIsSuccess
  } = useGetOrderInformation(visit_id as string, {
    enabled: !!visit_id,
    refetchInterval: 15000,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    retry: false
  });
  generatePath(ROUTES.CLINICAL_WORKFLOW);
  const { data: workflowInfo } = useGetWorkflowDashboard(visit_id as string);
  const [impressionBoxOpen, impressionBoxHandlers] = useDisclosure(false);

  const { data: orderInformationData } = useGetInitialFormData<UseOrderFormResponse>('order-form', visit_id as string);

  const orderSide = orderInformationData?.patient_information?.order_side;

  const handleRecording = (foot: 'left' | 'right', rescanValue: string) => {
    setResults({
      [`${foot}RescanValue`]: rescanValue
    });
    resetPrecheck();
    showRecordScren(foot);
  };
  const { mutate: uploadScan } = useUploadScansToS3MultiPart();

  const onImpressionBoxModalClose = () => {
    navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
    impressionBoxHandlers.close();
  };
  const {
    mutate: mutateScanImpressionBox,
    isPending: scanImpressionLoading,
    isSuccess: scanImpressionSuccess
  } = useScanImpressionBox({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orderInformation', visit_id as string] });
      impressionBoxHandlers.open();
    }
  });

  const { mutate: mutateUploadScans, isPending: uploadScansLoading } = useUploadScans({
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.error || 'Something went wrong',
        color: 'red'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orderInformation', visit_id as string] });
      navigate(generatePath(ROUTES.CLINICAL_WORKFLOW));
      attachScansHandlers.close();
      notifications.show({
        title: 'Complete',
        message: 'The foot scans were successfully uploaded',
        color: 'green'
      });
    }
  });

  const handleUploadedScansClicked = () => {
    const dateNow = Date.now();
    const previewName = `${dateNow}-PREVIEW-${visit_id}`;

    const isLeftMovFile = leftFile?.name.toLowerCase().endsWith('.mov');

    uploadScan({
      visitId: visit_id as string,
      isMovFile: isLeftMovFile as boolean,
      videoFile: leftFile,
      previewName: `${previewName}-left`
    });

    const isRightMovFile = rightFile?.name.toLowerCase().endsWith('.mov');

    uploadScan({
      visitId: visit_id as string,
      isMovFile: isRightMovFile as boolean,
      videoFile: rightFile,
      previewName: `${previewName}-right`
    });

    mutateUploadScans({
      left_preview_name: `${previewName}-left`,
      right_preview_name: `${previewName}-right`,
      visit_id: visit_id as string,
      is_left_mov: isLeftMovFile as boolean,
      is_right_mov: isRightMovFile as boolean
    });
  };
  const handleScanImpressionBox = () => {
    mutateScanImpressionBox({ visitId: visit_id as string });
  };

  const getFootStatus = (foot: 'left' | 'right') => {
    const key = `${foot}_status` as 'left_status' | 'right_status';

    if ((foot === 'left' && orderSide === 'Right') || (foot === 'right' && orderSide === 'Left')) {
      return `Order side is: ${orderSide} foot`;
    }

    if (orderInformation?.order_info[key] === 'Pending') {
      return `${foot} Foot Processing...`;
    }

    if (orderInformation?.order_info[key] === 'Approved') {
      return `${foot} Scan Approved`;
    }

    if (!orderInformation?.order_info[key]) {
      return 'No Scan Found';
    }

    if (orderInformation.order_info[key]?.indexOf('Declined') !== -1) {
      return `${foot} Foot Rejected`;
    }

    return orderInformation.order_info[key];
  };

  const getFootDisabledStatus = (foot: 'left' | 'right') => {
    const key = `${foot}_status` as 'left_status' | 'right_status';
    return orderInformation?.order_info[key] === 'Pending' || orderInformation?.order_info[key] === 'Approved';
  };

  const getRescanValue = (foot: 'left' | 'right') => {
    const key = `${foot}_status` as 'left_status' | 'right_status';
    if (orderInformation?.order_info[key]?.indexOf('Declined') === undefined) {
      return '0';
    }
    return '2';
  };

  const allScansComplete = results.leftFootScanSuccess && results.rightFootScanSuccess;
  const footSelectorShouldBeDisabled = orderInformationFetchingIsSuccess && orderInformationIsError;

  return (
    <Overlay opacity={1} className={css.footContainer}>
      <Stack pb={rem(80)} h="100%" align="center" justify="flex-end">
        <Modal
          opened={impressionBoxOpen}
          onClose={onImpressionBoxModalClose}
          centered
          closeOnClickOutside={false}
          closeOnEscape={false}
          withCloseButton={false}
          title="You have chosen to Scan Impression Box"
        >
          <Stack>
            Please attach Impression Box Scans to this order once order is submitted.
            <Button onClick={onImpressionBoxModalClose} size="xs" fullWidth>
              I will attach Impression Box scans to this order
            </Button>
          </Stack>
        </Modal>

        <Modal
          opened={attachScansOpen}
          onClose={() => attachScansHandlers.close()}
          centered
          title="Attach Your Scanned Videos Here"
        >
          <Stack>
            <FileInput
              ref={leftFileInputRef}
              onTouchStart={() => leftFileInputRef.current?.click()}
              disabled={orderSide === 'Right'}
              accept=".mp4, .mov, .webm"
              label="Left Foot Scan"
              size="md"
              placeholder="Upload Left Video"
              onChange={setLeftFile}
              required={orderSide !== 'Right'}
            />
            <FileInput
              ref={rightFileInputRef}
              onTouchStart={() => rightFileInputRef.current?.click()}
              disabled={orderSide === 'Left'}
              accept=".mp4, .mov, .webm"
              label="Right Foot Scan"
              size="md"
              placeholder="Upload Right Video"
              onChange={setRightFile}
              required={orderSide !== 'Left'}
            />

            <Button fullWidth loading={uploadScansLoading} onClick={handleUploadedScansClicked}>
              Submit Uploaded Scans
            </Button>
          </Stack>
        </Modal>

        <Stack mb="xl" w="80%" ta="center">
          {!footSelectorShouldBeDisabled && (
            <>
              <Text size="md" fw="bold">
                Select the foot you want to scan
              </Text>

              <Stack pt="xl" justify="center" w="100%" gap="lg">
                <Button
                  color="white"
                  size="md"
                  variant="gradient"
                  disabled={workflowInfo?.submitted}
                  onClick={() => attachScansHandlers.open()}
                >
                  Upload Pre-Scanned Videos
                </Button>
                <Button
                  color="white"
                  size="md"
                  variant="gradient"
                  rightSection={scanImpressionSuccess && <IconShoe />}
                  onClick={handleScanImpressionBox}
                  loading={scanImpressionLoading}
                  disabled={workflowInfo?.submitted}
                >
                  Scan Impression Box
                </Button>
              </Stack>
            </>
          )}
          {footSelectorShouldBeDisabled && (
            <Text size="md" c="red">
              There was an error setting up the foot scan. Please try again later.
            </Text>
          )}
        </Stack>
        <LoadingOverlay visible={orderInformationIsPending} />
        <UnstyledButton
          disabled={
            footSelectorShouldBeDisabled ||
            getFootDisabledStatus('left') ||
            workflowInfo?.submitted ||
            orderSide === 'Right'
          }
          onClick={() => handleRecording('left', getRescanValue('left'))}
          className={css.footButton}
        >
          <Box w={100} p="xl" pl="xs">
            <Image src="/foot-icon.svg" className={css.footSvg} alt="Left Foot" />
          </Box>
          <Stack gap={0}>
            <Text size="xl" fw="bold">
              Left Foot
            </Text>
            {!footSelectorShouldBeDisabled && (
              <Text
                size="md"
                c={results.leftFootScanSuccess ? 'hikeGreen.4' : 'white'}
                style={{
                  textTransform: 'capitalize'
                }}
              >
                {results.leftFootScanSuccess ? 'Upload complete' : getFootStatus('left')}
              </Text>
            )}
          </Stack>
        </UnstyledButton>
        <UnstyledButton
          disabled={
            footSelectorShouldBeDisabled ||
            getFootDisabledStatus('right') ||
            workflowInfo?.submitted ||
            orderSide === 'Left'
          }
          onClick={() => handleRecording('right', getRescanValue('right'))}
          className={css.footButton}
        >
          <Box w={100} p="xl" pl="xs">
            <Image src="/foot-icon.svg" className={clsx([css.footSvg, css.flip])} alt="Right Foot" />
          </Box>
          <Stack gap={0}>
            <Text size="xl" fw="bold">
              Right Foot
            </Text>
            {!footSelectorShouldBeDisabled && (
              <Text
                size="md"
                c={results.rightFootScanSuccess ? 'hikeGreen.4' : 'white'}
                style={{
                  textTransform: 'capitalize'
                }}
              >
                {results.rightFootScanSuccess ? 'Upload complete' : getFootStatus('right')}
              </Text>
            )}
          </Stack>
        </UnstyledButton>

        {allScansComplete && (
          <>
            <Text size="sm">You have successfully uploaded the foot scans</Text>
            <Button color="hikeGreen" component={Link} to={generatePath(ROUTES.CLINICAL_WORKFLOW)}>
              Back to workflow
            </Button>
          </>
        )}
      </Stack>

      {!allScansComplete && (
        <Center pos="absolute" top={0} pt="xl" w="100%">
          <Button color="red" component={Link} to={generatePath(ROUTES.CLINICAL_WORKFLOW)}>
            Back to workflow
          </Button>
        </Center>
      )}
    </Overlay>
  );
}
