import { PhysicianSearch, useGetPhysicians } from '@/api/search';
import { AsyncAutocomplete } from '@/components/Autocomplete/AsyncAutocomplete';
import { FormBuilderFnProps } from '@/components/FormPage/FormBuilder';
import { Combobox, TextInput } from '@mantine/core';

export const DiabeticCarePhysicianAutocomplete = (props: FormBuilderFnProps) => {
  const activeSearchTerm = props.form.values.diabetic_care_physician as string;

  const {
    data: physicians,
    isLoading: physiciansLoading,
    isError: physiciansError
  } = useGetPhysicians(activeSearchTerm);

  if (physiciansError) {
    return (
      <TextInput
        label="Diabetic Care Physician"
        placeholder="Enter a diabetic care physician"
        {...props.form.getInputProps('diabetic_care_physician')}
        variant="default"
      />
    );
  }

  return (
    <AsyncAutocomplete<PhysicianSearch>
      loading={physiciansLoading}
      disabled={props.disabled}
      label="Diabetic Care Physician"
      placeholder="Enter a physician"
      value={props.form.values.diabetic_care_physician as string}
      onChange={(value) => {
        props.form.setFieldValue('diabetic_care_physician', value);
      }}
      data={physicians || []}
      searchKeys={['name']}
      textInputProps={{
        variant: 'default',
        required: false
      }}
      optionsMapNode={(physician) => (
        <Combobox.Option value={physician.name} key={physician.name}>
          {physician.name}
        </Combobox.Option>
      )}
    />
  );
};
